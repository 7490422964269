import React, { useState } from "react";
import {
  FormControl,
  Divider,
  Select,
  MenuItem,
  Slider,
  TextField,
  Box,
  styled,
  IconButton,
  Tooltip,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";

const AudienceRadioButtonFilter = ({
  text,
  buttons,
  setAudienceGenderFilterState,
  tooltipInfo,
}) => {
  const [percentageValue, setPercentageValue] = useState(50);
  const [gender, setGender] = useState("male");

  const handlePercentageChange = (event, newValue) => {
    setPercentageValue(newValue);
    setAudienceGenderFilterState({ gender: gender, percentage: newValue });
  };

  return (
    <Accordion sx={{ width: { md: "80%", xs: "90%" }, boxShadow: "none" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: 1,
          }}
        >
          <IconButton style={{ color: "#3DBE72" }}>
            <Tooltip title={tooltipInfo}>
              <HelpOutlineRoundedIcon
                style={{ fontSize: 12, padding: 0, margin: 0 }}
              />
            </Tooltip>
          </IconButton>
          <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
        </Box>
      </AccordionSummary>

      <AccordionDetails sx={{ textAlign: "start", paddingLeft: "20px" }}>
        <FormControl>
          {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={buttons[0]}
            name="radio-buttons-group"
            onChange={(e) => {
              setGender(e.target.value);
              setAudienceGenderFilterState({
                percentage: percentageValue,
                gender: gender,
              });
            }}
          >
            {buttons.map((btn) => {
              return (
                <FormControlLabel
                  value={btn}
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#3dbe72",
                        },
                      }}
                    />
                  }
                  label={btn}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
        <Box
          sx={{
            padding: "15px 10px",
            bgcolor: "#f0f3f6",
            borderRadius: "10px",
          }}
        >
          <Typography sx={{ margin: "5px 0" }}>
            More than{" "}
            <span style={{ marginRight: "5px", fontWeight: "bold" }}>
              {percentageValue}%
            </span>
            of Audience
          </Typography>
          <Slider
            getAriaLabel={() => "Percentage of Audience"}
            value={percentageValue}
            onChange={handlePercentageChange}
            valueLabelDisplay="auto"
            getAriaValueText={(value) => `More than ${value}% of audience`}
            style={{
              color: "#3dbe72",
              padding: "0",
              width: "100%",
            }}
            size="small"
            min={0}
            max={100}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default AudienceRadioButtonFilter;
