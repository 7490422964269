import { Typography, Grid, Box, Icon, IconButton, Button } from "@mui/material";
// import { Box } from '@mui/system'

import DP from "../../../images/dp-lg.png";
import dp from "../../../images/dp.png";

import TurnRightRoundedIcon from "@mui/icons-material/TurnRightRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Link } from "react-router-dom";

const AccountAnalytics = ({ avglikes, avgcomments, er, Id, selectedSocial, }) => {



  const InstaOptions = [
    "Influencer Category",
    "Influencer Gender",
    "Engagement Rate",
    "Average Reels Views",
    "Average Likes",
    "Average Comments",
    "Brand Mentions",
    "Monthly Followers Growth",
  ];

  const YoutubeOptions = [
    "Influencer Category",
    "Influencer Gender",
    "Average Video Views",
    "Average Comments",
    "Influencer Interests",
    "Engagement Rate",
    "Brand Mentions",
    "Monthly Subscribers Growth",
  ]




  let encodedId = btoa(Id.toString());
  return (
    <Box
      sx={{ padding: { md: "30px", xs: "20px" } }}
      style={{ background: "#F2F7FF", borderRadius: 20, marginTop: 30 }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: { md: "center", xs: "flex-start" },
          marginBottom: "20px",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Typography style={{ fontWeight: "bold", fontSize: 15 }}>
          Account Analytics {selectedSocial === "instagram" ? " (Instagram)" : " (Youtube)"}
        </Typography>
        <Link
          to={`/dashboard/find-influencers/profile/${encodedId}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Button
            size="small"
            variant="contained"
            sx={{ marginTop: { md: 0, xs: 1 } }}
            style={{
              borderRadius: 30,
              color: "#FFFFFF",
              fontWeight: "bold",
              background: "#3DBE72",
              boxShadow: "none",
            }}
          >
            View Full Report
          </Button>
        </Link>
      </Box>

      <div style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
        gap: 20,
      }}
      >
        {selectedSocial === "instagram" ? InstaOptions.map((option, index) => (
          <div
            item
            key={index}
            style={{ background: "#FFFFFF", padding: 15, borderRadius: 10}}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                {option}
              </Typography>
              <IconButton style={{ color: "#3DBE72" }}>
                <HelpOutlineRoundedIcon style={{ fontSize: 15 }} />
              </IconButton>
            </Box>
            <Typography style={{ fontWeight: "bold", fontSize: "1.7rem" }}>
              {"266"}
            </Typography>
          </div>
        )) : YoutubeOptions.map((option, index) => (
          <Grid


            item
            md={5.8}
            xs={12}
            key={index}
            style={{ background: "#FFFFFF", padding: 15, borderRadius: 10 }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                {option}
              </Typography>
              <IconButton style={{ color: "#3DBE72" }}>
                <HelpOutlineRoundedIcon style={{ fontSize: 15 }} />
              </IconButton>
            </Box>
            <Typography style={{ fontWeight: "bold", fontSize: "1.7rem" }}>
              {"266"}
            </Typography>
          </Grid>
        ))}
      </div>


    </Box>
  );
};

export default AccountAnalytics;
