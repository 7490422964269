import axios from "axios";

const URL = process.env.REACT_APP_BASE_URL

export const getUserByID = async (id) => {
    try {
        const res = await axios.post(`${URL}/getuserbyid`, id)
        console.log("user response by user id : ", res);
        return res.data.user

    }
    catch (e) {
        console.log("error while fetching user details by id : ", e.message);
    }
}