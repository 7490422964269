import React, { useEffect, useState } from "react";

import {
  Box,
  Grid,
  Typography,
  styled,
  IconButton,
  Button,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  FormGroup,
  Switch,
} from "@mui/material";

import {
  CancelOutlined,
  LanguageOutlined,
  DataArray,
  InsertLinkOutlined,
  DoneAll,
  LockOutlined,
  Settings,
  Done,
} from "@mui/icons-material";

// import { getCollectionById } from "../../../service/collections api/getCollectionById";

// import { getUserByID } from "../../../service/user/getUserByID";

// import { updateCollectionShareWith } from "../../../service/collections api/updateCollectionShareWith";
// import {
//   UpdateLinkAccess,
//   UpdatePublicAccess,
// } from "../../../service/collections api/shareCollection";
import GearDropdown1 from "./GearDropdown1";
// import {
//   getCollectionShareAcces,
//   shareCollection,
//   updateCollectionShareAcces,
// } from "../../../service/api";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const CustomSelect = () => {
  const [value, setValue] = React.useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
      <InputLabel id="demo-simple-select-label">View</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        onChange={handleChange}
        label="View"
      >
        <MenuItem value="all">All</MenuItem>
        <MenuItem value="active">Active</MenuItem>
        <MenuItem value="inactive">Inactive</MenuItem>
      </Select>
    </FormControl>
  );
};

const SettingsDialog = ({
  open,
  onClose,
  columns,
  setColumns,
  title,
  handleSubmit,
}) => {
  const handleToggleChange = (index) => {
    const newColumns = [...columns];
    newColumns[index].enabled = !newColumns[index].enabled;
    setColumns(newColumns);
  };

  console.log(columns);
  const handleColumnsubmit = () => {
    let data = {
      id: 3,
    };

    if (title === "Email Link Access") {
      data.email_share_access = {};
      columns.forEach((column, index) => {
        data.email_share_access[column.name.props.name] = column.enabled
          ? 1
          : 0;
      });
    } else {
      data = {
        ...data,
      };
      columns.forEach((column, index) => {
        data[column.name.props.name] = column.enabled ? 1 : 0;
      });
    }

    // Send the data based on the link access type
    console.log("data", data);
    handleSubmit(data);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ textAlign: "center" }}>
        <b>{title}</b>
      </DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column, index) => (
            <FormControlLabel
              control={
                <Switch
                  checked={column.enabled}
                  onChange={() => handleToggleChange(index)}
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: "#3DBF73", // This is the color of the thumb (circle) when the switch is checked
                      "& + .MuiSwitch-track": {
                        backgroundColor: "#3DBF73", // This is the background color of the track when the switch is checked
                      },
                    },
                  }}
                />
              }
              label={column.name.props.name}
              key={index}
            />
          ))}
        </FormGroup>
        <Grid
          item
          md={6}
          xs={3}
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            onClick={handleColumnsubmit}
            variant="contained"
            sx={{
              background: "#3dbf73ff",
              textTransform: "none",
              boxShadow: "none",
              "&:hover": { background: "#3dbf73ff" },
              width: "100%",
              fontSize: "15px",
            }}
          >
            Share
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const ShareInfluencers = ({ sharePopUp, clickedData, columnsData }) => {
  console.log("Clicked collection : ", clickedData);

  const [status, setStatus] = useState({
    loading: false,
    message: "",
  });

  const [can, setCan] = useState("");
  const [createdBy, setCreatedBy] = useState({});
  const [collection, setCollection] = useState([]);
  console.log("collection : ", collection);

  const [emails, setEmails] = useState("");

  const [payload, setPayload] = useState(null);
  const [dialogOpenForPublic, setDialogOpenForPublic] = useState(false);
  const [dialogOpenForEmail, setDialogOpenForEmail] = useState(false);
  const [columns, setColumns] = useState(columnsData);

  const handleDialogToggleforPublic = () => {
    setDialogOpenForPublic(!dialogOpenForPublic);
  };

  const handleDialogToggleforEmail = () => {
    setDialogOpenForEmail(!dialogOpenForEmail);
  };
  console.log("state", collection);
  // const shared_with_list = collection;

  //   const { id } = clickedData;
  //   const encodeId = (id) => btoa(id);
  //   const encodedId = encodeId(id.toString());

  //   const getRecordCreatedBy = async () => {
  //     const res = await getUserByID({ id });
  //     console.log("created by : ", res);
  //     setCreatedBy(res);
  //   };

  //   useEffect(() => {
  //     const fetchCollectionById = async (id_) => {
  //       const collection_id = {
  //         id: id_,
  //       };

  //       console.log("collection_id", JSON.stringify(collection_id.id));
  //       try {
  //         const data = {
  //           for_id: JSON.stringify(collection_id.id),
  //           for_data: "collection",
  //         };
  //         const response = await getCollectionShareAcces(data);
  //         console.log("response on share collection", response.Access);

  //         setCollection(response.Access);
  //       } catch (error) {
  //         console.error("Error while fetching collections by Id:", error.message);
  //       } finally {
  //         getRecordCreatedBy();
  //       }
  //     };

  //     fetchCollectionById(id);
  //   }, [status]);

  //   console.log("collectionAccess", collection);

  const handleChangeEmails = (e) => {
    const { value } = e.target;
    console.log(value);

    setEmails(value);
  };

  const loadFromLocalStorage = (key) => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : null;
  };

  const [currUser, setCurrUser] = useState(loadFromLocalStorage("currUser"));

  const [shareStatus, setShareStatus] = useState(null);

  console.log("currUser", currUser);

  const handleAccessiblityChange = async (e) => {
    try {
      const sharePayload = {
        type: "link",
        email: "",
        access_level: can,
        link: "/public/shared/collection/" + encodedId,
        for_data: "collection",
        for_id: JSON.stringify(id),
        user_id: "42",
        given_id: JSON.stringify(currUser),
        status: "Active",
      };
      const response = await shareCollection({ data: sharePayload });
      console.log("Share with API Response public:", response);
    } catch (error) {
      console.error(
        "Error during API call of update collection share with :",
        error
      );
    } finally {
      setEmails("");
      setStatus({ loading: false, message: "Shared" });
    }
  };

  const handleShareAccessUpdate = async (id) => {
    console.log("valueee", shareStatus);

    if (collection.length > 0) {
      console.log("etar", shareStatus);
      const data = {
        id: JSON.stringify(id),
        status: shareStatus,
      };
      try {
        const response = await updateCollectionShareAcces(data);
        console.log("Share with API Response:", response);
      } catch (error) {
        console.error(
          "Error during API call of update collection share with :",
          error
        );
      }
    } else {
      console.log("Emails field is empty.");
    }
  };

  const handleSubmitShare = async () => {
    setStatus({ loading: true, message: "processing" });
    if (emails.trim() !== "") {
      try {
        console.log(emails);
        const sharePayload = {
          type: "email",
          email: emails,
          access_level: can,
          link: "/shared/collection/" + encodedId,
          for_data: "collection",
          for_id: JSON.stringify(id),
          user_id: "42",
          given_id: JSON.stringify(currUser),
          status: "view",
        };

        console.log("Generated Payload:", sharePayload);

        const response = await shareCollection({ data: sharePayload });
        console.log("Share with API Response:", response);

        // const response = await updateCollectionShareWith(sharePayload);
        // console.log("Share with API Response:", response);

        setEmails("");
        // console.log("recent email : ", response.shared_with);
        // Handle the API response as needed
      } catch (error) {
        console.error(
          "Error during API call of update collection share with :",
          error
        );
        // Handle errors appropriately, e.g., show an error message to the user
      } finally {
        // fetchCollectionById();
        setEmails("");
        setStatus({ loading: false, message: "Shared" });
      }
    } else {
      console.warn("Emails field is empty.");
      setStatus({ loading: false });
      // Optionally, you can inform the user that the emails field is empty
    }
  };

  const handleLinkAccessSubmit = async (data) => {
    try {
      const response = await UpdateLinkAccess(data);
      console.log(response);
      setDialogOpenForEmail(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePublicAccessSubmit = async (data) => {
    try {
      const response = await UpdatePublicAccess(data);
      console.log(response);
      setDialogOpenForPublic(false);
    } catch (err) {
      console.log(err);
    }
  };

  const [accessibility, setAccessibility] = useState("Restricted");

  return (
    <Box p={4}>
      <Grid container>
        <Grid item md={10} xs={10}>
          <Typography
            sx={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              color: "#3dbf73ff",
            }}
          >
            Share the Influencer Profile
          </Typography>
        </Grid>
        <Grid
          item
          md={2}
          xs={2}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <IconButton size="small" onClick={() => sharePopUp(false)}>
            <CancelOutlined />
          </IconButton>
        </Grid>

        <Grid item md={12} xs={12} mt={2.5}>
          <Grid item style={{ display: "flex", alignItems: "center" }}>
            <Grid item md={12} xs={12}>
              <input
                type="text"
                style={{
                  width: "100%",
                  height: "100%",
                  padding: "8px 10px",
                  borderRadius: "8px",
                  border: "1px solid #3dbf73ff",
                  "&:focus": {
                    border: "1px slid yellow",
                  },
                  // outline : 'none'
                }}
                placeholder="Email (,) comma separated"
                value={emails}
                onChange={handleChangeEmails}
              />
            </Grid>
            <Grid>
              <Box p={4}>
                <GearDropdown1 can={can} setCan={setCan} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} mt={2}>
          <hr style={{ height: "2px", background: "black" }} />
        </Grid>
        <Grid item md={12} xs={12} mt={2} mb={2}>
          {createdBy ? (
            <Grid container style={{ width: "100%" }}>
              <Grid
                item
                md={8}
                xs={8}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "30px",
                    overflow: "hidden",
                    marginRight: "10px",
                  }}
                >
                  <img
                    src="https://i.pinimg.com/736x/38/11/d8/3811d876c4397073393b7ff9fbd2b48a.jpg"
                    alt=""
                    width={"100%"}
                    height={"100%"}
                  />
                </Box>
                <p
                  style={{
                    fontSize: ".9rem",
                  }}
                >
                  {createdBy.name}
                </p>
              </Grid>
              <Grid
                item
                md={4}
                xs={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span style={{ fontSize: ".8rem" }}>Owner</span>
              </Grid>
            </Grid>
          ) : (
            "Finding owner...   "
          )}

          <Box
            sx={{
              maxHeight: "180px",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                width: "8px", // Width of the scrollbar
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1", // Color of the track
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#888", // Color of the thumb
                borderRadius: "4px", // Border radius of the thumb
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555", // Color of the thumb on hover
              },
            }}
          >
            {collection &&
              collection.map(({ email, status, id }, index) => (
                <Grid container mt={1} key={index}>
                  <Grid
                    item
                    md={8}
                    xs={8}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "30px",
                        overflow: "hidden",
                        marginRight: "10px",
                      }}
                    >
                      <img
                        src="https://i.pinimg.com/736x/38/11/d8/3811d876c4397073393b7ff9fbd2b48a.jpg"
                        alt=""
                        width={"100%"}
                        height={"100%"}
                      />
                    </Box>
                    <p
                      style={{
                        fontSize: ".9rem",
                      }}
                    >
                      {email}
                    </p>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={4}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      paddingRight: "10px",
                    }}
                  >
                    <select
                      name=""
                      id=""
                      style={{
                        border: "none",
                        outline: "none",
                      }}
                      defaultValue={status}
                      onChange={(e) => {
                        setShareStatus(e.target.value);
                      }}
                    >
                      <option value="edit">edit</option>
                      <option value="view">view</option>
                      <option value="remove">remove</option>
                    </select>
                    {shareStatus ? (
                      <IconButton
                        onClick={() => {
                          handleShareAccessUpdate(id);
                          setShareStatus(null);
                        }}
                      >
                        <Done />
                      </IconButton>
                    ) : null}
                  </Grid>
                </Grid>
              ))}
          </Box>
        </Grid>
        <p>General access</p>
        <Grid
          item
          md={12}
          mb={3}
          xs={12}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Grid container mt={1}>
            <Grid item md={1.2} xs={1.5}>
              <IconButton
                sx={{
                  background: "#d9d9d9ff",
                  "&:hover": { background: "#d9d9d9ff" },
                }}
              >
                <LockOutlined />
              </IconButton>
            </Grid>
            <Box sx={{}}>
              <Grid item md={10} xs={10}>
                <select
                  name="can"
                  id=""
                  style={{
                    border: "none",
                    outline: "none",
                    fontSize: ".9rem",
                  }}
                  value={can}
                  onChange={(e) => {
                    setAccessibility(e.target.value);
                    handleAccessiblityChange(e);
                  }}
                >
                  <option value={"Restricted"}>Restricted</option>
                  <option value={"Access"}>Access</option>
                </select>
              </Grid>
              {accessibility === "Access" ? (
                <Link
                  to={`/public/shared/collection/${encodedId}`}
                  style={{ textDecoration: "none", color: "#3dbf73ff" }}
                >
                  {`public/shared/collection/${encodedId}`}
                </Link>
              ) : null}
            </Box>
          </Grid>

          <Grid>
            <GearDropdown1 can={can} setCan={setCan} />
            {/* <Box p={4}>
              <Grid>
                <IconButton onClick={handleDialogToggleforPublic}>
                  <Settings />
                </IconButton>
              </Grid>
              <SettingsDialog
                title={"Public Link Access"}
                handleSubmit={handlePublicAccessSubmit}
                open={dialogOpenForPublic}
                onClose={handleDialogToggleforPublic}
                columns={columns}
                setColumns={setColumns}
              />
            </Box> */}
          </Grid>
        </Grid>

        <Grid item md={6} xs={9}>
          <Button
            variant="text"
            startIcon={<InsertLinkOutlined />}
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              color: "#3dbf73ff",
              marginRight: "20px",
            }}
            onClick={() => {
              if (accessibility === "Access") {
                // Assuming 2 represents "Access"
                // Replace 'yourCollectionProfileLink' with the actual link you want to copy
                const yourCollectionProfileLink =
                  "http://example.com/collectionProfile";
                navigator.clipboard
                  .writeText(yourCollectionProfileLink)
                  .then(() => {
                    console.log("Link copied to clipboard");
                    // Optionally, show a success message to the user
                  })
                  .catch((err) => {
                    console.error("Failed to copy the link: ", err);
                    // Optionally, show an error message to the user
                  });
              } else {
                console.log("Copy link is disabled due to restricted access.");
                // Optionally, show a message that copying is disabled
              }
            }}
            disabled={can !== 2} // Disable the button if can is not equal to 2
          >
            Copy link
          </Button>
        </Grid>

        <Grid
          item
          md={6}
          xs={3}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {
            <Button
              onClick={() => handleSubmitShare()}
              variant="contained"
              sx={{
                background: "#3dbf73ff",
                textTransform: "none",
                boxShadow: "none",
                "&:hover": { background: "#3dbf73ff" },
              }}
              disabled={status.loading && status.message === "Shared"}
            >
              {status.loading ? (
                <CircularProgress size={20} sx={{ color: "white" }} />
              ) : status.message === "Shared" && status.loading ? (
                "Shared"
              ) : (
                "Share"
              )}
            </Button>
          }
        </Grid>
      </Grid>
    </Box>
  );
};

export default ShareInfluencers;
