import React from 'react';
import { CSVLink } from 'react-csv';

import { IconButton, Tooltip } from '@mui/material'
import CSVicon from '../../../images/csv.png'
const CSVExportButton = ({ data, filename }) => {
    const csvData = React.useMemo(() => {
        return [
            // Header row
            ["id",
                "accounts",
                "categories",
                "followers",
                "engagementRate",
                "averageLikes",
                "averageComments",
                "avg_comments",
                "avg_likes",
                "created_at",
                "current_access_token",
                "date",
                "db_insta_comments",
                "db_yt_comment",
                "email",
                "email_verified_at",
                "google_id",
                "insta_engagement_ratio",
                "insta_followers",
                "insta_otp_timestamp",
                "instagram_id",
                "instagram_table_id",
                "instagram_verify_status",
                "mobile",
                "name",
                "photo",
                "platform",
                "role",
                "status",
                "to_be_decided",
                "updated_at",
                "youtube_channel_id",
                "youtube_id",
                "youtube_otp_timestamp",
                "youtube_table_id",
                "youtube_verify_status"
            ],
            // Data rows
            ...data?.map((item) => [
                item.id,
                item.accounts,
                item.categories,
                item.followers,
                item.engagementRate,
                item.averageLikes,
                item.averageComments,
                item.avg_comments,
                item.avg_likes,
                item.created_at,
                item.current_access_token,
                item.date,
                item.db_insta_comments,
                item.db_yt_comment,
                item.email,
                item.email_verified_at,
                item.google_id,
                item.insta_engagement_ratio,
                item.insta_followers,
                item.insta_otp_timestamp,
                item.instagram_id,
                item.instagram_table_id,
                item.instagram_verify_status,
                item.mobile,
                item.name,
                item.photo,
                item.platform,
                item.role,
                item.status,
                item.to_be_decided,
                item.updated_at,
                item.youtube_channel_id,
                item.youtube_id,
                item.youtube_otp_timestamp,
                item.youtube_table_id,
                item.youtube_verify_status
            ]),
        ];
    }, [data]);

    return (
        <CSVLink data={csvData} filename={`${filename}.csv`} >
            <Tooltip title="Download CSV" placement='top' arrow>
                <IconButton size='small' data={data} filename="influencers_data.csv">
                    <img src={CSVicon} alt="" width={30} />
                </IconButton>
            </Tooltip>
        </CSVLink>
    );
};

export default CSVExportButton;
