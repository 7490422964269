import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Tabs, Tab, Typography, Box, TabPanel, Grid, TextField, InputAdornment, Button, IconButton, styled, Avatar, useMediaQuery } from '@mui/material'
import { PersonSearchRounded, AddRounded } from '@mui/icons-material'
import Insta from '../../images/insta-sm.png'
import Youtube from '../../images/youtube-sm.png'
import Table from './Table';

import { assignedCampaign } from './collectionData'
import { getAllInfluencers } from '../../../service/getAllApi';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0, pt: 2 }}>
          {/* <Typography>{children}</Typography> */}
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BasicTabs = ({ data }) => {

  console.log("data basic tabs ; ", data);

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  console.log("basics tabs", data);

  const [value, setValue] = React.useState(0);
  const [searchTerm, setSearchTerm] = React.useState(""); 

  const [loading, setLoading] = useState(false);
  const [influencerData, setInfluencerData] = useState([]);
  const [searchedData,setSearchedData] =useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  
    if (event.target.value.length > 1) {
      const filteredData = influencerData.filter((influencer) =>
        influencer.name.toLowerCase().includes(event.target.value.toLowerCase())
      );
      setSearchedData(filteredData);
    } else {
      setSearchedData(influencerData);
    }
  };


  const getInfluencers = async () => {
    // const collection_id = {
    //     id: collectionData.id
    // }
    // const collection = await getCollectionById(collection_id);

    setLoading(true);

    const collectionInfluencers = JSON.parse(data.influncers_id)
      ? JSON.parse(data.influncers_id)
      : [];

    console.log("collectionInfluencers Arr", collectionInfluencers);

    const users = await getAllInfluencers();

    let influencers = users?.filter((influencer) =>
      collectionInfluencers.includes(influencer.id)
    );


    console.log('influencer ddda',influencers);

    console.log("users", users);

    setInfluencerData(influencers);
    setSearchedData(influencers)

    setLoading(false);
    // console.log(allInfluencersRecord);
  };

  useEffect(() => {
    getInfluencers();
  }, []);
  

  return (
    <Box >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab style={{ fontSize: '12px', color: 'black', fontWeight: 'bold' }} label="Instagram" {...a11yProps(0)} icon={<img width="20" src={Insta} />} iconPosition="start" />
          <Tab style={{ fontSize: '12px', color: 'black', fontWeight: 'bold' }} label="Youtube" {...a11yProps(1)} icon={<img width="20" src={Youtube} />} iconPosition="start" />
          {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}
        sx={{ p: 0 }}
      >

        <Grid container >
          <Grid item md={9} xs={10}  >
            <TextField size='small' placeholder='Search Influencer by Name'
              value={searchTerm} // Control the TextField with the searchTerm state
              onChange={handleSearchChange}
              sx={{
                borderRadius: 8, // Adjust the value as needed for your desired border radius

                '& .MuiOutlinedInput-root': {
                  borderRadius: 8, // Adjust the value as needed for your desired border radius
                  paddingRight: 0.4,
                  pl: 2,
                  '&:hover fieldset': {
                    borderColor: 'black', // Set the border color for hover state
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black', // Set the border color for focused state
                  },
                },
              }}
              fullWidth
            // InputProps={{
            //   endAdornment: (
            //     <InputAdornment position="end">
            //       <IconButton size='small'>
            //         <PersonSearchRounded />
            //       </IconButton>

            //     </InputAdornment>
            //   ),
            // }}
            />
          </Grid>

          {
            isSmallScreen ?
              <Grid item xs={2} align="center" justify="center">
                <IconButton sx={{ background: 'black', color: 'white', '&:hover': { background: 'black', color: 'white', } }}>
                  <AddRounded />
                </IconButton>
              </Grid>
              :

              <Grid item md={3} xs={5} align="end" justify={'center'}>

                <Button variant='contained' startIcon={<AddRounded />}
                  sx={{
                    background: 'black',
                    boxShadow: 'none',
                    textTransform: 'none',
                    fontSize: { md: '1rem', xs: '.6rem' },
                    // padding  : {md : '4px 5px', xs :'4px  5px'},
                    borderRadius: 10,
                    width: '90%',

                    '&:hover': {
                      background: 'black',
                      boxShadow: 'none',

                    }
                  }}
                >
                  Add Influencers
                </Button>
              </Grid>
          }

          <Grid item md={12} xs={12} mt={2}>
            <Table collectionData={data}  influencerData={searchedData} loading={loading}/>
          </Grid>
          <Grid mt={2} item md={12} xs={12}
            sx={{
              bgcolor: '#f2f7ffff',
              borderRadius: 5
            }}
          >
            <Grid container p={2}>
              <Grid item md={12} xs={12} >
                <Typography sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Assigned Campaign</Typography>
              </Grid>
              <Grid item md={12} xs={12} p={3}>
                {
                  assignedCampaign?.map((item) => (
                    <Grid container key={item.id} mb={2}>
                      <Grid item md={1} xs={3} sx={{ display: 'flex', alignItems: 'center' }}>

                        <Avatar alt="Remy Sharp" src={item.img} />
                      </Grid>
                      <Grid item md={10} xs={9} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Typography fontWeight={'bold'} fontSize={'1rem'}>Kurkure</Typography>
                        <Typography fontSize={'.9rem'}>Targetr : 1.23 Million Conversations</Typography>
                      </Grid>
                    </Grid>
                  ))
                }

              </Grid>
              {/* {
                assignedCampaign.map((item) => (
                  
                ))
              } */}

              {/* <Grid item md={1.5} xs={1.5} sx={{ display: 'flex', alignItems: 'center' }}>

                <Avatar alt="Remy Sharp" src='https://icon-library.com/images/avatar-icon-images/avatar-icon-images-4.jpg' />
              </Grid>
              <Grid item md={10} xs={10} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Typography fontWeight={'bold'} fontSize={'1rem'}>Kurkure</Typography>
                <Typography fontSize={'.9rem'}>Targetr : 1.23 Million Conversations</Typography>
              </Grid> */}
            </Grid>

          </Grid>
        </Grid>

      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Grid container >
          <Grid item md={9} xs={10}  >
            <TextField size='small' placeholder='Search Influencer by Name'
              sx={{
                borderRadius: 8, // Adjust the value as needed for your desired border radius

                '& .MuiOutlinedInput-root': {
                  borderRadius: 8, // Adjust the value as needed for your desired border radius
                  paddingRight: 0.4,
                  pl: 2,
                  '&:hover fieldset': {
                    borderColor: 'black', // Set the border color for hover state
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black', // Set the border color for focused state
                  },
                },
              }}
              fullWidth
            // InputProps={{
            //   endAdornment: (
            //     <InputAdornment position="end">
            //       <IconButton size='small'>
            //         <PersonSearchRounded />
            //       </IconButton>

            //     </InputAdornment>
            //   ),
            // }}
            />
          </Grid>

          {
            isSmallScreen ?
              <Grid item xs={2} align="center" justify="center">
                <IconButton sx={{ background: 'black', color: 'white', '&:hover': { background: 'black', color: 'white', } }}>
                  <AddRounded />
                </IconButton>
              </Grid>
              :

              <Grid item md={3} xs={5} align="end" justify={'center'}>

                <Button variant='contained' startIcon={<AddRounded />}
                  sx={{
                    background: 'black',
                    boxShadow: 'none',
                    textTransform: 'none',
                    fontSize: { md: '1rem', xs: '.6rem' },
                    // padding  : {md : '4px 5px', xs :'4px  5px'},
                    borderRadius: 10,
                    width: '90%',

                    '&:hover': {
                      background: 'black',
                      boxShadow: 'none',

                    }
                  }}
                >
                  Add Influencers
                </Button>
              </Grid>
          }

          <Grid item md={12} xs={12} mt={2}>
            <Table influencerData={influencerData} loading ={loading}  />
          </Grid>
          <Grid mt={2} item md={12} xs={12}
            sx={{
              bgcolor: '#f2f7ffff',
              borderRadius: 5
            }}
          >
            <Grid container p={2}>
              <Grid item md={12} xs={12} >
                <Typography sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Assigned Campaign</Typography>
              </Grid>
              <Grid item md={12} xs={12} p={3}>
                {
                  assignedCampaign?.map((item) => (
                    <Grid container key={item.id} mb={2}>
                      <Grid item md={1} xs={3} sx={{ display: 'flex', alignItems: 'center' }}>

                        <Avatar alt="Remy Sharp" src={item.img} />
                      </Grid>
                      <Grid item md={10} xs={9} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Typography fontWeight={'bold'} fontSize={'1rem'}>Kurkure</Typography>
                        <Typography fontSize={'.9rem'}>Targetr : 1.23 Million Conversations</Typography>
                      </Grid>
                    </Grid>
                  ))
                }
              </Grid>
              {/* {
                assignedCampaign.map((item) => (
                  
                ))
              } */}

              {/* <Grid item md={1.5} xs={1.5} sx={{ display: 'flex', alignItems: 'center' }}>

                <Avatar alt="Remy Sharp" src='https://icon-library.com/images/avatar-icon-images/avatar-icon-images-4.jpg' />
              </Grid>
              <Grid item md={10} xs={10} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Typography fontWeight={'bold'} fontSize={'1rem'}>Kurkure</Typography>
                <Typography fontSize={'.9rem'}>Targetr : 1.23 Million Conversations</Typography>
              </Grid> */}
            </Grid>

          </Grid>
        </Grid>
      </CustomTabPanel>
    </Box>
  )
}

export default BasicTabs