import React, { useEffect, useState } from "react";
import {
  FormControl,
  Divider,
  Select,
  MenuItem,
  Slider,
  TextField,
  Box,
  styled,
  IconButton,
  Tooltip,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";

// const CheckboxButtonFilter = ({
//   text,
//   buttons,
//   setCheckboxButtonFilterState,
//   clear,
// }) => {
//   const [selectedCheckboxes, setSelectedCheckboxes] = useState({});

//   const handleCheckboxChange = (event) => {
//     const { name, checked } = event.target;
//     const label = buttons.find((btn) => btn === name);
//     if (checked) {
//       setSelectedCheckboxes((prevSelected) => ({
//         ...prevSelected,
//         [label]: true,
//       }));
//     } else {
//       const { [label]: removed, ...rest } = prevSelected;
//       setSelectedCheckboxes(rest);
//     }
//     setCheckboxButtonFilterState(selectedCheckboxes);
//   };

//   useEffect(() => {
//     if (clear) {
//       setSelectedCheckboxes({});
//       setSelectedCheckboxes([]);
//     }
//   }, [clear]);

//   return (
//     <Accordion sx={{ width: { md: "80%", xs: "90%" }, boxShadow: "none" }}>
//       <AccordionSummary
//         expandIcon={<ExpandMoreIcon />}
//         aria-controls="panel1a-content"
//         id="panel1a-header"
//         sx={{ textAlign: "start" }}
//       >
//         <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
//       </AccordionSummary>

//       <AccordionDetails sx={{ textAlign: "start", paddingLeft: "20px" }}>
//         <FormGroup onChange={(e) => console.log(e)}>
//           {buttons.map((btn) => {
//             return (
//               <FormControlLabel
//                 control={
//                   <Checkbox
//                     checked={selectedCheckboxes[btn]}
//                     onChange={handleCheckboxChange}
//                     sx={{
//                       color: "#3dbe72",
//                       "&.Mui-checked": {
//                         color: "#3dbe72",
//                       },
//                     }}
//                   />
//                 }
//                 label={btn}
//               />
//             );
//           })}
//         </FormGroup>
//       </AccordionDetails>
//     </Accordion>
//   );
// };

// export default CheckboxButtonFilter;

const CheckboxButtonFilter = ({
  text,
  buttons,
  setCheckboxButtonFilterState,
  setContactInfoState,
  setHasPrevBrandSponsorsState,
  setVerifiedContactInfoState,
  setSocialLinksState,
  setHasSponsoredPostsState,
  clear,
  tooltipInfo,
}) => {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [checkboxStates, setCheckboxStates] = useState({});

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const label = buttons.find((btn) => btn === name);
    if (checked) {
      setSelectedCheckboxes((prevSelected) => [...prevSelected, label]);
      setCheckboxStates((prevStates) => ({ ...prevStates, [label]: true }));
    } else {
      setSelectedCheckboxes((prevSelected) =>
        prevSelected.filter((item) => item !== label)
      );
      setCheckboxStates((prevStates) => ({ ...prevStates, [label]: false }));
    }
    setCheckboxButtonFilterState(selectedCheckboxes);
    if (setContactInfoState) setContactInfoState(selectedCheckboxes);
    else if (setHasPrevBrandSponsorsState)
      setHasPrevBrandSponsorsState(selectedCheckboxes);
    else if (setVerifiedContactInfoState)
      setVerifiedContactInfoState(selectedCheckboxes);
    else if (setSocialLinksState) setSocialLinksState(selectedCheckboxes);
    else setHasSponsoredPostsState(selectedCheckboxes);
  };

  useEffect(() => {
    if (clear) {
      setSelectedCheckboxes([]);
      setCheckboxStates({});
    }
  }, [clear]);

  return (
    <Accordion sx={{ width: { md: "80%", xs: "90%" }, boxShadow: "none" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ textAlign: "start" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: 1,
          }}
        >
          <IconButton style={{ color: "#3DBE72" }}>
            <Tooltip title={tooltipInfo}>
              <HelpOutlineRoundedIcon
                style={{ fontSize: 12, padding: 0, margin: 0 }}
              />
            </Tooltip>
          </IconButton>
          <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
        </Box>
      </AccordionSummary>

      <AccordionDetails sx={{ textAlign: "start", paddingLeft: "20px" }}>
        <FormGroup>
          {buttons.map((btn) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkboxStates[btn] || false}
                    onChange={handleCheckboxChange}
                    name={btn}
                    sx={{
                      color: "#3dbe72",
                      "&.Mui-checked": {
                        color: "#3dbe72",
                      },
                    }}
                  />
                }
                label={btn}
              />
            );
          })}
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  );
};

export default CheckboxButtonFilter;
