// CustomColumnState.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  value: "",
  for_table: "findInfluencer",
  for_id: "",
  status: "active",
  created_by: "tester",
};

export const CustomColumnState = createSlice({
  name: "sharedState",
  initialState,
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
    setValue: (state, action) => {
      state.value = action.payload;
    },
    setForTable: (state, action) => {
      state.for_table = action.payload;
    },
    setForId: (state, action) => {
      state.for_id = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setCreatedBy: (state, action) => {
      state.created_by = action.payload;
    },
  },
});

export const { setName, setCreatedBy, setForId, setForTable, setStatus, setValue } = CustomColumnState.actions;

export default CustomColumnState.reducer;
