import { Box, Button, styled } from "@mui/material";
import { useEffect, useRef, useState } from "react";

import Header from "./subcomponents/findInfluencer/FIHeader";
import SearchBar from "./subcomponents/findInfluencer/SearchBar";
import AdvFilterDrawer2 from "./subcomponents/findInfluencer/AdvFilterDrawer2";
import InfluencersDataTable from "./subcomponents/findInfluencer/InfluencersDataTable";
import { data } from "./subcomponents/findInfluencer/InfluencersData.js";

// APIs
import { getAllInfluencersWithLimit } from "../../service/influencers/fetchInfluencersWithLimit.js";
import { getAllInfluencers } from "../../service/getAllApi.js";
import AdvFilterDrawerY from "./subcomponents/findInfluencer/AdvFilterDrawerY.jsx";
import { useSelector } from "react-redux";

const Container = styled(Box)(({ theme }) => ({}));

const FindInfluencer = () => {
  const [open, setOpen] = useState(false);
  const [influencers, setInfluencers] = useState([]);
  const [fetchingInfluencer, setFetchingInfluencer] = useState(false);
  const [fetchingInfluencers, setFetchingInfluencers] = useState(false);
  const [lastId, setLastId] = useState(null);
  const [scrollStep, setScrollStep] = useState(0);
  const [selectedSocial, setSelectedSocial] = useState("instagram");

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const isScrollingRef = useRef(false);


  const fetchMoreInfluencers = async () => {
    try {
      setFetchingInfluencers(true);
      const response = await getAllInfluencers();
      console.log("influencers ", response);
      if (response.length > 0) {
        setInfluencers(response);
      }
    } catch (error) {
      console.error("Error fetching influencers:", error);
    } finally {
      setFetchingInfluencers(false);
    }
  };

  useEffect(() => {
    fetchMoreInfluencers();
  }, []);

  const handleScroll = () => {
    if (isScrollingRef.current) return;
    isScrollingRef.current = true;

    const section1 = section1Ref.current;
    const section2 = section2Ref.current;

    if (section1 && section2) {
      if (scrollStep === 0) {
        window.scrollTo({ top: section1.offsetTop, behavior: "smooth" });
        setScrollStep(1);
      } else if (scrollStep === 1) {
        window.scrollTo({ top: section2.offsetTop, behavior: "smooth" });
        setScrollStep(2);
      } else {
        window.scrollTo({ top: section1.offsetTop, behavior: "smooth" });
        setScrollStep(1);
      }
    }

    setTimeout(() => {
      isScrollingRef.current = false;
    }, 1000); // Adjust timeout to match the smooth scroll duration
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollStep]);

  const isMiniOpen = useSelector((state) => state.sharedState.isOpen);

  return (
    <Box>
      <Box id="section1" ref={section1Ref} sx={{ height: "max-content", width: "auto" }}>
        <Header />
        <SearchBar
          open={open}
          set={setOpen}
          setInfluencers={setInfluencers}
          influencers={influencers}
          data={data}
          selectedSocial={selectedSocial}
          setSelectedSocial={setSelectedSocial}
        />
      </Box>
      <Box id="section2" ref={section2Ref} sx={{ marginTop: "50px" }}>
        <Box sx={{
          height: "max-content",
          mx: "auto",
          overflowX: "hidden",
          width: isMiniOpen ? "94vw" : "79vw",
          transition: "width 0.4s ease-in-out", // Smooth transition over 0.3 seconds
        }}
        >
          <InfluencersDataTable
            influencers={influencers}
            fetching={fetchingInfluencer}
            fetchMoreInfluencers={fetchMoreInfluencers}
            onScrollFetching={fetchingInfluencers}
            selectedSocial={selectedSocial}
          />
        </Box>
        {selectedSocial == "instagram" ? (
          <AdvFilterDrawer2
            open={open}
            set={setOpen}
            selectedSocial={selectedSocial}
          />
        ) : (
          <AdvFilterDrawerY
            open={open}
            set={setOpen}
            selectedSocial={selectedSocial}
          />
        )}
      </Box>
    </Box>
  );
};

export default FindInfluencer;
