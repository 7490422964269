import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { data } from "../subcomponents/findInfluencer/InfluencersData.js";
import {
  FormGroup,
  FormControlLabel,
  Switch,
  Box,
  Button,
  Typography,
  styled,
  Tooltip,
  IconButton,
  Chip,
  Stack,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { SwapVertOutlined } from "@mui/icons-material";
import WhatsAppIcon from "../../images/WhatsApp.png";
import GmailIcon from "../../images/Gmail.png";

const CustomSortIcon = () => (
  // <span >
  //     <ImportExport style={{color : 'black', "&:hover" : {color : 'yellow'}}}/> {/* Icon for ascending sort */}
  //     {/* <ImportExport /> Icon for descending sort */}
  // </span>
  // <span>
  //     <img src={SwapVertOutlined} width={15} style={{ margin: '5px' }} />
  // </span>
  <IconButton>
    <SwapVertOutlined />
  </IconButton>
);

const CustomAccounts = ({ row }) => (
  <Box
    style={{ pointerEvents: "none" }}
    display="flex"
    alignItems="center"
    mt={1}
    mb={1}
  >
    <img
      src={
        row.photo
          ? row.photo
          : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU"
      }
      alt=""
      width={50}
      style={{ borderRadius: "50%" }}
    />
    <Typography ml={1} fontWeight="bold" fontSize="15px">
      {row.name}
    </Typography>
  </Box>
);

const Customcategory = ({ row }) => (
  <Chip
    style={{ pointerEvents: "none" }}
    label={"Tech Influencers"}
    color="success"
    size="small"
  />
);

const CustomAverage = ({ row }) => (
  <span style={{ pointerEvents: "none" }}>
    {"665K"}/{"50K"}
  </span>
);

// const IOSSwitch = styled((props) => (
//   <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
// ))(({ theme }) => ({
//   width: 42,
//   height: 26,
//   padding: 0,
//   '& .MuiSwitch-switchBase': {
//     padding: 0,
//     margin: 2,
//     transitionDuration: '300ms',
//     '&.Mui-checked': {
//       transform: 'translateX(16px)',
//       color: '#fff',
//       '& + .MuiSwitch-track': {
//         backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
//         opacity: 1,
//         border: 0,
//       },
//       '&.Mui-disabled + .MuiSwitch-track': {
//         opacity: 0.5,
//       },
//     },
//     '&.Mui-focusVisible .MuiSwitch-thumb': {
//       color: '#33cf4d',
//       border: '6px solid #fff',
//     },
//     '&.Mui-disabled .MuiSwitch-thumb': {
//       color:
//         theme.palette.mode === 'light'
//           ? theme.palette.grey[100]
//           : theme.palette.grey[600],
//     },
//     '&.Mui-disabled + .MuiSwitch-track': {
//       opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
//     },
//   },
//   '& .MuiSwitch-thumb': {
//     boxSizing: 'border-box',
//     width: 22,
//     height: 22,
//   },
//   '& .MuiSwitch-track': {
//     borderRadius: 26 / 2,
//     backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
//     opacity: 1,
//     transition: theme.transitions.create(['background-color'], {
//       duration: 500,
//     }),
//   },
// }));

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        // backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
        backgroundColor: "#3dbf73ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const CustomStatus = ({ row }) => (
  <Box sx={{ display: "flex", alignItems: "center" }}>
    <FormControlLabel
      size="medium"
      control={<AntSwitch sx={{ m: 1 }} defaultChecked />}
    />
  </Box>
);

const CustomContact = ({ row }) => (
  <Box display={"flex"}>
    <IconButton size="small">
      <img src={WhatsAppIcon} alt="" width={"100%"} />
    </IconButton>
    <IconButton size="small">
      <img src={GmailIcon} alt="" width={"100%"} />
    </IconButton>
  </Box>
);

const CustomFeedback = ({ row }) => (
  <Box>
    <input
      type="text"
      name=""
      id=""
      style={{
        borderRadius: 5,
        padding: "5px 5px",
        width: "100%",
        border: "none",
        background: "#dfe6e0",
      }}
      placeholder="write here..."
    />
  </Box>
);

const columns = [
  // {
  //   name: 'ID',
  //   selector: row => row.id,
  //   sortable: true,
  //   width: '70px',
  //   // center: true,
  //   // style: {
  //   //     textAlign: 'center', // Center align the header
  //   // },
  // },
  {
    name: "Accounts",
    selector: (row) => row.name,
    sortable: true,
    width: "200px",
    cell: (row) => <CustomAccounts row={row} />,
  },
  {
    name: "Categories",
    selector: (row) => row.categories,
    sortable: true,
    reorder: true,
    width: "160px",
    cell: (row) => <Customcategory row={row} />,
    // center: true
  },
  {
    name: "Followers",
    selector: (row) => "12m",
    sortable: true,
    reorder: true,
    // center: 'true',
    // width: '110px',
  },
  {
    name: (
      <Box display="flex" alignItems="center">
        <span>ER%</span>
        <Tooltip title="Engagement Rate" arrow placement="top">
          <IconButton size="small">
            <InfoOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    selector: (row) => "18.2%",
    sortable: true,
    reorder: true,
    // width: '100px',
    // center: true,
  },
  {
    name: (
      <Box display="flex" alignItems="center">
        <span>Avg.L/C</span>
        <Tooltip title="Average Likes & Comments" arrow placement="top">
          <IconButton size="small">
            <InfoOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    selector: (row) => row.average,
    sortable: true,
    reorder: true,
    cell: (row) => <CustomAverage row={row} />,
    // width: '125px',
    // center: true
  },
  {
    name: "Status",
    // selector: row => row.actions,
    reorder: true,
    width: "80px",
    cell: (row) => <CustomStatus row={row} />,
  },
  {
    name: "Contact",
    reorder: true,
    width: "130px",
    cell: (row) => <CustomContact row={row} />,
    center: "true",
  },
  {
    name: "Feedback",
    reorder: "false",
    cell: (row) => <CustomFeedback row={row} />,
    width: "200px",
  },
].map((column) => ({
  ...column,
  // style : {
  //     fontSize : '15px',
  // }
}));

const customStyles = {
  head: {
    style: {
      fontSize: "15px", // Set the desired font size for the header
    },
  },
  table: {
    style: {
      overflowX: "auto", // or 'hidden' or 'scroll'
    },
  },
  rows: {
    style: {
      '&:hover': {
        '.rdt_TableCell:first-child': {
          visibility: 'visible',
        },
      },
    },
  },
  cells: {
    style: {
      // Hide the first cell (checkbox) by default
      '&:first-child': {
        visibility: 'hidden',
      },
    },
  },
};

const InfluencerTable = ({ influencers }) => {
  // const [influencer, setInfluencer] = useState(data);
  return (
    
      <DataTable
        // title="Influencers"
        columns={columns}
        data={influencers}
        fixedHeader
        pagination
        selectableRows
        // onRowClicked={handleRowClick}
        customStyles={customStyles}
        pointerOnHover
        selectableRowsHighlight
        // sortIcon={<CustomSortIcon />}
        // progressPending={pending}
        // progressComponent={<CircularProgress />}
        
        fixedHeaderScrollHeight="550px"
        sortIcon={<CustomSortIcon />}
        // style={{ overflowX: 'scroll' , width: '100%'}}
      />
   
  );
};

export default InfluencerTable;
