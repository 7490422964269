import React, { useState } from "react";
import Papa from "papaparse";
import axios from "axios";
import { Button } from "@mui/material";

const URL = process.env.REACT_APP_BASE_URL;

function CsvFileUploader() {
  const [csvData, setCsvData] = useState([]);
  const [uploadStatus, setUploadStatus] = useState("");

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const csv = e.target.result;
        const results = Papa.parse(csv, { header: true });
        setCsvData(results.data);
        console.log("CSV Data:", results.data);
        setUploadStatus(file.name);
      };
      reader.readAsText(file);
    }
  };

  const sendDataToApi = () => {
    const apiUrl = `${URL}/fetchytdata`;

    const requestBody = {
      ytids: csvData,
    };

    axios
      .post(apiUrl, requestBody)
      .then((response) => {
        console.log("API response:", response);
        setUploadStatus("File uploaded successfully!");
      })
      .catch((error) => {
        console.error("Error sending data to API:", error);
        setUploadStatus("Error sending data to API.");
      });
  };

  return (
    <div>
      {!csvData.length ? (
        <>
          <input type="file" onChange={handleFileUpload} />
        </>
      ) : (
        <>
          <Button
            variant="contained"
            onClick={sendDataToApi}
            disabled={!csvData.length}
          >
            Send
          </Button>
        </>
      )}

      {uploadStatus && (
        <p
          style={{
            marginTop: "1rem",
          }}
        >
          {uploadStatus}
        </p>
      )}
    </div>
  );
}

export default CsvFileUploader;
