
import FindInfluencer from "../../FindInfluencer"
import Home from "../../Home"
import { Box, useMediaQuery, styled } from "@mui/material";
// import { Route, Routes } from "react-router-dom"
import { Outlet } from "react-router-dom";

const MiniDrawerRoute = () => {


  const isXsScreen = useMediaQuery('(max-width: 600px)'); // adjust size for XS

  const isMdScreen = useMediaQuery('(min-width: 601px) and (max-width: 960px)');

  // const Main = styled(Box)(({theme})=>({

  // }))

  return (
    <>
      <Box
        sx={{
          position: isXsScreen ? 'relative' : 'static',
          left: isXsScreen ? 60 : 0,
          width: isXsScreen ? '82%' : '100%'

        }}
      >
        <Outlet />
      </Box>

    </>
  )
}

export default MiniDrawerRoute