import React, { useEffect, useState } from "react";
import {
  FormControl,
  Divider,
  Select,
  MenuItem,
  Slider,
  TextField,
  Box,
  styled,
  Chip,
  IconButton,
  Tooltip,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";

const SearchNTag = ({
  text,
  setSearchNTagFilterState,
  setMentionsState,
  setHashtagsState,
  clear,
  tooltipInfo,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [tags, setTags] = useState([]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setSearchNTagFilterState(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (inputValue.trim() !== "") {
        setTags((prevTags) => [...prevTags, inputValue.trim()]);
        setInputValue("");
      }
    }
  };

  const handleTagDelete = (tag) => {
    setTags((prevTags) => prevTags.filter((t) => t !== tag));
  };

  useEffect(() => {
    if (clear) {
      setInputValue("");
      setTags([]);
    }
  }, [clear]);

  if (setMentionsState) setMentionsState(inputValue);
  else setHashtagsState(inputValue);

  return (
    <Accordion sx={{ width: { md: "80%", xs: "90%" }, boxShadow: "none" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: 1,
          }}
        >
          <IconButton style={{ color: "#3DBE72" }}>
            <Tooltip title={tooltipInfo}>
              <HelpOutlineRoundedIcon
                style={{ fontSize: 12, padding: 0, margin: 0 }}
              />
            </Tooltip>
          </IconButton>
          <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
        </Box>
      </AccordionSummary>

      <AccordionDetails sx={{ textAlign: "start" }}>
        <TextField
          id="outlined-basic"
          label={text}
          variant="outlined"
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          sx={{
            width: "100%",
            "& .MuiInputLabel-root": {
              color: "#3dbe72",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#3dbe72",
              },
              "&:hover fieldset": {
                borderColor: "#3dbe72",
              },
            },
            "& .MuiInputBase-input": {
              color: "#3dbe72",
            },
          }}
        />
        <Box sx={{ mt: 1, display: "flex", flexWrap: "wrap" }}>
          {tags.map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              onDelete={() => handleTagDelete(tag)}
              sx={{ ml: 1, bgcolor: "#3DBE72", color: "#fff" }}
            />
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default SearchNTag;
