import React from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material';


const UserProfile = () => {
  const user = useSelector((state) => state.user.user);


  // console.log(createdUser);

  return (
    <>
      <Box>
        {
        
        }
      </Box>
    </>
  )
}

export default UserProfile