

import { Box, Card, CardContent, Grid, Typography, IconButton, Button, styled } from "@mui/material"
import { Link } from "react-router-dom";
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import EastIcon from '@mui/icons-material/East';
import ControlPointRoundedIcon from '@mui/icons-material/ControlPointRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import LightbulbRoundedIcon from '@mui/icons-material/LightbulbRounded';
import Groups2RoundedIcon from '@mui/icons-material/Groups2Rounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';

const Container = styled(Grid)(({ theme }) => ({

  display: 'flex',
  justifyContent: 'center',
  padding: '0px 50px',
  marginBottom : '2rem',
  [theme.breakpoints.down('md')]: {

    padding: '0px 10px',
  },
  [theme.breakpoints.down('sm')]: {

    padding: '0px 0px',
  },

}))




const CardContaner = styled(Card)`
  border-radius : 15px;
  border : 1px solid black;

`

const Title = styled(Typography)`

  padding : 5px 20px;
  background : #3DBE72;
  color : #FFFFFF;
  font-weight : bold;
  display : flex;
  justify-content : space-between;
  align-items : center;
  

  
`
const CardBody = styled(CardContent)(({ theme }) => ({
  'minHeight': '85px',
  'display': 'flex',
  'flexDirection': 'column',
  'alignItems': 'center',
  'justifyContent': 'spaceEvenly'
}))

const FlexBox = styled(Box)`

display : flex;
justify-content : space-between;
`

const FooterBtn = styled(Typography)`

  font-weight : bold;
  padding : 8px 20px;
  font-size : 13px;
  

`

const ViewProfileBtn = styled(Button)(({ theme }) => ({

  background: '#FFFFFF',
  color: 'black',
  border: 'none',
  boxShadow: 'none',
  borderRadius: '50px',
  padding: '2px 20px',
  textTransform: 'none',
  '&:hover': {
    background: '#FFFFFF',
    boxShadow: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '2px 10px',
  }
}))






const ViewCampaigns = styled(Button)(({ theme }) => ({

  // background : '#FFFFFF',
  color: 'black',
  fontWeight: 'bold',
  border: '1px solid black',

  // boxShadow : 'none',
  borderRadius: '50px',
  textTransform: 'none',
  '&:hover': {
    background: '#FFFFFF',
    // boxShadow : 'none',
    border: '1px solid black',
  }
}))



const CreateCampaignBtn = styled(Button)(({ theme }) => ({

  background: '#3DBE72',
  color: '#FFFFFF',
  border: 'none',
  boxShadow: 'none',
  borderRadius: '50px',
  textTransform: 'none',
  margin: 15,
  '&:hover': {
    boxShadow: 'none',
    background: '#3DBE72',
  }
}))

const LearnCampaignLink = styled(Link)`

  color : gray;
  display : flex;
  margin : 5rem 0px;
  display : flex;
  align-items : center;
  
  
  

`

const BtnGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '220px',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    marginTop: 10
  }
}))


const RecentProfilesSubHead = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center'
  }
}))




const SavedBtn = styled(Button)(({ theme }) => ({

  textTransform: 'none',
  background: '#3DBE72',
  boxShadow: 'none',
  padding: '4px 25px',
  borderRadius: 18,
  '&:hover': {
    boxShadow: 'none',
    background: '#3DBE72',
  }

}))

const Connected = styled(Button)(({ theme }) => ({

  textTransform: 'none',
  background: '#DDE8F8',
  color: 'black',
  padding: '4px 25px',
  boxShadow: 'none',
  borderRadius: 18,
  border: 'none',
  '&:hover': {
    boxShadow: 'none',
    background: '#3DBE72',
    color: '#FFFFFF',
    border: 'none',
  }

}))



const Cards = () => {
  return (
    <Container container spacing={{ md: 4, xs: 2 }} align='center' >

      {/* FIRST ROW  */}
      <Grid item xs={11} md={4}>
        <CardContaner>
          <Title>
            <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>Influencers</Typography>
            <IconButton size="small" style={{ color: "#FFFFFF" }}>
              <ListAltRoundedIcon />
            </IconButton>
          </Title>
          <CardBody>
          </CardBody>
          <FlexBox>
            <FooterBtn>Connected : 10</FooterBtn>
            <FooterBtn>Total : 10</FooterBtn>
          </FlexBox>
        </CardContaner>
      </Grid>

      <Grid item xs={11} md={4}>
        <CardContaner>
          <Title>
            <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>Brands</Typography>
            <IconButton size="small" style={{ color: "#FFFFFF" }}>
              <SummarizeOutlinedIcon />
            </IconButton>
          </Title>
          <CardBody>
          </CardBody>
          <FlexBox>
            <FooterBtn>Enquiry : 10</FooterBtn>
            <FooterBtn>Total : 10</FooterBtn>
          </FlexBox>
        </CardContaner>
      </Grid>

      <Grid item xs={11} md={4}>
        <CardContaner>
          <Title>
            <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>Employees</Typography>
            <IconButton size="small" style={{ color: "#FFFFFF" }}>
              <PersonRoundedIcon />
            </IconButton>
          </Title>
          <CardBody>
          </CardBody>
          <FlexBox>
            {/* <FooterBtn>Connected : 10</FooterBtn> */}
            <FooterBtn>Total : 10</FooterBtn>
          </FlexBox>
        </CardContaner>
      </Grid>

      {/* SECOND ROW  */}
      <Grid item xs={11} md={4}>
        <CardContaner>
          <Title>
            <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>Collection</Typography>
            <IconButton size="small" style={{ color: "#FFFFFF" }}>
              <LightbulbRoundedIcon />
            </IconButton>
          </Title>
          <CardBody>
          </CardBody>
          <FlexBox>
            {/* <FooterBtn>Enquiry : 10</FooterBtn> */}
            <FooterBtn>Total : 10</FooterBtn>
          </FlexBox>
        </CardContaner>
      </Grid>

      <Grid item xs={11} md={4}>
        <CardContaner>
          <Title>
            <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>Campaign</Typography>
            <IconButton size="small" style={{ color: "#FFFFFF" }}>
              <Groups2RoundedIcon />
            </IconButton>
          </Title>
          <CardBody>
          </CardBody>
          <FlexBox>
            <FooterBtn>Active : 10</FooterBtn>
            <FooterBtn>Inactive : 2</FooterBtn>
          </FlexBox>
        </CardContaner>
      </Grid>

      <Grid item xs={11} md={4}>
        <CardContaner>
          <Title>
            <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>Visitors</Typography>
            <IconButton size="small" style={{ color: "#FFFFFF" }}>
              <PeopleAltRoundedIcon />
            </IconButton>
          </Title>
          <CardBody>
          </CardBody>
          <FlexBox>
            <FooterBtn>Connected : 10</FooterBtn>
            <FooterBtn>Total : 10</FooterBtn>
          </FlexBox>
        </CardContaner>
      </Grid>


      {/* THIRD ROW  */}
      <Grid item xs={11} md={8}>
        <CardContaner>
          <Title>
            <Typography style={{ fontSize: 14, fontWeight: 'bold', padding: '8px 0px' }}>Recent Profiles</Typography>
            <ViewProfileBtn size="small" variant="contained" endIcon={<EastIcon />}>
              View Profiles
            </ViewProfileBtn>
            {/* <IconButton style={{ color: "#FFFFFF" }}>
              View Profiles
              <ListAltRoundedIcon />
            </IconButton> */}
          </Title>
          <CardBody>
            <RecentProfilesSubHead>
              <Typography fontSize={14} style={{ fontWeight: 'bold' }}>Saved Profiles</Typography>
              <BtnGroup>
                <SavedBtn size="small" variant="contained">Saved</SavedBtn>
                <Connected size="small" variant="outlined">Connected</Connected>
              </BtnGroup>
            </RecentProfilesSubHead>
            <LearnCampaignLink> <InfoOutlinedIcon style={{ fontSize: 12 }} /> <span style={{ fontSize: 12 }}>How to save an influencer profile?</span> </LearnCampaignLink>
          </CardBody>
        </CardContaner>
      </Grid>

      <Grid item md={4} xs={11}>
        <CardContaner>
          <Title>
            <Typography style={{ fontSize: 14, fontWeight: 'bold', padding: '8px 0px' }}>Recent Campaigns</Typography>

            {/* <IconButton style={{ color: "#FFFFFF" }}>
              <ListAltRoundedIcon />
            </IconButton> */}
          </Title>
          <CardBody>
            <ViewCampaigns size="small" variant="outlined" endIcon={<EastIcon />}>
              View Campaigns
            </ViewCampaigns>

            <CreateCampaignBtn size="small" variant="contained" endIcon={<ControlPointRoundedIcon />}>
              Create New Campaign
            </CreateCampaignBtn>

            <LearnCampaignLink style={{ margin: '3.1rem 0rem' }}> <InfoOutlinedIcon style={{ fontSize: 12 }} /> <span style={{ fontSize: 12 }}>Learn campaign management.</span> </LearnCampaignLink>
          </CardBody>

        </CardContaner>
      </Grid>

    </Container>
  )
}

export default Cards