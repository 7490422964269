
const rowData = [
  {
    id: 1,
    brand: "Coca-cola",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    campaign: 'Media Conclave',
    status: 'Under Review',
    platform: {
      logo: "https://e7.pngegg.com/pngimages/875/247/png-clipart-product-design-number-logo-instalogo-purple-text.png",
      name: "Instagram"
    },
    date: "15th Nov 2023"
  },
  {
    "id": 2,
    "brand": "Nike",
    "logo": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    "campaign": "Just Do It",
    "status": "Approved",
    "platform": {
      "logo": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/1024px-Instagram_logo_2016.svg.png",
      "name": "Instagram"
    },
    "date": "20th Jan 2024"
  },

  {
    "id": 3,
    "brand": "Apple",
    "logo": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    "campaign": "Think Different",
    "status": "Pending",
    "platform": {
      "logo": "https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-twitter-social-media-round-icon-png-image_6315985.png",
      "name": "Twitter"
    },
    "date": "5th Feb 2024"
  }
  ,
  {
    "id": 4,
    "brand": "Adidas",
    "logo": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    "campaign": "Impossible is Nothing",
    "status": "Rejected",
    "platform": {
      "logo": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Facebook_icon_2013.svg/800px-Facebook_icon_2013.svg.png",
      "name": "Facebook"
    },
    "date": "10th Mar 2024"
  }
  ,
  {
    "id": 5,
    "brand": "Samsung",
    "logo": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    "campaign": "Do What You Can't",
    "status": "Under Review",
    "platform": {
      "logo": "https://cdn4.iconfinder.com/data/icons/social-media-icons-the-circle-set/48/youtube_circle-512.png",
      "name": "YouTube"
    },
    "date": "18th Apr 2024"
  },
  {
    "id": 6,
    "brand": "Microsoft",
    "logo": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    "campaign": "Empower Every Person",
    "status": "Approved",
    "platform": {
      "logo": "https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-twitter-social-media-round-icon-png-image_6315985.png",
      "name": "Twitter"
    },
    "date": "25th May 2024"
  }
  ,
  {
    "id": 7,
    "brand": "Amazon",
    "logo": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    "campaign": "A to Z",
    "status": "Pending",
    "platform": {
      "logo": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Facebook_icon_2013.svg/800px-Facebook_icon_2013.svg.png",
      "name": "Facebook"
    },
    "date": "3rd Jun 2024"
  }
  ,
  {
    "id": 8,
    "brand": "Google",
    "logo": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    "campaign": "Don't Be Evil",
    "status": "Rejected",
    "platform": {
      "logo": "https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-linkedin-512.png",
      "name": "LinkedIn"
    },
    "date": "12th Jul 2024"
  }
  ,
  {
    "id": 9,
    "brand": "Sony",
    "logo": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    "campaign": "Be Moved",
    "status": "Under Review",
    "platform": {
      "logo": "https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-twitter-social-media-round-icon-png-image_6315985.png",
      "name": "Twitter"
    },
    "date": "21st Aug 2024"
  }
  ,
  {
    "id": 10,
    "brand": "Tesla",
    "logo": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    "campaign": "Accelerating the World's Transition to Sustainable Energy",
    "status": "Approved",
    "platform": {
      "logo": "https://cdn4.iconfinder.com/data/icons/social-media-icons-the-circle-set/48/youtube_circle-512.png",
      "name": "YouTube"
    },
    "date": "9th Sep 2024"
  }
  ,
  {
    "id": 11,
    "brand": "Puma",
    "logo": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/86/Puma_logo.svg/1280px-Puma_logo.svg.png",
    "campaign": "Forever Faster",
    "status": "Pending",
    "platform": {
      "logo": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/1024px-Instagram_logo_2016.svg.png",
      "name": "Instagram"
    },
    "date": "17th Oct 2024"
  }


]

export { rowData }