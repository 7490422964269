import React, { useState } from "react";
import {
  Modal,
  Box,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
  Button,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "10px",
  transform: "translate(-50%, -50%)",
  width: 300,
  height: "max-content",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  gap: "7px",
  alignItems: "start",
  justifyContent: "center",
};

function GearDropdown1({ can, setCan }) {
  const [open, setOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("");
  const [priority, setPriority] = useState("");
  const [toggleStates, setToggleStates] = useState({
    srNo:
      priority == "low"
        ? true
        : priority == "medium"
        ? true
        : priority == "high"
        ? true
        : false,
    profile:
      priority == "low"
        ? true
        : priority == "medium"
        ? true
        : priority == "high"
        ? true
        : false,
    collectionName:
      priority == "low"
        ? true
        : priority == "medium"
        ? true
        : priority == "high"
        ? true
        : false,
    numInfluencers:
      priority == "low"
        ? true
        : priority == "medium"
        ? true
        : priority == "high"
        ? true
        : false,
    brand:
      priority == "low"
        ? true
        : priority == "medium"
        ? true
        : priority == "high"
        ? true
        : false,
    createdOn:
      priority == "low"
        ? true
        : priority == "medium"
        ? true
        : priority == "high"
        ? true
        : false,
    status:
      priority == "low"
        ? true
        : priority == "medium"
        ? true
        : priority == "high"
        ? true
        : false,
    actions:
      priority == "low"
        ? true
        : priority == "medium"
        ? true
        : priority == "high"
        ? true
        : false,
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDropdownChange = (event) => {
    const priority = event.target.value;
    setDropdownValue(priority.toLowerCase());
    setCan(priority);

    switch (priority) {
      case "low":
        setToggleStates({
          srNo: true,
          profile: true,
          collectionName: true,
          numInfluencers: false,
          brand: false,
          createdOn: false,
          status: false,
          actions: false,
        });
        break;
      case "medium":
        setToggleStates({
          srNo: true,
          profile: true,
          collectionName: true,
          numInfluencers: false,
          brand: true,
          createdOn: false,
          status: true,
          actions: false,
        });
        break;
      case "high":
        setToggleStates({
          srNo: true,
          profile: true,
          collectionName: true,
          numInfluencers: true,
          brand: true,
          createdOn: true,
          status: true,
          actions: true,
        });
        break;
      default:
        break;
    }
  };

  const handleToggleChange = (event) => {
    setToggleStates({
      ...toggleStates,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <div>
      <IconButton onClick={handleOpen}>
        <SettingsIcon />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <h2 id="modal-title">Email Link Access</h2>
          <FormControl
            fullWidth
            sx={{
              border: "#3dbf73ff",
              color: "#3dbf73ff",
              "&:focus": { border: "#3dbf73ff", color: "#3dbf73ff" },
              "&:active": { border: "#3dbf73ff", color: "#3dbf73ff" },
              "&:outline": { border: "#3dbf73ff", color: "#3dbf73ff" },
            }}
          >
            <InputLabel
              sx={{ border: "#3dbf73ff", color: "#3dbf73ff" }}
              id="dropdown-label"
            >
              Priority
            </InputLabel>
            <Select
              labelId="dropdown-label"
              id="dropdown"
              value={dropdownValue}
              label="Priority"
              onChange={handleDropdownChange}
              sx={{
                border: "#3dbf73ff",
                color: "#3dbf73ff",
              }}
            >
              <MenuItem sx={{ fontWeight: "bold" }} value="low">
                Low
              </MenuItem>
              <MenuItem sx={{ fontWeight: "bold" }} value="medium">
                Medium
              </MenuItem>
              <MenuItem sx={{ fontWeight: "bold" }} value="high">
                High
              </MenuItem>
            </Select>
          </FormControl>
          <Box
            mt={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "7px",
              alignItems: "start",
              justifyContent: "center",
            }}
          >
            {Object.keys(toggleStates).map((key) => (
              <FormControlLabel
                key={key}
                control={
                  <Switch
                    checked={toggleStates[key]}
                    onChange={handleToggleChange}
                    name={key}
                    color="success"
                    sx={{
                      color: "#3dbf73ff",
                      "&:focus": {
                        color: "#3dbf73ff",
                      },
                      "&:active": {
                        color: "#3dbf73ff",
                      },
                    }}
                  />
                }
                label={
                  key.charAt(0).toUpperCase() +
                  key.slice(1).replace(/([A-Z])/g, " $1")
                }
              />
            ))}
          </Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#3dbf73ff",
              "&:hover": {
                backgroundColor: "#3dbf73ff",
              },
            }}
            onClick={handleClose}
            fullWidth
          >
            Share
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default GearDropdown1;
