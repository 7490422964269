import React, { useState } from 'react'
import { Box, Button, TextField, Typography, styled, IconButton, InputAdornment, CircularProgress } from '@mui/material'

import { Instagram, YouTube, TaskAlt, } from '@mui/icons-material'

import Logo from '../images/logo_CreatorHunt-white.png'
import insta from '../images/insta-sm.png'
import youtube from '../images/youtube-sm.png'

// APIs
import { addInstaAccount } from '../../service/addSocialMediaAPI'



const Container = styled(Box)(({ theme }) => ({

    display: 'flex',
    // height : '100vh',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '2rem',
    // justifyContent : 'center'   

    [theme.breakpoints.down('sm')]: {
        paddingLeft: '1rem'
    }


}))

const MyButton = styled(Button)(({ theme }) => ({

    color: 'Black',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: '13px',
    background: '#3DBE7233',
    border: '1px solid #3DBE72',
    boxShadow: 'none',
    marginRight: 10,
    width: '150px',
    '&:hover': {
        background: '#3DBE7233',
        border: '1px solid #3DBE72',
        boxShadow: 'none',
    }
}))

const LoadingSpinner = styled(CircularProgress)(({ theme }) => ({
    color: 'white',
    marginRight: '8px', // Some spacing between spinner and text
}));


const SignupForAddSocialMedia = () => {

    const [insta, setInsta] = useState('');
    const [updateInstaLoader, setUpdateInstaLoader] = useState(false);
    const [isInstaUpdate, setIsInstaUpdate] = useState({ status: false, message: "" });

    const handleInstagramId = (e) => {
        if (e.target.value == '') {
            setIsInstaUpdate({ status: false, message: "" })
        }
        setInsta(e.target.value)
        // console.log(insta);
    }

    const handleAddInstaAccount = async () => {
        setIsInstaUpdate({ status: false, message: "" })
        setUpdateInstaLoader(true);
        let payload = {
            "id": 21,
            "instagram_id": insta
        }
        const res = await addInstaAccount(payload);
        console.log(res);


        if (res.data.status === 201) {
            setIsInstaUpdate({ status: true, message: res.data.message })

        }
        setUpdateInstaLoader(false)
    }

    return (
        <Container >
            <Box>
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={Logo} alt="" width={170} />
                </Box>
                <Box>
                    <Typography style={{ fontWeight: 'bold', fontSize: 15, margin: '5px 0px' }}>Create Your Account</Typography>
                    <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>Add a Social Media Platform for Influencer</Typography>
                </Box>


                <Box style={{ margin: '2rem 0px' }}>
                    <Typography style={{ fontWeight: 'bold', fontSize: 15, marginBottom: 10 }}>1. Add your Instagram Account</Typography>
                    <p style={{ fontSize: 12, marginBottom: 5, color: '#3dbf73ff' }}>{isInstaUpdate.status && isInstaUpdate.message}</p>
                    <TextField
                        size='small'
                        placeholder='Enter your Instagram username'
                        onChange={handleInstagramId}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton edge="start">
                                        <Instagram style={{ color: '#E4405F' }} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}

                    />

                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                        flexDirection: 'column',
                        marginTop: '.5rem'
                    }}>


                        <Button size='small' variant='contained'
                            onClick={handleAddInstaAccount}
                            endIcon={isInstaUpdate.status ? <TaskAlt /> : (updateInstaLoader && <LoadingSpinner size={25} />)}
                            disabled={updateInstaLoader || insta == ""}
                            sx={{
                                fontWeight: 'bold',
                                boxShadow: 'none',
                                background: "#3dbf73ff",
                                '&:hover': {
                                    background: "#3dbf73ff",
                                }
                            }}
                        >
                            {
                                isInstaUpdate.status ? "Added" : "Add"
                            }
                        </Button>
                    </Box>
                </Box>

                <Box>
                    <Typography style={{ fontWeight: 'bold', fontSize: 15, marginBottom: 10 }}>2. Add your Youtube Channel</Typography>


                    <TextField
                        size='small'
                        placeholder='Enter your Youtube Channel'
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton edge="start">
                                        <YouTube style={{ color: '#FF0000' }} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}

                    />

                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: '.5rem'
                    }}>
                        <Button size='small' variant='contained'
                            sx={{
                                fontWeight: 'bold',
                                boxShadow: 'none',
                                background: "#3dbf73ff",
                                '&:hover': {
                                    background: "#3dbf73ff",
                                }
                            }}
                        >Add</Button>
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}

export default SignupForAddSocialMedia