import React, { useEffect, useState } from "react";
import {
  FormControl,
  Divider,
  Select,
  MenuItem,
  Slider,
  TextField,
  Box,
  styled,
  IconButton,
  Tooltip,
  Tabs,
  Tab,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import CloseIcon from "@mui/icons-material/Close";

const AudienceSearchDropdownFilter = ({
  text,
  options,
  setAudienceFilterState,
  setAudienceLocationState,
  setAudienceLanguageState,
  setAudienceInterestState,
  setAudienceMostPostedState,
  clear,
  tooltipInfo,
}) => {
  const [value, setValue] = useState(options[0]);
  const [inputValue, setInputValue] = useState("");

  const [percentageValue, setPercentageValue] = useState(50);

  const handlePercentageChange = (event, newValue) => {
    setPercentageValue(newValue);
    setAudienceFilterState({
      selectedValue: value,
      percentage: percentageValue,
    });
    if (setAudienceLocationState)
      setAudienceLocationState({
        location: value,
        percentage: percentageValue,
      });
    else if (setAudienceLanguageState)
      setAudienceLanguageState({
        language: value,
        percentage: percentageValue,
      });
    else if (setAudienceMostPostedState)
      setAudienceMostPostedState({
        mostPosted: value,
        percentage: percentageValue,
      });
    else
      setAudienceInterestState({
        interest: value,
        percentage: percentageValue,
      });
  };

  const [tabValue, setTabValue] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelect = (newValue) => {
    if (!selectedItems.includes(newValue)) {
      setSelectedItems((prevItems) => [...prevItems, newValue]);
    }
  };

  const handleTagDelete = (value) => {
    setSelectedItems((prevValues) => prevValues.filter((v) => v !== value));
  };

  useEffect(() => {
    if (clear) {
      setPercentageValue(50);
      setValue(options[0]);
      setSelectedItems([]);
    }
  }, [clear]);

  return (
    <Accordion sx={{ width: { md: "80%", xs: "90%" }, boxShadow: "none" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ textAlign: "start" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: 1,
          }}
        >
          <IconButton style={{ color: "#3DBE72" }}>
            <Tooltip title={tooltipInfo}>
              <HelpOutlineRoundedIcon
                style={{ fontSize: 12, padding: 0, margin: 0 }}
              />
            </Tooltip>
          </IconButton>
          <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
        </Box>
      </AccordionSummary>

      <AccordionDetails sx={{ textAlign: "start" }}>
        <Box>
          <Autocomplete
            color="success"
            value={value}
            onChange={(event, newValue) => {
              if (newValue) {
                setValue(newValue);
                handleSelect(newValue);
                setAudienceFilterState({
                  selectedValue: value,
                  percentage: percentageValue,
                });
                if (setAudienceLocationState)
                  setAudienceLocationState({
                    location: value,
                    percentage: percentageValue,
                  });
                else if (setAudienceLanguageState)
                  setAudienceLanguageState({
                    language: value,
                    percentage: percentageValue,
                  });
                else
                  setAudienceInterestState({
                    interest: value,
                    percentage: percentageValue,
                  });
              } else {
                setValue("");
                setInputValue("");
              }
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            id="controllable-states-demo"
            options={options}
            sx={{
              width: "100%",
              "& .MuiInputLabel-root": {
                color: "#3dbe72",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#3dbe72",
                },
                "&:hover fieldset": {
                  borderColor: "#3dbe72",
                },
              },
              "& .MuiInputBase-input": {
                color: "#3dbe72",
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  "& .MuiInputLabel-root": {
                    color: "#3dbe72",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#3dbe72",
                    },
                    "&:hover fieldset": {
                      borderColor: "#3dbe72",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#3dbe72",
                  },
                }}
                label={text}
              />
            )}
          />
        </Box>
        {selectedItems.length > 0 && (
          <Box>
            <Tabs
              value={tabValue} // Use the state here
              onChange={(event, newValue) => setTabValue(newValue)} // Update the state here
              aria-label="OR AND Tabs"
              sx={{
                "& .MuiTab-root": {
                  color: "#3DBE72",
                  "&.Mui-selected": {
                    color: "#3DBE72",
                  },
                },
              }}
            >
              <Tab label="OR" />
              <Tab label="AND" />
            </Tabs>
            <Box
              sx={{
                padding: "7px 15px",
                borderRadius: "10px",
                marginTop: "10px",
                bgcolor: "#f0f3f6",
              }}
            >
              {selectedItems.map((item) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ padding: "5px", fontWeight: "bold" }}>
                      {item}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 1,
                      }}
                    >
                      <TextField
                        value={percentageValue}
                        type="number"
                        size="small"
                        sx={{ width: 80, padding: "5px" }}
                        onChange={handlePercentageChange}
                      />
                      <IconButton
                        size="small"
                        onClick={() => handleTagDelete(item)}
                        sx={{ padding: 0, margin: 0 }}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default AudienceSearchDropdownFilter;
