import {
  Grid,
  Typography,
  AvatarGroup,
  Avatar,
  Box,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Tabs,
  styled,
  Tab,
  BottomNavigation,
  BottomNavigationAction,
  Dialog,
  Tooltip,
  ListItem,
  CircularProgress,
} from "@mui/material";
import {
  Search,
  AddRounded,
  ShareRounded,
  BookmarkBorderRounded,
  VisibilityOffRounded,
  CloseRounded,
  FilterAltOutlined,
  SwapVertOutlined,
  CancelOutlined,
  WarningAmberOutlined,
  Add,
  AddTaskOutlined,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { NavLink, Outlet, useParams } from "react-router-dom";
import myStyle from "../../styles/CollectinProfile.module.css";
import YoutubeIcon from "../../images/youtube-sm.png";
import InstaIcon from "../../images/insta-sm.png";
import { width } from "@mui/system";
import { getCollectionById } from "../../../service/collections api/getCollectionById";
import { getBrandByID } from "../../../service/brands/getBrandByID";
import { getAllInfluencers } from "../../../service/getAllApi";
import InfluencerTable from "./InfluencerTable";
import Analytics from "./Analytics";
import { getAllBrands } from "../../../service/brands/getAllBrands";
import AddNewBrand from "./AddNewBrand";
import { updateCollection } from "../../../service/collections api/createCollection";

const img1 =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToQeYeClZMrqqq2aaTT2UdbuWtVktz8jQ9gw&usqp=CAU";

const navLinkStyle = {
  textDecoration: "none",
  color: "black",
  padding: "10px",
};

const MyInput = styled("input")(`
    background : #dce7f7ff;
    outlined : none;
    border : none;
    width : 100%;
    padding : 15px 10px;
    border-radius : 8px;
    font-size : 15px;
    margin-top : 5px;
`);
const LoadingSpinner = styled(CircularProgress)(({ theme }) => ({
  color: "#FFFFFF",
}));

const MyTextArea = styled("textarea")(`
background : #dce7f7ff;
    outlined : none;
    border : none;
    width : 100%;
    padding : 15px 10px;
    border-radius : 8px;
    font-size : 15px;
    margin-top : 5px;
`);

const activeLinkStyle = {
  fontWeight: "bold",
  color: "blue",
};
const Mylabel = styled("label")(`
    color : #828282ff;
`);

const CollectionProfile = (props) => {
  const { id } = useParams();

  // Base64 decode function
  const decodeId = (encodedId) => atob(encodedId);
  const decodedId = decodeId(id);
  console.log("decoded collection id : ", decodedId);

  // const rowData = props.location?.state?.rowData;

  // console.log("profile : ", rowData);

  const [value, setValue] = React.useState("one");
  const [value2, setValue2] = React.useState("one");

  const [collection, setCollection] = useState({});
  const [brand, setBrand] = useState({});
  const [influencers, setInfluencers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createCollectionDialog, setCreateCollectionDialog] = useState(false);
  const [isSucessful, setIsSuccessful] = useState(false);
  const [addBrandDialog, setAddBrandDialog] = useState(false);
  const [brandName, setBrandName] = useState("");
  const [isBrandFocus, setIsBrandFocus] = useState(false);
  const [allBrands, setAllBrands] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState({
    message: "",
    status: false,
  });
  // const [editCollection, setEditCollection] = useState({
  //   name: collection.name || "",
  //   description: collection.description || "",
  //   assigned_brand_id: collection.assigned_brand_id || null,
  //   influncers_id: collection.influncers_id || null,
  //   created_by: id,
  // });

  // console.log("influencers collection ", collection);
  console.log("influlencers ", influencers);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
  };

  const handleCollectionChange = (e) => {
    const { name, value } = e.target;
    setCollection((prevCollection) => ({
      ...prevCollection,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError({ message: "", status: false });
    try {
      const response = await updateCollection(collection);
      console.log("updated collection ", response);
      setCollection(response);
      // Encryption
      // Base64 encode function
      // const encodeId = (id) => btoa(id.toString());
      // const encodedId = encodeId(response.id);

      // setEncodedNewCollectionId(encodedId);

      // console.log("Encoded ID:", encodedId);

      // handleNewCollection(response);
      // getCollection();
      // setAllCollection(preCollection => [...preCollection, response])

      setIsSuccessful(true);
      setError({ message: "", status: false });
    } catch (e) {
      setError({ message: "Collection doesn't exists.", status: true });
      console.error("Error while submiting  data : " + e);

      setIsSuccessful(false);
    } finally {
      setLoading(false);
    }
  };

  const closeCreateCollectionDialog = () => {
    setCreateCollectionDialog(false);
  };
  const openCreateCollectionDialog = () => {
    setCreateCollectionDialog(true);
  };

  const handleClose = () => {
    setCreateCollectionDialog(false);
    setError({ message: "", status: false });
    setIsSuccessful(false);
    setIsBrandFocus(false);
    setIsEditing(false);
    setBrandName("");
    setCollection({ ...collection });
    // handleNewCollection(newCollection);
    // getCollection();
  };
  const filteredBrands = allBrands?.filter((brand) =>
    brand.name.toLowerCase().includes(brandName.toLowerCase())
  );

  const handleListItemClick = (name, id) => {
    setBrandName(name);
    console.log("brand name ", brandName);

    setCollection((prevCollection) => {
      console.log("Updated collection:", {
        ...prevCollection,
        assigned_brand_id: id,
      });
      return { ...prevCollection, assigned_brand_id: id };
    });

    console.log("Clicked on:", name, "with ID:", id);
    console.log("payload collectin :", collection);
    setIsBrandFocus(false);
  };
  const getBrands = async () => {
    try {
      const response = await getAllBrands();
      //   console.log("all brands : ", response);
      setAllBrands(response);
    } catch (e) {
      console.error("Error while fetching all the collections" + e.message);
    } finally {
      console.log("api hit.");
    }
  };

  const openAddBrandDialog = () => {
    setAddBrandDialog(true);
  };
  const closeAddBrandDialog = () => {
    setAddBrandDialog(false);
  };

  const handleBrandChange = (e) => {
    const { value } = e.target;
    setBrandName(value);

    // setFilteredBrand
    // setFilteredBrand(filteredBrands)

    console.log(value);
  };

  const brandDetails = async (brand_id) => {
    try {
      const brandID = parseInt(brand_id);
      // console.log(brandID);
      const id_ = { id: brandID };
      // console.log("brand id : ", id_);
      const response = await getBrandByID(id_);
      console.log("brand data  : ", response);
      const brand = response.data.user;
      console.log("brand : ", brand);
      setBrand(brand);
      console.log("brand state : ", brand);
    } catch (e) {
      console.error("error while fetching  brand data by id : ", e.message);
    } finally {
    }
  };

  // Define a function to find influencer names by their IDs
  // const findInfluencerNames = () => {
  //   if (collection && collection.influencers_id && influencers.length > 0) {
  //     const influencerNames = collection.influencers_id.map(influencerId => {
  //       const foundInfluencer = influencers.find(influencer => influencer.id === influencerId);
  //       return foundInfluencer ? undInfluencer.name : ''; // Return the name if influencer is found, otherwise an empty string
  //     });
  //     return influencerNames;
  //   }
  //   return [];
  // }

  // const getInfluencers = async () => {

  //   try {
  //     // setFetchingInfluencer(true);
  //     const response = await getAllInfluencers();
  //     console.log("influencers on cp : ", response);
  //     // dispatch(setInfluencers(response));s
  //     setInfluencers(response)
  //     console.log("influencer on cp of state : ", influencers)

  //     // console.log("collection's influencers", collection.influncers_id);

  //   } catch (error) {
  //     console.error("Error fetching influencers:", error);
  //     // Handle the error (e.g., display an error message)
  //   } finally {
  //     // setFetchingInfluencer(false)
  //   }
  // };

  const getBrand = (id) => {
    const brand = allBrands?.filter((brand) => {
      // console.log(brand, id, "brand-id");

      return brand.id == id;
    });
    console.log(brand, "brand");
    return brand;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        window.scroll(0, 0);
        const id = { id: decodedId };
        const collectionResponse = await getCollectionById(id);
        const collectionData = collectionResponse.data.collection[0];
        console.log("collection by id : ", collectionData);
        setCollection(collectionData);

        if (collectionData) {
          console.log("brand id on cp: ", collectionData.assigned_brand_id);
          await brandDetails(collectionData.assigned_brand_id);

          // Parse influncers_id string into an array of numbers
          const influencerIdsArray = JSON.parse(collectionData.influncers_id);
          console.log("Influencer IDs Array: ", influencerIdsArray);

          // Get influencers data
          const influencersData = await getAllInfluencers();
          console.log("All influencers data: ", influencersData);

          // Filter influencers from influencersData based on collection's influencer IDs
          const filteredInfluencers = influencersData.filter((influencer) =>
            influencerIdsArray.includes(influencer.id)
          );
          console.log("Filtered influencers: ", filteredInfluencers);

          setInfluencers(filteredInfluencers);
        } else {
          console.log("No influencers found for this collection.");
          // Set influencers to an empty array or handle as needed
          setInfluencers([]);
        }
      } catch (e) {
        console.error(
          "error while fetching data collection data by id : ",
          e.message
        );
      }
    };

    fetchData();
    getBrands();

    // getInfluencers()
  }, []);

  // useEffect(() => {
  //   window.scroll(0, 0)
  //   collectionDetails();

  // }, [])

  return (
    <>
      <Grid container p={3}>
        <Grid item md={12} xs={12}>
          <Grid container>
            <Grid item md={2.5} xs={12}>
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  color: "#3dbf73ff",
                  fontWeight: "bold",
                }}
              >
                Collection
              </Typography>
            </Grid>
            <Grid item md={5} xs={8}>
              <TextField
                // label="Your Label"
                // variant="outlined"
                size="small"
                outline="false"
                placeholder="Search Influencers..."
                // onChange={handleSearch}
                fullWidth
                sx={{
                  borderRadius: 8, // Adjust the value as needed for your desired border radius

                  "& .MuiOutlinedInput-root": {
                    borderRadius: 8, // Adjust the value as needed for your desired border radius
                    // paddingRight: 0.4,
                    "&:hover fieldset": {
                      borderColor: "black", // Set the border color for hover state
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black", // Set the border color for focused state
                    },
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={4} xs={4} sx={{ pl: 3 }}>
              <Button
                sx={{
                  fontWeight: "bold",
                  color: "#3dbf73ff",
                  borderRadius: 20,
                  borderColor: "#3dbf73ff",
                  textTransform: "none",
                  "&:hover": { borderColor: "#3dbf73ff", borderWidth: "2px" },
                  borderWidth: "2px",
                }}
                variant="outlined"
                startIcon={<AddRounded />}
              >
                Add Influencers
              </Button>
            </Grid>
            {/* left  */}
            {/* <Grid item md={3} xs={12}>
              <Grid container>
                <Grid item md={12} xs={12} align='start' mt={2}>
                  <AvatarGroup total={24} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', position: 'relative' }}
                    componentsProps={{
                      additionalAvatar: {
                        sx: {
                          height: '50px',
                          width: '50px',
                          background: "grey",
                          color: '#FFFFFF',
                          border: '2px solid green',
                          position: 'relative',
                          zIndex: 1
                        }
                      }
                    }
                    }
                  >

                    <Avatar sx={{
                      width: 70,
                      height: 70,

                    }} alt="Remy Sharp" src="/static/images/avatar/1.jpg" />

                    <Avatar sx={{
                      width: 50,
                      height: 50
                    }} alt="Travis Howard" src="/static/images/avatar/2.jpg" />

                    <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />

                    <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />

                  </AvatarGroup>
                </Grid>
                <Grid item md={12} xs={12} mt={2}>
                  <Button variant='contained' size='small' sx={{ textTransform: 'none', width: '90%', background: '#3dbf73ff', borderRadius: 20, boxShadow: 'none', fontWeight: 'bold', '&:hover': { boxShadow: 'none', background: '#3dbf73ff' } }}>Assign to Campaign</Button>
                </Grid>
                <Grid item md={12} xs={12}>

                </Grid>
              </Grid>
            </Grid> */}
            <Grid
              item
              md={2.5}
              xs={12}
              align="start"
              sx={{ position: "relative" }}
            >
              <AvatarGroup
                total={24}
                componentsProps={{
                  additionalAvatar: {
                    sx: {
                      height: "50px",
                      width: "50px",
                      background: "whilte",
                      color: "#3dbf73ff   ",

                      border: "2px solid #3dbf73ff",
                      position: "absolute",
                      left: 140,
                      zIndex: 1,
                    },
                  },
                }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 6,
                }}
              >
                <Avatar
                  style={{
                    width: "70px",
                    height: "70px",
                    position: "absolute",
                    left: 0,
                  }}
                  alt="Travis Howard"
                  src={img1}
                />

                <Avatar
                  style={{
                    width: "50px",
                    height: "50px",
                    position: "absolute",
                    left: 50,
                  }}
                  alt="Travis Howard"
                  src={img1}
                />

                <Avatar
                  style={{
                    width: "50px",
                    height: "50px",
                    position: "absolute",
                    left: 75,
                  }}
                  alt="Agnes Walker"
                  src={img1}
                />

                <Avatar
                  style={{
                    width: "50px",
                    height: "50px",
                    position: "absolute",
                    left: 100,
                  }}
                  alt="Trevor Henderson"
                  src={img1}
                />
              </AvatarGroup>
            </Grid>
            {/* right  */}
            <Grid item md={9} xs={12}>
              <Grid container>
                {/* <Grid item md={6}>
                  <TextField
                    // label="Your Label"
                    // variant="outlined"
                    size='small'
                    outline='false'
                    placeholder='Search Influencers...'
                    // onChange={handleSearch}
                    fullWidth
                    sx={{
                      borderRadius: 8, // Adjust the value as needed for your desired border radius

                      '& .MuiOutlinedInput-root': {
                        borderRadius: 8, // Adjust the value as needed for your desired border radius
                        // paddingRight: 0.4,
                        '&:hover fieldset': {
                          borderColor: 'black', // Set the border color for hover state
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black', // Set the border color for focused state
                        },
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={6} sx={{ pl: 3 }}>
                  <Button sx={{ fontWeight: 'bold', color: '#3dbf73ff', borderRadius: 20, borderColor: '#3dbf73ff', '&:hover': { borderColor: '#3dbf73ff', borderWidth: '2px', }, borderWidth: '2px' }} variant='outlined' startIcon={<AddRounded />}>Add Influencers</Button>
                </Grid> */}
                <Grid item md={12} xs={12}>
                  <Grid container mt={3}>
                    <Grid
                      item
                      md={12}
                      sx={{ display: "flex", alignItems: "start" }}
                    >
                      <Box>
                        <Typography
                          sx={{ fontWeight: "bold", fontSize: "1.5rem" }}
                        >
                          {collection?.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "1.2rem",
                            color: "grey",
                          }}
                        >
                          {brand ? brand.name : "No brand found"}
                        </Typography>
                      </Box>
                      <IconButton size="small">
                        <ShareRounded sx={{ color: "rgb(61, 191, 115)" }} />
                      </IconButton>
                    </Grid>
                    <Grid item md={6}>
                      {/* <Button size='small' variant='contained' sx={{ bgcolor: '#c5edd6ff', color: '#000000', textTransform: 'none', boxShadow: 'none', borderRadius: 2, border: '2px solid #3dbf73ff', '&:hover': { bgcolor: '#c5edd6ff', color: '#000000', boxShadow: 'none', } }}>Food &  Drinks</Button> */}

                      {/* <Button size='small' variant='contained' sx={{ bgcolor: '#c5edd6ff', color: '#000000', textTransform: 'none', boxShadow: 'none', borderRadius: 2, border: '2px solid #3dbf73ff', margin: '0px 10px', '&:hover': { bgcolor: '#c5edd6ff', color: '#000000', boxShadow: 'none', } }}>Inforcement</Button> */}

                      {/* <IconButton size='small'>
                        <ShareRounded sx={{ color: 'rgb(61, 191, 115)' }} />
                      </IconButton> */}
                    </Grid>
                  </Grid>
                </Grid>

                <Dialog
                  open={createCollectionDialog}
                  onClose={handleClose}
                  // 👇 Props passed to Paper (modal content)
                  PaperProps={{
                    sx: { borderRadius: 5, border: "2px solid black" },
                  }}
                >
                  {isSucessful ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: 3,
                      }}
                    >
                      <AddTaskOutlined
                        sx={{ fontSize: "2.2rem", color: "#3dbe72ff" }}
                      />

                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "1.3rem",
                          color: "#3dbe72ff",
                          marginTop: 2,
                        }}
                      >
                        Collection Successfully Updated.
                      </Typography>

                      <Box
                        mt={4}
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          width: "100%",
                        }}
                      >
                        <Button
                          size="small"
                          variant="contained"
                          sx={{
                            bgcolor: "#3dbe72ff",
                            // color : '#3dbe72ff',
                            fontWeight: "bold",
                            // border: '1px solid black',
                            boxShadow: "none",
                            textTransform: "none",
                            border: "1px solid #3dbe72ff",
                            marginRight: 1,
                            "&:hover": {
                              bgcolor: "#3dbe72ff",
                              color: "white",
                              borderColor: "black",
                            },
                          }}
                          onClick={() => handleClose()}
                        >
                          Close
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <Grid container p={4}>
                      <Grid
                        item
                        md={11}
                        xs={11}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "1.2rem",
                            color: "#4caf50",
                          }}
                        >
                          Edit Collection
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={1}
                        xs={1}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                        }}
                      >
                        <Tooltip title="Close" arrow placement="left">
                          <IconButton
                            size="small"
                            onClick={() => {
                              setCreateCollectionDialog(false);
                              handleClose();
                            }}
                          >
                            <CancelOutlined />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <form>
                          <Box mt={2}>
                            <Mylabel htmlFor="collection">
                              Name of Collection
                            </Mylabel>
                            <Box>
                              <MyInput
                                type="text"
                                name="name"
                                id="collection"
                                value={collection?.name}
                                disabled={!isEditing}
                                placeholder="Enter the Name of collection"
                                onChange={(e) => handleCollectionChange(e)}
                                required
                                onFocus={() => setIsBrandFocus(false)}
                              />
                            </Box>
                          </Box>
                          <Box mt={2}>
                            <Mylabel htmlFor="brand">Brand Name</Mylabel>
                            <Box position={"relative"}>
                              <MyInput
                                type="text"
                                name="brand_name"
                                id="brand"
                                disabled={!isEditing}
                                value={
                                  getBrand(collection?.assigned_brand_id)[0]
                                    ?.name || ""
                                }
                                placeholder="search the brand "
                                required
                                onChange={(e) => handleBrandChange(e)}
                                onFocus={() => {
                                  setIsBrandFocus(true);
                                }}
                                // onBlur={() => setIsBrandFocus(false)}
                              />
                              <IconButton
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  right: "10px",
                                  transform: "translateY(-50%)",
                                }}
                                onClick={openAddBrandDialog}
                              >
                                <Tooltip title="Add a new Brand" arrow>
                                  <Add />
                                </Tooltip>
                              </IconButton>
                              {isBrandFocus && (
                                <Box
                                  sx={{
                                    padding: "5px 10px",
                                    position: "absolute",
                                    width: "100%",
                                    background: "white",
                                    marginTop: 1,
                                    borderRadius: 2,
                                    maxHeight: "140px",
                                    overflowY: "scroll",
                                    zIndex: 10,
                                  }}
                                >
                                  {filteredBrands
                                    ?.reverse()
                                    ?.map(({ name, id }) => (
                                      <ListItem
                                        key={id}
                                        component="div"
                                        sx={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleListItemClick(name, id)
                                        }
                                      >
                                        {name}
                                      </ListItem>
                                    ))}
                                </Box>
                              )}
                            </Box>
                          </Box>
                          <Box mt={2}>
                            <Mylabel htmlFor="description">Description</Mylabel>
                            <Box>
                              <MyTextArea
                                rows={3}
                                type="text"
                                name="description"
                                id="description"
                                disabled={!isEditing}
                                placeholder="Write short description"
                                onChange={(e) => handleCollectionChange(e)}
                                required
                                value={collection.description}
                              />
                            </Box>
                          </Box>
                          <Box mt={2}>
                            <Mylabel htmlFor="collection">Status</Mylabel>
                            <Box>
                              <MyInput
                                type="text"
                                name="status"
                                id="status"
                                value={collection.status}
                                disabled={!isEditing}
                                placeholder="Status"
                                onChange={(e) => handleCollectionChange(e)}
                                required
                                onFocus={() => setIsBrandFocus(false)}
                              />
                            </Box>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              mt: 2,
                            }}
                          >
                            <Button
                              variant="outlined"
                              size="small"
                              sx={{
                                textTransform: "none",
                                borderRadius: 10,
                                fontWeight: "bold",
                                marginRight: 1,
                                background: "#c5edd6ff",
                                borderWidth: "2px",
                                color: "black",
                                borderColor: "#3dbf73ff",
                                "&:hover": {
                                  borderWidth: "2px",
                                  borderColor: "#3dbf73ff",
                                },
                              }}
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                            <Button
                              size="small"
                              variant="contained"
                              sx={{
                                bgcolor: error.status
                                  ? "#e81e61ff"
                                  : "#3dbf73ff",
                                boxShadow: "none",
                                fontWeight: "bold",
                                borderRadius: 10,
                                // padding: '10px 30px',
                                "&:hover": {
                                  bgcolor: error.status
                                    ? "#e81e61ff"
                                    : "#4cb04fff",
                                },
                              }}
                              onClick={
                                isEditing
                                  ? handleSubmit
                                  : () => setIsEditing(true)
                              }
                              endIcon={
                                loading ? (
                                  <LoadingSpinner size={25} />
                                ) : isSucessful ? (
                                  <AddTaskOutlined />
                                ) : (
                                  error.status && <WarningAmberOutlined />
                                )
                              }
                            >
                              {isEditing ? "Save" : "Edit Collection"}
                            </Button>
                          </Box>
                        </form>
                      </Grid>
                    </Grid>
                  )}
                </Dialog>

                <Dialog open={addBrandDialog} onClose={closeAddBrandDialog}>
                  <AddNewBrand close={closeAddBrandDialog} />
                </Dialog>
                <Grid item md={12} xs={12} mt={2}>
                  <Grid container>
                    <Grid item md={6} xs={6}>
                      {/* <TextField
                            size='small'
                            outline='false'
                            placeholder='Analyze any influencer'
                            // onChange={handleSearch}
                            fullWidth
                            sx={{
                                // Adjust the value as needed for your desired border radius

                                '& .MuiOutlinedInput-root': {
                                    // Adjust the value as needed for your desired border radius
                                    borderRadius: 8,
                                    paddingRight: 0.4,
                                    '&:hover fieldset': {
                                        borderColor: '#3dbf73ff', // Set the border color for hover state
                                        boxShadow: '0 0 10px rgba(61, 191, 115, .5)'

                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#3dbf73ff', // Set the border color for hover state
                                        boxShadow: '0 0 10px rgba(61, 191, 115, .5)'
                                    },
                                },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search size="small" />
                                    </InputAdornment>
                                ),
                            }}
                        /> */}
                      <Box mt={1}>
                        <Button
                          variant="contained"
                          onClick={openCreateCollectionDialog}
                          // fullWidth
                          sx={{
                            background: "#1f2933ff",
                            fontWeight: "bold",
                            borderRadius: 8,
                            boxShadow: "none",
                            "&:hover": {
                              background: "#1f2933ff",
                            },
                          }}
                          startIcon={<Add />}
                        >
                          Edit Collection
                        </Button>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={6}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        size="small"
                        variant="text"
                        sx={{
                          fontWeight: "bold",
                          textTransform: "none",
                          color: "#000000",
                        }}
                        startIcon={
                          <FilterAltOutlined sx={{ color: "#3dbf73ff" }} />
                        }
                      >
                        Filter
                      </Button>

                      <Button
                        size="small"
                        variant="text"
                        sx={{
                          fontWeight: "bold",
                          textTransform: "none",
                          color: "#000000",
                        }}
                        startIcon={
                          <SwapVertOutlined sx={{ color: "#3dbf73ff" }} />
                        }
                      >
                        Sort
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12} xs={12} mt={2}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    // textColor="secondary"
                    // indicatorColor="secondary"
                    // aria-label="secondary tabs example"
                  >
                    <Tab
                      icon={<img src={InstaIcon} width={20} />}
                      value="one"
                      sx={{
                        textTransform: "none",
                        fontWeight: "bold",
                        color: "black",
                      }}
                      label="Instagram"
                      iconPosition="start"
                    />
                    <Tab
                      icon={<img src={YoutubeIcon} width={25} />}
                      sx={{
                        textTransform: "none",
                        fontWeight: "bold",
                        color: "black",
                      }}
                      value="two"
                      label="Youtube"
                      iconPosition="start"
                    />
                  </Tabs>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={12} xs={12}>
              <Tabs
                value={value2}
                onChange={handleChange2}
                // textColor="secondary"
                // indicatorColor="secondary"
                // aria-label="secondary tabs example"
              >
                <Tab
                  value="one"
                  sx={{
                    textTransform: "none",
                    fontWeight: "bold",
                  }}
                  label="Influencers"
                />

                <Tab
                  sx={{
                    textTransform: "none",
                    fontWeight: "bold",
                    color: "black",
                  }}
                  value="two"
                  label="Analytics"
                />
              </Tabs>

              {/*
              <nav >
                <ul type='none' style={{ display: 'flex' }}>
                  <li>
                    <NavLink to={'/dashboard/collection/profile/list'}


                    >Influencer</NavLink>
                  </li>
                  <li style={{ marginLeft: '1.5rem' }}>
                    <NavLink to={'/dashboard/collection/profile/analytics'}
                    >Analytics</NavLink>
                  </li>
                </ul>
              </nav> */}
            </Grid>
            <Grid item md={12} xs={12} mt={2}>
              {/* <Outlet /> */}
              {value2 === "one" ? (
                <InfluencerTable influencers={influencers} />
              ) : (
                <Analytics />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CollectionProfile;
