import React, { useState } from "react";
import {
  Grid,
  Typography,
  styled,
  Box,
  Button,
  IconButton,
  AvatarGroup,
  Avatar,
  Tabs,
  Tab,
  Tooltip,
  useMediaQuery,
  useScrollTrigger,
} from "@mui/material";
import { Link } from "react-router-dom";
import { ShareOutlined, CloseRounded } from "@mui/icons-material";

import directionRight from "../../images/directionRight.png";
import BasicTabs from "./BasicTabs";

const img1 =
  "https://img.freepik.com/free-photo/beautiful-woman-with-trendy-pink-sunglasses_273609-4532.jpg?size=626&ext=jpg&ga=GA1.1.1803636316.1701216000&semt=ais";

const LAvatar = styled(Avatar)(({ theme }) => ({
  width: "40px",
}));

const CollectionDrawer = ({ setOpen, sharePopUp, data }) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const { name, id } = data;

  // Encryption
  // Base64 encode function
  console.log("data ds", data);
  const encodeId = (id) => btoa(id);
  const encodedId = encodeId(id.toString());

  return (
    <>
      <Grid container p={3}>
        <Grid item md={11} xs={11}>
          <Typography
            sx={{
              color: "#3dbf73ff",
              fontSize: "1.8rem",
              fontWeight: "bold",
            }}
          >
            Collection
          </Typography>
        </Grid>
        <Grid item md={1} xs={1} align="end">
          <Tooltip title="Close" arrow placement="left">
            <IconButton onClick={() => setOpen(false)}>
              <CloseRounded sx={{ color: "red" }} />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item md={12} xs={12}>
          <Grid container mt={1}>
            <Grid
              item
              md={3}
              xs={4}
              align="start"
              sx={{ position: "relative" }}
            >
              <AvatarGroup
                total={24}
                componentsProps={{
                  additionalAvatar: {
                    sx: {
                      height: "50px",
                      width: "50px",
                      background: "whilte",
                      color: "#3dbf73ff   ",

                      border: "2px solid #3dbf73ff",
                      position: "absolute",
                      left: 140,
                      zIndex: 1,
                    },
                  },
                }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 6,
                }}
              >
                <Avatar
                  style={{
                    width: "70px",
                    height: "70px",
                    position: "absolute",
                    left: 0,
                  }}
                  alt="Travis Howard"
                  src={img1}
                />

                <Avatar
                  style={{
                    width: "50px",
                    height: "50px",
                    position: "absolute",
                    left: 50,
                  }}
                  alt="Travis Howard"
                  src={img1}
                />

                <Avatar
                  style={{
                    width: "50px",
                    height: "50px",
                    position: "absolute",
                    left: 75,
                  }}
                  alt="Agnes Walker"
                  src={img1}
                />

                <Avatar
                  style={{
                    width: "50px",
                    height: "50px",
                    position: "absolute",
                    left: 100,
                  }}
                  alt="Trevor Henderson"
                  src={img1}
                />
              </AvatarGroup>
            </Grid>

            <Grid item md={9} xs={12} mt={{ xs: 7, md: 0 }}>
              <Grid container>
                <Grid
                  item
                  md={12}
                  XS={12}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Box>
                    <Typography sx={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                      {" "}
                      <Link
                        to={`/dashboard/collection/${encodedId}`}
                        style={{ textDecoration: "none", color: "#000000" }}
                      >
                        {name}
                      </Link>{" "}
                    </Typography>
                    <Typography>117M Total Followrs</Typography>
                  </Box>
                  <IconButton
                    component={Link}
                    to={`/dashboard/collection/${encodedId}`}
                  >
                    <img src={directionRight} alt="" width={40} />
                  </IconButton>
                </Grid>
                {/* <Grid item md={3} XS={3}>
                                    
                                </Grid> */}
                <Grid item md={12} xs={12} mt={2}>
                  <Button
                    variant="contained"
                    // size='small'
                    sx={{
                      textTransform: "none",
                      borderRadius: 20,
                      bgcolor: "#c5ebd5ff",
                      color: " #000000",
                      fontWeight: "bold",
                      boxShadow: "none",
                      border: "2px solid #3dbf73ff",
                      padding: "3px 15px",
                      "&:hover": {
                        border: "2px solid #3dbf73ff",
                        boxShadow: "none",
                        bgcolor: "#c5ebd5ff",
                      },
                      marginRight: 1,
                    }}
                  >
                    Add to Campaign
                  </Button>
                  <IconButton
                    sx={{
                      bgcolor: "#3dbf73ff",
                      "&:hover": {
                        bgcolor: "#3dbf73ff",
                      },
                    }}
                    size="small"
                    onClick={() => sharePopUp(true)}
                  >
                    <ShareOutlined sx={{ color: "#FFFFFF" }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={12} xs={12} mt={1}>
              {/* <Button variant='contained'
                                size='small'
                                sx={{
                                    textTransform: 'none',
                                    borderRadius: 2,
                                    bgcolor: '#c5ebd5ff',
                                    color: " #000000",
                                    fontWeight: 'bold',
                                    boxShadow: "none",
                                    border: '1px solid #3dbf73ff',
                                    padding: '2px 8px',
                                    fontSize: '.6rem',
                                    '&:hover': {
                                        border: '1px solid #3dbf73ff',
                                        boxShadow: "none",
                                        bgcolor: '#c5ebd5ff',
                                    },
                                    marginRight: 1
                                }}

                            >
                                Food & Drinks
                            </Button>

                            <Button variant='contained'
                                size='small'
                                sx={{
                                    textTransform: 'none',
                                    borderRadius: 2,
                                    bgcolor: '#c5ebd5ff',
                                    color: " #000000",
                                    fontWeight: 'bold',
                                    boxShadow: "none",
                                    border: '1px solid #3dbf73ff',
                                    padding: '2px 8px',
                                    fontSize: '.6rem',
                                    '&:hover': {
                                        border: '1px solid #3dbf73ff',
                                        boxShadow: "none",
                                        bgcolor: '#c5ebd5ff',
                                    },
                                    marginRight: 1
                                }}

                            >
                                Infotainment
                            </Button> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} mt={3}>
          <BasicTabs data={data} />
        </Grid>
        <Grid item md={8} xs={8}></Grid>
      </Grid>
    </>
  );
};

export default CollectionDrawer;
