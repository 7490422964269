import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Box from "@mui/material/Box";
import Card3 from "./Card3";
import "./CustomCarousel1.module.css";

const CustomCarousel1 = () => {
  const sliderRef = React.useRef(null);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    initialSlide: 0,
    centerMode: true,
    centerPadding: "0px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  const cards = [
    { likes: 2.8, comments: 12.1, date: "19 Jun 24" },
    { likes: 5.6, comments: 11, date: "9 Jul 21" },
    { likes: 10.4, comments: 31, date: "4 Sept 22" },
    { likes: 7.2, comments: 21, date: "19 Feb 20" },
    { likes: 10.4, comments: 31, date: "4 Sept 22" },
    { likes: 7.2, comments: 21, date: "19 Feb 20" },
    { likes: 10.4, comments: 31, date: "4 Sept 22" },
    { likes: 5.6, comments: 11, date: "9 Jul 21" },
    { likes: 10.4, comments: 31, date: "4 Sept 22" },
  ];

  return (
    <Box
      sx={{
        width: { lg: "1080px", md: "900px", sm: "800px", xs: "500px" },
        textAlign: "center",
        display: "inline-block",
        margin: "0 auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        <ArrowBackIosIcon
          onClick={handlePrev}
          sx={{
            cursor: "pointer",
            position: "absolute",
            left: "0px",
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            flex: 1,
            overflow: "hidden",
            padding: "10px 30px",
            margin: 2,
          }}
        >
          <Slider ref={sliderRef} {...settings}>
            {cards.map((cardData, i) => (
              <Card3
                likes={cardData.likes}
                comments={cardData.comments}
                date={cardData.date}
              />
            ))}
          </Slider>
        </Box>
        <ArrowForwardIosIcon
          onClick={handleNext}
          sx={{
            cursor: "pointer",
            position: "absolute",
            right: "0px",
            zIndex: 1,
          }}
        />
      </Box>
    </Box>
  );
};

export default CustomCarousel1;
