import {
  Grid,
  Typography,
  Box,
  styled,
  TextField,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { UseSelector } from "react-redux";
// import Cards from './Cards'
// import SearchSection from './SearchSection'
// import Cards from './Cards'
// import SearchSection from './SearchSection'
import Cards from "./subcomponents/homePartial/Cards";
import SearchSection from "./subcomponents/homePartial/SearchSection";
import { useSelector } from "react-redux";

const Container = styled(Grid)(({ theme }) => ({
  width: "100%",
}));

const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
  },
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  color: " #A7A7A7",
  fontSize: "1.2rem",
  [theme.breakpoints.down("md")]: {
    fontSize: 15,
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "1.6rem",
  marginBottom: "2rem",
  color: "black",
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    fontSize: 20,
  },
}));

const StyledSpan = styled("span")`
  color: #3dbe72;
`;

const Home = () => {
  const { name } = useSelector((state) => state.user.user);

  return (
    <>
      <Container item md={10}>
        <Header mt={4}>
          <SubTitle>
            Welcome Back, <StyledSpan>{name}</StyledSpan>
          </SubTitle>
          <Title sx={{
            width: "55%",
          }}>
            Unlock the full Potential of
            <StyledSpan>Influencer Marketing</StyledSpan>
          </Title>
          {/* <SearchSection/> */}
        </Header>
        <Cards />
      </Container>
    </>
  );
};

export default Home;
