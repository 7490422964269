import axios from "axios"
const URL = process.env.REACT_APP_BASE_URL

export const getCollectionById = async (id) => {
    try {
        const response = await axios.post(`${URL}/getcollectionbyid`, id);


        console.log("Collection response from api :", response);


        // const { influncers_id } = response.data.collection[0];

        // console.log("Collection from api :", influncers_id);

        return response;

    }
    catch (error) {
        console.error('error occurs while fetch collection by ID : ' + error.message);

    }

}