import React, {useState} from 'react'

import { Dialog, Grid, Typography, styled, IconButton, TextField, Button, Box } from '@mui/material'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { FileUploader } from "react-drag-drop-files";
const DialogConti = styled(Dialog)(({ theme }) => ({

}))

const Title = styled(Typography)(({ theme }) => ({
    color: '#3DBE72',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    // padding : '10px'
}))

const GridContainer = styled(Grid)(({ theme }) => ({
    padding: '2rem'
}))

const InputField = styled(TextField)(({ theme }) => ({
    width: '100%'
}))

const fileTypes = ["JPG", "PNG", "GIF"];
const DialogBox = () => {

    const [file, setFile] = useState(null);
    const handleChange = (file) => {
      setFile(file);
    };

    return (
        <DialogConti open={true}>
            <GridContainer container spacing={2}>
                <Grid item sm={10} lg={10}>
                    <Title>Create new Campaign</Title>
                </Grid>
                <Grid item sm={2} lg={2} align='right'>
                    <IconButton>
                        <CancelOutlinedIcon />
                    </IconButton>
                </Grid>

                <Grid item sm={12} lg={12}>
                    <Typography style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>1.Campaign Details</Typography>
                </Grid>
                <Grid item sm={12} lg={12}>
                    <Typography style={{ color: 'grey', padding: '5px' }}>Campaign Name</Typography>
                    <InputField size="small" />
                </Grid>

                <Grid item sm={12} lg={12}>
                    <Typography style={{ color: 'grey', padding: '5px' }}>Brand Name</Typography>
                    <InputField size="small" />
                </Grid>


                <Grid item sm={12} lg={12}>
                    <Typography style={{ color: 'grey', padding: '5px' }}>Brand Logo</Typography>
                    <FileUploader  handleChange={handleChange} name="file" hoverTitle="Drop herer." types={fileTypes}label="Click to Browse file or Drag & Drop to upload" multiple={false} />
                </Grid>
               
                <Grid item sm={12} lg={12}>
                    <Typography style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>1.Campaign Details</Typography>
                </Grid>

                
                <Grid item align='right' lg={12}>
                    <Box sx={{width : '100%'}}>
                    <Button variant='contained' style={{background : '#3DBE724D', color :'black',fontWeight : 'bold',borderRadius : '20px' ,boxShadow :'none', textTransform : 'none', border : '2px solid #3DBE72', padding : '0px'}}>Cancle</Button>
                    <Button variant='contained' style={{background : '#3DBE72', color :'white', textTransform : 'none', boxShadow : 'none', borderRadius : '20px', padding : '1px 10px', marginLeft : 10}}>Create Campaign</Button>
                    </Box>
                </Grid>

            </GridContainer>
        </DialogConti>
    )
}

export default DialogBox