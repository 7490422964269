import axios from "axios";
const URL = process.env.REACT_APP_BASE_URL;

export const getAllCampaign = async () => {
  try {
    const response = await axios.post(`${URL}/campaign/getall`);
    const data = response.data.campaigns;
    // console.log("campaign", response);
    if (data) {
      // console.log("campaign data: ", data);
      return data;
    } else {
      console.error("Error: collections is empty");
      return false;
    }
  } catch (error) {
    console.error(
      "error occurs while create new collections : " + error.message
    );
  }
};

export const AssignCampaign = async (payload) => {
  try {
    const response = await axios.post(`${URL}/collection/assign/campagin`,payload);
    
    // console.log("campaign", response);
    if (response) {
      console.log("campaign data: ", response);
      return response;
    } else {
      console.error("Error: collections is empty");
      return false;
    }
  } catch (error) {
    console.error(
      "error occurs while create new collections : " + error.message
    );
  }
};
