import React, { useRef, useState } from 'react';
import { checkEmail } from '../../service/api';
//Material Ui Components----!
import { Box, Typography, TextField, Button, Divider, InputAdornment, Tooltip, Dialog, Grid, IconButton, CircularProgress } from "@mui/material"
import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { registerUser } from '../../service/api';
// import { SystemSecurityUpdateWarning } from '@mui/icons-material'
import OtpInput from 'react-otp-input';
import { CloseRounded, ReportProblem, RemoveRedEyeRounded, VisibilityOffRounded, SystemSecurityUpdateWarning } from '@mui/icons-material'

//Images-----!
import Logo from "../images/logo_CreatorHunt.png"
import Logo2 from "../images/logo_CreatorHunt-white.png"
import Prancheta from "../images/Prancheta.png"


//CSS using Styled component of Material UI-----!

const Container = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
        display: 'block'
    },
    [theme.breakpoints.down('md')]: {
        display: 'block'
    }
}))

const LeftBox = styled(Box)(({ theme }) => ({
    width: '50%',
    minHeight: '100vh',
    backgroundColor: '#1F2933',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',

    [theme.breakpoints.down('sm')]: {
        display: 'none'
    },
    [theme.breakpoints.down('md')]: {
        display: 'none',
        width: '100%',
    }
}))

const Header = styled(Typography)(({ theme }) => ({
    fontSize: '1.6rem',
    fontWeight: 700
}))

const Title = styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    fontSize: '12px'
}))

const MyLogo = styled('img')({
    width: '120px',
    height: 'auto',
    marginTop: '20px'
});

const Contant = styled(Box)`
color: #FFF;
width: 85%;
`
const ImgBox = styled(Box)`
    max-width:60%;
    display: flex;  
    justify-content: center;
`

//RIGHT BOX
const RightBox = styled(Box)(({ theme }) => ({
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
        width: '100%',

    },

    [theme.breakpoints.down('md')]: {
        width: '100%',
    }
}))


const RightChild = styled(Box)(({ theme }) => ({
    width: '60%',

    [theme.breakpoints.down('sm')]: {
        width: '90%',

    },

    [theme.breakpoints.down('md')]: {
        width: '90%',

    }
}))


const InputBox = styled(Box)`
    margin : 20px 0px;
`

const Label = styled('label')({
    fontSize: '10px',
    fontWeight: 400,
})

const InputField = styled(TextField)(({ theme }) => ({
    width: '100%',
    paddingTop: '5px',
    borderRadius: '5px',
    fontSize: 10,
    '& .MuiOutlinedInput-root': {
        '&:not(.Mui-focused)': {
            borderColor: 'initial', // Reset default border color
        },
        '&.Mui-focused': {
            borderColor: 'green', // Red border color when focused
            borderWidth: '5px'
        },
    },
}))


const RightHeader = styled(Box)`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
margin : 30px;
font-weight : 400;
`

const RightSubtitle = styled('p')`
font-size : 16px;
`

const LoginButton = styled(Button)(({ theme }) => ({
    width: "100%",
    background: "#3DBE72",
    padding: 10,
    '&:hover': {
        background: "#3DBE72",
    }
}))

const ToSignupBtn = styled(Link)(({ theme }) => ({
    textTransform: "lowercase",
    color: "#3DBE72",
    cursor: 'pointer',
    textDecoration: "none"
}))
const Line = styled(Divider)`
    margin : 30px 0px 10px 0px;
`

const Footer = styled(Box)`
    display : flex;
    align-items: center;
    justify-content: space-between;
`

const FooterContent = styled(Typography)`
    font-size : 15px;
`

const VerifyBtn = styled(Button)(({ theme }) => ({
    background: '#3DBE72',
    boxShadow: 'none',
    borderRadius: 20,
    textTransform: 'capitalize',
    '&:hover': {
        background: '#3DBE72',
        boxShadow: 'none',
    }
}))

const LoadingSpinner = styled(CircularProgress)(({ theme }) => ({
    color: 'white',
    marginRight: '8px', // Some spacing between spinner and text
}));



/**
* Functional component representing the Signup page.
* Manage user registration and form validation.
*/
const Signup = () => {

    const [errors, setErrors] = useState({
        name: "",
        email: "",
        countryCode: "",
        phone: "",
        password: "",
        repassword: ""
    });

    // State variables 
    const [response, setResponse] = useState(null);
    const [eye, setEye] = useState(false);
    const [show, setShow] = useState(false);
    const [disable, setDisable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState('');
    const [otpPhoneDialog, setPhoneOtpDialog] = useState(false);
    const [otpEmailDialog, setEmailOtpDialog] = useState(false);
    const [emailOTPIcon, setEmailOTPIcon] = useState(false);
    const [checkEmaliLoading, setCheckEmailLoading] = useState(false);

    //Router navigation hook
    const navigate = useNavigate();

    // Refs for form Input fields 
    const name = useRef(null);
    const email = useRef(null);
    const countryCode = useRef(null);
    const phone = useRef(null);
    const password = useRef(null);
    const repassword = useRef(null);



    // Function to check if all input fields have values
    const areAllFieldsFilled = () => {

        // const noErrors = Object.values(errors).every((error) => error === "");

        if (

            name.current?.value.trim() !== "" &&
            (email.current?.value.trim() !== "" && errors.email === "") &&
            (countryCode.current?.value.trim() !== "") &&
            (phone.current?.value.trim() !== "") &&
            (password.current?.value !== "") &&
            (repassword.current?.value !== "")
        ) {
            setDisable(true);
        }
        else {
            setDisable(false);
        }
    };

    const handleHideAndShowPassword = () => {
        setShow((x) => x == true ? false : true);
    }
    /**
     * Handles email validity check based on a regular expression.
     * Updates the emailValid state accordingly.
   
    */

    // const isEmailExists = async() => {
    //     try{

    //     }catch(e){

    //     }
    // }

    const handleInputChange = async (event) => {
        const { name, value } = event.target;

        // Validate input and update error message accordingly
        let errorMessage = "";

        if (name === 'email') {

            const isValidEmail = (email) => {
                // Regular expression for basic email validation
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                return emailRegex.test(email);
            };


            if (value !== "") {
                if (isValidEmail(value)) {

                    setEmailOTPIcon(true);
                    // const res = await checkEmail(value);

                    // console.log(res);

                    // if (res.data.status === 201) {
                    //     errorMessage = (
                    //         <div>{res.data.message}</div>

                    //     ),
                    //         setEmailOTPIcon(false);
                    // }
                    // else {
                    //     errorMessage = "";
                    //     setEmailOTPIcon(true);
                    // }

                } else {
                    errorMessage = (
                        <div>Invalid email address.</div>

                    ),
                        setEmailOTPIcon(false);
                }
            }

        }


        if (name === 'phone') {
            // Example: Check if the phone value contains non-numeric characters
            if (value == "") {
                errorMessage = "";
            }
            else if (!/^\d+$/.test(value)) {
                errorMessage = (
                    <div>Please enter a valid phone number.</div>
                )
            }
            else if (value.length < 10 || value.length > 10) {
                errorMessage = (
                    <div>Phone number must be 10 digits.</div>
                )
            }

        }


        if (name === 'password') {
            const minLength = 8;
            const hasUppercase = /[A-Z]/.test(value);
            const hasLowercase = /[a-z]/.test(value);
            const hasNumber = /\d/.test(value);
            const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);

            if (value.length === 0) {
                errorMessage = ""
                setEye(false);
            }

            else if (!(value.length >= minLength &&
                hasUppercase &&
                hasLowercase &&
                hasNumber &&
                hasSpecialChar)) {
                errorMessage = (<div>
                    Weak password. Use at least 8 characters,
                    including uppercase, lowercase, numbers, and special characters.
                </div>);
                setEye(true);

            }
        }


        if (name === 'repassword') {
            if (value.length === 0) {
                errorMessage = ""
            }

            else if (!(value === password.current.value)) {
                errorMessage = (<div>
                    Passwords do not match. Please make sure the passwords match.
                </div>);
            }

        }
        // Add more validation logic for other fields if needed

        // Update the errors state
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: errorMessage
        }));

        areAllFieldsFilled();
    };



    /**
    * Submits the signup form and registers the user.
    * Navigates to the login page upon successful registration.
    * @param {Event} e - The form submission event.
    */
    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);

        const user = {
            "name": name.current.value.trim(),
            "email": email.current.value.trim().toLowerCase(),
            "password": password.current.value,
            "mobile": `${countryCode.current.value} ${phone.current.value}`,
            "role": "",
            "google_id": "",
            "instagram_id": ""
        }

        try {
            const response = await registerUser(user);
            if (response && response.data && response.status === 200) {
                console.log("user register successfully  : " + response)
                navigate('/login')
            }
        }
        catch (error) {
            console.error("Error registering user : ", error);
        }
        finally {
            setLoading(false);
        }



    }



    //... (rest of the component code below.)
    //... (JSX code.)
    return (

        <Container >

            <LeftBox>
                <Contant >
                    <MyLogo src={Logo} alt="" />

                    <Header variant="h3" >Develop a strategy for executing influencer marketing initiatives.</Header>
                    <br />
                    <Title >Elevate your brand&rsquo;s digital presence through our dynamic influencer marketing strategies. Connect with influential personalities, amplify your reach, and forge authentic connections. Maximize impact and visibility with our tailored solutions.</Title>
                </Contant>
                <ImgBox >
                    <img src={Prancheta} alt="" width="100%" />
                </ImgBox>
            </LeftBox>


            {/* Right Side's Container  */}
            <RightBox>

                {/* getting pin for email address  */}
                {/* <Dialog open={otpEmailDialog} maxWidth={'sm'}

                    PaperProps={{
                        style: {
                            borderRadius: 20,
                        },
                    }}
                >
                    <Grid Container p={4}>
                        <Grid item md={12} sm={12} align={'right'}>
                            <IconButton onClick={() => { setEmailOtpDialog(false); setEmailOTPIcon(true) }}>
                                <CloseRounded />
                            </IconButton>
                        </Grid>
                        <Grid item md={12} align={'center'}>
                            <Typography sx={{ fontWeight: 'bold', fontSize: 25 }}>Enter OTP</Typography>
                        </Grid>
                        <Grid item md={12} align={'center'}>
                            <Typography>
                                We have sent a verification code to your Email address
                            </Typography>
                        </Grid>
                        <Grid item md={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} mt={3}>
                            <OtpInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={4}
                                renderSeparator={<span>&nbsp;</span>}
                                renderInput={(props) =>
                                    <input {...props}

                                        style={{
                                            // Your custom styles for each input
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            padding: '8px',
                                            marginRight: '8px',
                                            width: '40px',
                                            textAlign: 'center',
                                            background: 'rgba(0, 255, 105, 0.2)',
                                        }}
                                    />
                                }
                            />
                        </Grid>

                        <Grid item md={12} align={'center'} mt={3}>
                            <VerifyBtn variant='contained' fullWidth>
                                Verify
                            </VerifyBtn>
                        </Grid>

                        <Grid item md={12} align={'center'} mt={3}>
                            <Typography>Didn't receive the code?</Typography>
                        </Grid>

                        <Grid item md={12} align={'center'}>
                            <Typography sx={{ color: '#3DBE72' }}>Resend Code</Typography>
                        </Grid>
                    </Grid>
                </Dialog> */}

                {/* getting pin for phone number  */}
                {/* <Dialog open={otpPhoneDialog} maxWidth={'sm'}
                    PaperProps={{
                        style: {
                            borderRadius: 20,
                        },
                    }}
                >

                    <Grid Container p={4}>
                        <Grid item md={12} sm={12} align={'right'}>
                            <IconButton onClick={() => setPhoneOtpDialog(false)}>
                                <CloseRounded />
                            </IconButton>
                        </Grid>

                        <Grid item md={12} align={'center'}>
                            <Typography sx={{ fontWeight: 'bold', fontSize: 25 }}>Enter OTP</Typography>
                        </Grid>

                        <Grid item md={12} align={'center'}>
                            <Typography>
                                We have sent a verification code to your  mobile number
                            </Typography>
                        </Grid>

                        <Grid item md={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} mt={3}>
                            <OtpInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={4}
                                renderSeparator={<span>&nbsp;</span>}
                                renderInput={(props) =>
                                    <input {...props}
                                        style={{
                                            // Your custom styles for each input
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            padding: '8px',
                                            marginRight: '8px',
                                            width: '40px',
                                            textAlign: 'center',
                                            background: 'rgba(0, 255, 105, 0.2)',
                                        }}
                                    />
                                }
                            />
                        </Grid>

                        <Grid item md={12} align={'center'} mt={3}>
                            <VerifyBtn variant='contained' fullWidth>
                                Verify
                            </VerifyBtn>
                        </Grid>

                        <Grid item md={12} align={'center'} mt={3}>
                            <Typography>Didn't receive the code?</Typography>
                        </Grid>

                        <Grid item md={12} align={'center'}>
                            <Typography sx={{ color: '#3DBE72' }}>Resend Code</Typography>
                        </Grid>

                    </Grid>
                </Dialog> */}


                <RightChild>

                    <RightHeader>
                        <img src={Logo2} alt="" width="140px" />
                        <RightSubtitle>Create Your Account</RightSubtitle>
                        <span>{response}</span>
                    </RightHeader>

                    <form action="" onSubmit={handleSubmit}>
                        <InputBox>
                            <Label htmlFor="name" style={{ fontSize: 12 }}>Name</Label>
                            <InputField
                                id="name"
                                type="text"
                                size="small"
                                placeholder='Enter Your Name'
                                inputRef={name}
                                required
                                sx={{
                                    // Adjust the value as needed for your desired border radius
                                    '& .MuiOutlinedInput-root': {
                                        // Adjust the value as needed for your desired border radius
                                        paddingRight: 0.4,
                                        '&:hover fieldset': {
                                            borderColor: '#3dbf73ff', // Set the border color for hover state
                                            boxShadow: '0 0 10px rgba(61, 191, 115, .5)'
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#3dbf73ff', // Set the border color for focused state
                                            boxShadow: '0 0 10px rgba(61, 191, 115, .5)'
                                        },
                                    },
                                }}
                            />
                        </InputBox>

                        <InputBox>
                            <Label htmlFor="email" style={{ fontSize: 12 }}> Enter Your Bussiness E-Mail</Label>
                            <InputField
                                id="email"
                                type="email"
                                name="email"
                                size="small"
                                placeholder="Abc@CompanyName.com"
                                inputRef={email}
                                onChange={handleInputChange}

                                sx={{
                                    // Adjust the value as needed for your desired border radius

                                    '& .MuiOutlinedInput-root': {
                                        // Adjust the value as needed for your desired border radius
                                        paddingRight: 0.4,
                                        '&:hover fieldset': {
                                            borderColor: errors.email ? '#e91e63' : '#3dbf73ff', // Set the border color for hover state
                                            boxShadow: errors.email ? '0 0 10px rgba(233, 30, 99, 0.7)' : '0 0 10px rgba(61, 191, 115, .5)'

                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: errors.email ? '#e91e63' : '#3dbf73ff', // Set the border color for hover state
                                            boxShadow: errors.email ? '0 0 10px rgba(233, 30, 99, 0.7)' : '0 0 10px rgba(61, 191, 115, .5)'
                                        },
                                    },
                                }}

                                // InputProps={emailOTPIcon && {
                                //     endAdornment: (
                                //         <InputAdornment position="end">
                                //             <Tooltip title="verify your email!" arrow placement='left' open={emailOTPIcon}>
                                //                 <IconButton size="small" onClick={() => { setEmailOtpDialog(true); setEmailOTPIcon(false) }}>
                                //                     {

                                //                         <SystemSecurityUpdateWarning

                                //                             style={{
                                //                                 color: '#ff5722'
                                //                             }}

                                //                         />

                                //                     }

                                //                 </IconButton>
                                //             </Tooltip>
                                //         </InputAdornment>
                                //     ),
                                // }}
                            />
                            {
                                errors.email &&
                                <Box>

                                    <Typography sx={{ display: 'flex', alignItem: 'center', fontSize: '.7rem', color: '#e91e63', marginTop: 1 }}>  <ReportProblem sx={{ fontSize: 15, color: '#e91e63', marginRight: 1 }} />  {errors.email}</Typography>
                                </Box>
                            }
                        </InputBox>

                        <InputBox>
                            <Label htmlFor="phone" style={{ fontSize: 12 }}>Mobile No.</Label>
                            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <InputField
                                    // id="phone"
                                    type="phone"
                                    size="small"
                                    name="countryCode"
                                    defaultValue={'+91'}
                                    placeholder="+91"
                                    color="warning"
                                    inputRef={countryCode}
                                    onChange={handleInputChange}
                                    // inputProps={{pattern : "[0-9]{3}-[0-9]{3}-[0-9]{4}"}}
                                    required
                                    style={{ width: '15%' }}
                                    // autoFocus={mobileValid}
                                    sx={{
                                        // Adjust the value as needed for your desired border radius

                                        '& .MuiOutlinedInput-root': {
                                            // Adjust the value as needed for your desired border radius
                                            paddingRight: 0.4,
                                            '&:hover fieldset': {
                                                borderColor: errors.phone ? '#e91e63' : '#3dbf73ff', // Set the border color for hover state
                                                boxShadow: errors.phone ? '0 0 10px rgba(233, 30, 99, 0.7)' : '0 0 10px rgba(61, 191, 115, .5)'

                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: errors.phone ? '#e91e63' : '#3dbf73ff', // Set the border color for hover state
                                                boxShadow: errors.phone ? '0 0 10px rgba(233, 30, 99, 0.7)' : '0 0 10px rgba(61, 191, 115, .5)'
                                            },
                                        },
                                    }}
                                />

                                <InputField
                                    id="phone"
                                    type="phone"
                                    size="small"
                                    name="phone"
                                    placeholder="000-000-0000"
                                    inputRef={phone}
                                    onChange={handleInputChange}
                                    style={{ width: '83%' }}

                                    // autoFocus={mobileValid}
                                    sx={{
                                        // Adjust the value as needed for your desired border radius

                                        '& .MuiOutlinedInput-root': {
                                            // Adjust the value as needed for your desired border radius
                                            paddingRight: 0.4,
                                            '&:hover fieldset': {
                                                borderColor: errors.phone ? '#e91e63' : '#3dbf73ff', // Set the border color for hover state
                                                boxShadow: errors.phone ? '0 0 10px rgba(233, 30, 99, 0.7)' : '0 0 10px rgba(61, 191, 115, .5)'

                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: errors.phone ? '#e91e63' : '#3dbf73ff', // Set the border color for hover state
                                                boxShadow: errors.phone ? '0 0 10px rgba(233, 30, 99, 0.7)' : '0 0 10px rgba(61, 191, 115, .5)'
                                            },
                                        },
                                    }}
                                />

                            </Box>
                            {
                                errors.phone &&
                                <Box>

                                    <Typography sx={{ display: 'flex', alignItem: 'center', fontSize: '.7rem', color: '#e91e63', marginTop: 1 }}>  <ReportProblem sx={{ fontSize: 15, color: '#e91e63', marginRight: 1 }} />  {errors.phone}</Typography>
                                </Box>
                            }
                        </InputBox>

                        <InputBox>
                            <Label htmlFor="password" style={{ fontSize: 12 }}>Create A Strong Password</Label>
                            <InputField
                                id="password"
                                type={show ? "text" : 'password'}
                                name="password"
                                size="small"
                                placeholder="Abc@123"
                                inputRef={password}
                                onChange={handleInputChange}
                                sx={{
                                    // Adjust the value as needed for your desired border radius

                                    '& .MuiOutlinedInput-root': {
                                        // Adjust the value as needed for your desired border radius
                                        paddingRight: 0.4,
                                        '&:hover fieldset': {
                                            borderColor: errors.password ? '#e91e63' : '#3dbf73ff', // Set the border color for hover state
                                            boxShadow: errors.password ? '0 0 10px rgba(233, 30, 99, 0.7)' : '0 0 10px rgba(61, 191, 115, .5)'

                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: errors.password ? '#e91e63' : '#3dbf73ff', // Set the border color for hover state
                                            boxShadow: errors.password ? '0 0 10px rgba(233, 30, 99, 0.7)' : '0 0 10px rgba(61, 191, 115, .5)'
                                        },
                                    },
                                }}

                                InputProps={eye && {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton size="small" onClick={handleHideAndShowPassword}>
                                                {
                                                    show
                                                        ?
                                                        <VisibilityOffRounded

                                                            style={{
                                                                color: errors.password ? '#e91e63' : '#3dbf73ff'
                                                            }}

                                                        />
                                                        :
                                                        <RemoveRedEyeRounded

                                                            style={{
                                                                color: errors.password ? '#e91e63' : '#3dbf73ff'
                                                            }}

                                                        />
                                                }

                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {
                                errors.password &&
                                <Box>

                                    <Typography sx={{ display: 'flex', alignItem: 'center', fontSize: '.7rem', color: '#e91e63', marginTop: 1 }}>  <ReportProblem sx={{ fontSize: 15, color: '#e91e63', marginRight: 1 }} />  {errors.password}</Typography>
                                </Box>
                            }

                        </InputBox>
                        <InputBox>
                            <Label htmlFor="repassword" style={{ fontSize: 12 }}>Re-Enter Your Password</Label>
                            <InputField
                                id="repassword"
                                name="repassword"
                                type="password"
                                size="small"
                                placeholder="Abc@123"
                                inputRef={repassword}
                                onChange={handleInputChange}
                                sx={{
                                    // Adjust the value as needed for your desired border radius

                                    '& .MuiOutlinedInput-root': {
                                        // Adjust the value as needed for your desired border radius
                                        paddingRight: 0.4,
                                        '&:hover fieldset': {
                                            borderColor: errors.repassword ? '#e91e63' : '#3dbf73ff', // Set the border color for hover state
                                            boxShadow: errors.repassword ? '0 0 10px rgba(233, 30, 99, 0.7)' : '0 0 10px rgba(61, 191, 115, .5)'

                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: errors.repassword ? '#e91e63' : '#3dbf73ff', // Set the border color for hover state
                                            boxShadow: errors.repassword ? '0 0 10px rgba(233, 30, 99, 0.7)' : '0 0 10px rgba(61, 191, 115, .5)'
                                        },
                                    },
                                }}
                            />
                            {
                                errors.repassword &&
                                <Box>

                                    <Typography sx={{ display: 'flex', alignItem: 'center', fontSize: '.7rem', color: '#e91e63', marginTop: 1 }}>  <ReportProblem sx={{ fontSize: 15, color: '#e91e63', marginRight: 1 }} />  {errors.repassword}</Typography>
                                </Box>
                            }
                        </InputBox>

                        <LoginButton variant="contained" type='submit' disabled={!disable || loading} startIcon={loading && <LoadingSpinner size={25} />}>
                            {
                                loading ? 'Signing Up...' : 'Signup'
                            }


                        </LoginButton>
                    </form>

                    <Line />

                    <Footer mb={3}>
                        <FooterContent>already have an account ?</FooterContent>
                        <ToSignupBtn to="/login" variant="text">log in</ToSignupBtn>
                    </Footer>
                </RightChild>

            </RightBox>

        </Container >
    )
}

export default Signup