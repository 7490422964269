import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Box,
  Icon,
  IconButton,
  Button,
  styled,
  TextField,
  InputAdornment,
  Dialog,
} from "@mui/material";
import { useParams } from "react-router-dom";
// import { Box } from '@mui/system'
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AccountAnalytics from "./AccountAnalytics";
import DP from "../../../images/dp-lg.png";
import dp from "../../../images/dp.png";
import ShareIcon from "../../../images/directionRight.png";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TurnRightRoundedIcon from "@mui/icons-material/TurnRightRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Bio from "./Bio";
import BasicTabs from "./BasicTabs";
import { getUserByID } from "../../../../service/user/getUserByID";
import { AddRounded, Search, ShareRounded } from "@mui/icons-material";
import Card1 from "./Card1";
import Card2 from "./Card2";
import Card3 from "./Card3";
import Card4 from "./Card4";
import CustomCarousel from "./CustomCarousel";
import CustomCarousel1 from "./CustomCarousel1";
import ShareCollection from "../../collection/ShareCollection";
import ShareInfluencers from "./ShareInfluencers";

const EditButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  color: "white",
  background: "black",
  fontSize: ".8rem",
  padding: "2px 5px",
  "&:hover": {
    color: "white",
    background: "black",
  },
}));

const Profile = (props) => {
  const [user, setUser] = useState({});

  const { id } = useParams();

  // Base64 decode function
  const decodeId = (encodedId) => atob(encodedId);
  const decodedId = decodeId(id);
  console.log("decodedId : ", decodedId);

  // const rowData = props.location?.state?.rowData || {};
  // console.log("profile ", rowData);

  const getUser = async () => {
    const user = await getUserByID({ id: decodedId });
    console.log("user from profile page : ", user);
    setUser(user);
    console.log("User state : ", user);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getUser();
  }, []);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    console.log("Search Term Updated:", event.target.value);
  };

  const [open, setOpen] = React.useState(false);

  // const [shareCollectionDrawer, setShareCollectionDrawer] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <Grid container>
      <Grid item md={12}>
        <Grid container sx={{ marginTop: { md: 2, xs: 1 } }}>
          <Grid item md={12} p={3}>
            {/* <Typography
              style={{
                color: "#3DBE72",
                fontWeight: "bold",
                fontSize: "1.4rem",
              }}
            >
              Find Influencer
            </Typography> */}
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  color: "#3dbf73ff",
                  fontWeight: "bold",
                  width: "25%",
                }}
              >
                Find Influencers
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  gap: "2rem",
                  width: "100%",
                }}
              >
                <TextField
                  size="small"
                  outline="false"
                  placeholder="Search Influencers..."
                  value={searchTerm} // Bind the searchTerm state to the TextField
                  onChange={handleSearchChange} // Handle changes in the TextField
                  sx={{
                    width: "50%",
                    borderRadius: 8,
                    borderColor: "black",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 8,
                      "&:hover fieldset": {
                        borderColor: "black",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black",
                      },
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: "1rem",
                    alignItems: "center",
                  }}
                >
                  <Button
                    sx={{
                      fontWeight: "bold",
                      color: "#3dbf73ff",
                      borderRadius: 20,
                      borderColor: "#3dbf73ff",
                      textTransform: "none",
                      "&:hover": {
                        borderColor: "#3dbf73ff",
                        borderWidth: "2px",
                      },
                      borderWidth: "2px",
                      width: "40%",
                    }}
                    variant="outlined"
                    startIcon={<AddRounded />}
                    // onClick={handleOpen}
                  >
                    Add to campaign
                  </Button>
                  <Button
                    sx={{
                      fontWeight: "bold",
                      color: "#3dbf73ff",
                      borderRadius: 20,
                      borderColor: "#3dbf73ff",
                      textTransform: "none",
                      "&:hover": {
                        borderColor: "#3dbf73ff",
                        borderWidth: "2px",
                      },
                      borderWidth: "2px",
                      width: "40%",
                    }}
                    variant="outlined"
                    startIcon={<AddRounded />}
                    // onClick={handleOpen}
                  >
                    Add to collection
                  </Button>
                  <Button
                    variant="contained"
                    // onClick={() => {
                    //   openCreateCollectionDialog();
                    //   setCopy(false);
                    // }}
                    // fullWidth
                    sx={{
                      background: "#1f2933ff",
                      fontWeight: "bold",
                      borderRadius: 8,
                      boxShadow: "none",
                      width: "20%",
                      "&:hover": {
                        background: "#1f2933ff",
                      },
                    }}
                  >
                    Edit
                  </Button>
                  <ShareRounded
                    onClick={handleClickOpen}
                    sx={{
                      color: "rgb(61, 191, 115)",
                      ":hover": {
                        cursor: "pointer",
                      },
                    }}
                  />
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      sx: { borderRadius: 5, border: "2px solid black" },
                    }}
                  >
                    <ShareInfluencers sharePopUp={setOpen} />
                  </Dialog>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            md={12}
            sx={{ padding: { md: 3, xs: 2 } }}
            justify="center"
          >
            <Grid container spacing={2}>
              <Grid
                item
                md={1.5}
                sm={2}
                xs={3.5}
                sx={{ marginRight: "20px", padding: 0 }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 120,
                    height: 120,
                    overflow: "hidden",
                    borderRadius: "50%",
                  }}
                >
                  {user.photo ? (
                    <img
                      src={user.photo}
                      alt=""
                      width="100%"
                      height="100%"
                      style={{ objectFit: "cover", backgroundSize: "cover" }}
                    />
                  ) : (
                    <img
                      src={
                        "https://i.pinimg.com/736x/a2/11/7e/a2117e75dc55c149c2c68cbadee1f16e.jpg"
                      }
                      alt=""
                      width="100%"
                      height="100%"
                      style={{ objectFit: "cover", backgroundSize: "cover" }}
                    />
                  )}
                </Box>
              </Grid>

              <Box sx={{ padding: 1, marginTop: 1 }}>
                <Typography
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                  }}
                >
                  {user.name}
                </Typography>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr",
                    marginTop: 1,
                    gap: 3,
                    columnGap: 10,
                  }}
                >
                  <Typography
                    style={{
                      color: "black",
                      marginRight: "10px",
                    }}
                  >
                    Followers: {user.Followers ? user.Followers : 0}
                    {/* Followers
                  Location
                  Account type of the profile, such as Personal, Creator or Business
                  Gender of the profile
                  Bio
                  Content Categories
                  Hashtags */}
                  </Typography>
                  <Typography
                    style={{
                      color: "black",
                      marginRight: "10px",
                    }}
                  >
                    Location: {user.Location ? user.Location : "Location"}
                  </Typography>
                  <Typography
                    style={{
                      color: "black",
                      marginRight: "10px",
                    }}
                  >
                    Account: {user.AccountType ? user.AccountType : "Account"}
                  </Typography>
                  <Typography
                    style={{
                      color: "black",
                      marginRight: "10px",
                    }}
                  >
                    Gender: {user.Gender ? user.Gender : "Male"}
                  </Typography>
                  {/* <Typography
                    style={{
                      color: "black",
                      marginRight: "10px",
                    }}
                  >
                    {user.Bio ? user.Bio : "Bio"}
                  </Typography> */}
                  <Typography
                    style={{
                      color: "black",
                      marginRight: "10px",
                    }}
                  >
                    Category: {user.Category ? user.Category : "Category"}
                  </Typography>
                  <Typography
                    style={{
                      color: "black",
                      marginRight: "10px",
                    }}
                  >
                    Tags:{" "}
                    <Box sx={{ display: "inline-flex", flexWrap: "wrap" }}>
                      {user.Tags ? user.Tags : "Tags"}
                    </Box>
                  </Typography>
                </Box>
              </Box>
              <Box
                style={{
                  background: "#F2F7FF",
                  borderRadius: 20,
                  padding: 30,
                  marginTop: 30,
                  marginRight: 15,
                  marginLeft: 15,
                  width: "100%",
                }}
              >
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={user.photo}
                    alt=""
                    width="60px"
                    height="60px"
                    style={{
                      borderRadius: "50%",
                      objectFit: "cover",
                      backgroundSize: "cover",
                    }}
                  />
                  <Box style={{ marginLeft: 15 }}>
                    <Typography
                      style={{ fontWeight: "bold", fontSize: "12px" }}
                    >
                      @ {user.name}
                    </Typography>
                    <Typography style={{ fontSize: "10px" }}>
                      {user.Followers ? user.Followers : 0} Followers
                    </Typography>
                  </Box>
                </Box>
                <Typography style={{ margin: "10px 0" }}>
                  {/* Live life on your own terms #jointhemvmt Watches, Eyewear &
                  Premium Accessories For her @mvmtforher Shop our Site */}
                  {user.Bio ? user.Bio : "Bio"}
                </Typography>

                <Typography style={{ fontWeight: "bold" }}>
                  {" "}
                  #Live #life #jointhemvmt #Watches #Eyewear #Premium
                  #Accessories{" "}
                </Typography>
              </Box>
              {/* <Grid item md={6} xs={12}>
                <EditButton
                  sx={{ width: { xs: "100%", md: "40%", lg: "20%" } }}
                >
                  Edit Profile
                </EditButton>
                <Grid item mt={1} md={6} xs={12}>
                  <Button
                    variant="outlined"
                    sx={{
                      border: "3px solid #3DBE72",
                      textTransform: "none",
                      padding: "2px 20px",
                      mb: 1,
                      borderRadius: 20,
                      fontSize: ".7rem",
                      color: "black",
                      fontWeight: "bold",
                      marginRight: "10px",
                      background: "rgba(61, 190, 114, 0.3)",
                      "&:hover": { border: "3px solid #3DBE72" },
                    }}
                  >
                    Add to Campaign
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      border: "3px solid #3DBE72",
                      textTransform: "none",
                      mb: 1,
                      padding: "2px 20px",
                      borderRadius: 20,
                      fontSize: ".7rem",
                      color: "black",
                      fontWeight: "bold",
                      background: "rgba(61, 190, 114, 0.3)",
                      "&:hover": { border: "3px solid #3DBE72" },
                    }}
                  >
                    Add to Collection
                  </Button>
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12} sx={{ padding: 0 }}>
        {/* <Card1 />
        <Card2 />
        <Card3 />
        <Card4 /> */}
        {/* <CustomCarousel /> */}
        {/* <CustomCarousel1 /> */}
      </Grid>
      <Grid item md={12}>
        <BasicTabs />
      </Grid>
    </Grid>
  );
};

export default Profile;
