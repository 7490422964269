const collection = [
    {
        name: "Food Influencers",
        followers: '259K',
        engagement: '82%',
        success: '92.5%'
    },
    {
        name: "TastyBites",
        followers: '150K',
        engagement: '75%',
        success: '89%'
    },

    {
        name: "FlavorFiesta",
        followers: '310K',
        engagement: '88%',
        success: '94%'
    },

    {
        name: "GourmetGurus",
        followers: '200K',
        engagement: '80%',
        success: '91%'
    },

    {
        name: "EpicureanExplorer",
        followers: '180K',
        engagement: '85%',
        success: '93%'
    }
    ,
    {
        name: "SavorSensation",
        followers: '280K',
        engagement: '78%',
        success: '90%'
    }
    ,
    {
        name: "CulinaryCrafters",
        followers: '240K',
        engagement: '86%',
        success: '92%'
    }
    ,
    {
        name: "DeliciousDiscoveries",
        followers: '190K',
        engagement: '79%',
        success: '88%'
    }
    ,
    {
        name: "GastronomyGurus",
        followers: '330K',
        engagement: '87%',
        success: '95%'
    },

    {
        name: "NomNomNetwork",
        followers: '270K',
        engagement: '83%',
        success: '91%'
    },

    {
        name: "PalatePleasers",
        followers: '220K',
        engagement: '77%',
        success: '90%'
    }
]

export { collection }