import React from 'react'
import { Box, Typography, AvatarGroup, Avatar } from '@mui/material'
import { collection } from './AssignedTableData'

import DataTable from 'react-data-table-component';

const avt1 = 'https://st.depositphotos.com/1308396/61552/i/450/depositphotos_615523558-stock-photo-view-sunrise-light-rear-car.jpg'
const avt2 = 'https://cdn.pixabay.com/photo/2015/04/19/08/32/marguerite-729510_640.jpg'
const avt3 = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3OOfrBLXPZKaVZ-nwC8p9_dYGFi3jY4ktjQ&usqp=CAU'

const customStyles = {
    head: {
        style: {
            fontSize: '13px', // Set the desired font size for the header
        },
    }
};


const CustomName = ({ row }) => (
    <>

        <Box display={'flex'} alignItems={'center'} sx={{ pointerEvents: 'none', cursor: 'pointer' }}>
            <AvatarGroup>
                <Avatar alt="Remy Sharp" src={avt3} sx={{ position: 'relative', bottom: '-10px' }} />
                <Box display={'flex'} flexDirection={'column'}>
                    <Avatar alt="Travis Howard" src={avt2} sx={{ width: 28, height: 28, position: 'relative', left: '-5px', bottom: '-5px' }} />
                    <Avatar alt="Agnes Walker" src={avt1} sx={{ width: 28, height: 28, position: 'relative', left: '-15px', top: '-5px' }} />
                </Box>
                {/* <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" /> */}
            </AvatarGroup>
            <span>{row.name.toUpperCase()}</span>
        </Box>
    </>
)


const columns = [

    {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
        width: '300px',
        cell: row => <CustomName row={row} />
    },
    {
        name: 'Followers',
        selector: row => row.followers,
        sortable: true,
        reorder: true,
        // width: '190px',
        // cell: row => <Customcategory row={row} />
    },
    {
        name: 'Engagement Rate',
        selector: row => row.engagement,
        sortable: true,
        reorder: true,
        // width: '120px',
    },
    {
        name: 'Success Rate',
        selector: row => row.success,
        sortable: true,
        reorder: true,

        // width: '120px',
    }
].map(column => ({
    ...column,
    style: {
        fontSize: '13px',
    }
}))

const AssignedTable = () => {
    return (
        <Box sx={{ padding: 5, bgcolor: '#f2f7ffff', marginTop: 3, borderRadius: 4 }}>
            <Typography sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}>Assigned Collections</Typography>
            <Box mt={2}>
                <DataTable
                    columns={columns}
                    data={collection}
                    pagination
                    fixedHeader
                    fixedHeaderScrollHeight="500px"
                customStyles={customStyles}
                // onRowClicked={handleRowClick}
                />
            </Box>
        </Box>
    )
}

export default AssignedTable