import axios from "axios"
const URL = process.env.REACT_APP_BASE_URL

export const addInfluencerToCollection = async (data) => {
    try {
        const response = await axios.post(`${URL}/updatecollectioninfluncer`, data);
        if (response.data.status == 201) {
            const data = response.data.user;
            if (data) {
                console.log(data);
                return data;
            }
        } else {
            console.error(`Error: ${response.status} - ${response.statusText}`);
            return false;
        }


    }
    catch (error) {
        console.error('error occurs while adding influencer to collections : ' + error.message);

    }

}