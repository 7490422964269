import React from 'react'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Box, Button, Typography } from '@mui/material';

const MyBarChart = () => {
  const data = [
    { name: '11th', x: 12, y: 23, z: 122 },
    { name: '12th', x: 22, y: 3, z: 73 },
    { name: '13th', x: 13, y: 15, z: 32 },
    { name: '14th', x: 44, y: 35, z: 23 },
    { name: '15th', x: 35, y: 45, z: 20 },
    { name: '16th', x: 62, y: 25, z: 29 },
    { name: '17th', x: 37, y: 17, z: 61 },
    { name: '18th', x: 28, y: 32, z: 45 },
    { name: '19th', x: 19, y: 43, z: 93 },
  ];
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', bgcolor: '#f2f7ffff', padding: 5, flexDirection: " column", borderRadius: 4 }} mt={5}>
      <Typography sx={{ fontWeight: 'bold', fontSize: "1.4rem", marginBottom: 3 }}>Overall Growth of Campaign</Typography>
      <BarChart width={1100} height={300} data={data} >
        <CartesianGrid strokeDasharray="3 3"/>
        <XAxis dataKey="name" />
        <YAxis />
        <Legend />
        <Bar dataKey="x" stackId="a" fill="#8884d8" />
        <Bar dataKey="y" stackId="a" fill="#82ca9d" />
        <Bar dataKey="z" stackId="a" fill="#ff5722" />
      </BarChart>
      <Box sx={{display : 'flex', justifyContent : 'end'}}>
        <Button size='small' variant='contained' sx={{background : '#bce6d5ff', color : 'black', fontWeight : 'bold', borderRadius : 8, boxShadow : 'none',border : '1px solid black', '&:hover' : {bgcolor : '#bce6d5ff', boxShadow : 'none'}}}>View Full Report</Button>
      </Box>
    </Box>
  )
}

export default MyBarChart