import axios from "axios"

const URL = process.env.REACT_APP_BASE_URL;

export const addInstaAccount = async (account) => {
    try {
        const response = await axios.post(`${URL}/updateinstagramusername`, account);
        console.log(response);
        return response
    }
    catch (e) {
        console.error("Error while adding insta account : " + e.message);
    }
}
