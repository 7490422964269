import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Icon,
  Typography,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import CollectionsIcon from "@mui/icons-material/Collections";

const Card3 = ({ likes, comments, date }) => {
  return (
    <>
      {/* <Icon
          sx={{ position: "absolute", top: 5, right: 5, cursor: "pointer" }}
        >
          <CloseIcon fontSize="small" />
        </Icon> */}
      <Card
        sx={{
          maxWidth: 250,
          minWidth: 200,
          width: 240,
          maxHeight: 280,
          height: 280,
          position: "relative",
          marginBottom: 2,
        }}
      >
        <CollectionsIcon
          sx={{ position: "absolute", top: 5, right: 5, color: "white" }}
          fontSize="15"
        />
        <CardMedia
          sx={{ height: "85%" }}
          image="https://images.pexels.com/photos/2646483/pexels-photo-2646483.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          title="Profile image"
        />
        <CardActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "12px 7px",
            color: "grey",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 0.5,
              }}
            >
              <FavoriteBorderIcon />
              <Typography variant="subtitle2">{likes}m</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 0.5,
              }}
            >
              <ChatBubbleOutlineOutlinedIcon />
              <Typography variant="body2">{comments}k</Typography>
            </Box>
          </Box>
          <Box>
            <Typography variant="body2">{date}</Typography>
          </Box>
        </CardActions>
      </Card>
    </>
  );
};

export default Card3;
