import axios from "axios";

const URL = process.env.REACT_APP_BASE_URL

export const getAllBrands = async () => {
    try {
        const response = await axios.post(`${URL}/getallbrands`);
        if (response.data.status === 200) {
            const data = response.data.users;
            if (data) {
                return data;
            } else {
                console.error("Error: Brands data is empty");
                return false;
            }
        } else {
            console.error(`Error: ${response.status} - ${response.statusText}`);
            return false;
        }


    }
    catch (error) {
        console.error('error occurs while fetch all brands data : ' + error.message);

    }

}