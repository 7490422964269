import {
  Box,
  Typography,
  AvatarGroup,
  Avatar,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Tabs,
  styled,
  Tab,
  BottomNavigation,
  BottomNavigationAction,
  Dialog,
  Tooltip,
  Select,
  MenuItem,
  ListItem,
  CircularProgress,
  Modal,
  Accordion,
  AccordionSummary,
  Checkbox,
  AccordionDetails,
  FormControl,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Switch,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  Search,
  AddRounded,
  ShareRounded,
  BookmarkBorderRounded,
  VisibilityOffRounded,
  CloseRounded,
  FilterAltOutlined,
  SwapVertOutlined,
  CancelOutlined,
  WarningAmberOutlined,
  Add,
  AddTaskOutlined,
  Instagram,
  YouTube,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { NavLink, Outlet, useParams } from "react-router-dom";
import myStyle from "../../styles/CollectinProfile.module.css";
import YoutubeIcon from "../../images/youtube-sm.png";
import InstaIcon from "../../images/insta-sm.png";
import { width } from "@mui/system";
import { getCollectionById } from "../../../service/collections api/getCollectionById";
import { getBrandByID } from "../../../service/brands/getBrandByID";
import { getAllInfluencers } from "../../../service/getAllApi";
import InfluencerTable from "./InfluencerTable";
import Analytics from "./Analytics";
import { getAllBrands } from "../../../service/brands/getAllBrands";
import AddNewBrand from "./AddNewBrand";
import { updateCollection } from "../../../service/collections api/createCollection";
import ShareCollection from "./ShareCollection";
import CsvFileUploader from "./CsvFileUploader";
import { getAllCollection } from "../../../service/collections api/getAllCollection";
import { makeStyles } from "@mui/styles";
import SendIcon from "@mui/icons-material/Send";
import PDFicon from "../../images/pdf.png";
import CSVicon from "../../images/csv.png";
import InfluencersDataTable from "../subcomponents/findInfluencer/InfluencersDataTable";
import AnalyticsYoutube from "./AnalyticsYoutube";

const img1 =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToQeYeClZMrqqq2aaTT2UdbuWtVktz8jQ9gw&usqp=CAU";

const navLinkStyle = {
  textDecoration: "none",
  color: "black",
  padding: "10px",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  // overflowY: 'scroll',
  height: "100%",
  bgcolor: "background.paper",
  height: "80%",
  boxSizing: "border-box",
  borderRadius: 7,
  padding: "2rem",
  "&:--webkit-scrollbar": {
    display: "none",
  },
};

const MyInput = styled("input")(`
    background : #dce7f7ff;
    outlined : none;
    border : none;
    width : 100%;
    padding : 15px 10px;
    border-radius : 8px;
    font-size : 15px;
    margin-top : 5px;
`);

const LoadingSpinner = styled(CircularProgress)(({ theme }) => ({
  color: "#FFFFFF",
}));

const MyTextArea = styled("textarea")(`
background : #dce7f7ff;
    outlined : none;
    border : none;
    width : 100%;
    padding : 15px 10px;
    border-radius : 8px;
    font-size : 15px;
    margin-top : 5px;
`);

const activeLinkStyle = {
  fontWeight: "bold",
  color: "blue",
};

const Mylabel = styled("label")(`
    color : #828282ff;
`);

const CollectionProfile = (props) => {
  const { id } = useParams();

  // Base64 decode function
  const decodeId = (encodedId) => atob(encodedId);
  const decodedId = decodeId(id);
  console.log("decoded collection id : ", decodedId);

  // const rowData = props.location?.state?.rowData;

  // console.log("profile : ", rowData);

  const [value, setValue] = React.useState("one");
  const [value2, setValue2] = React.useState("one");

  const [collection, setCollection] = useState({});
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [brand, setBrand] = useState({});
  const [influencers, setInfluencers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createCollectionDialog, setCreateCollectionDialog] = useState(false);
  const [copy, setCopy] = useState(false);
  const [isSucessful, setIsSuccessful] = useState(false);
  const [sortCriteria, setSortCriteria] = useState("");
  const [sortDirection, setSortDirection] = useState("asc"); // 'asc' for ascending, 'desc' for descending
  const [addBrandDialog, setAddBrandDialog] = useState(false);
  const [brandName, setBrandName] = useState("");
  const [pending, setPending] = useState(false);
  const [accountLink, setAccountLink] = useState("");
  const [status, setStatus] = useState("");
  const [isBrandFocus, setIsBrandFocus] = useState(false);
  const [isStatusFocus, setIsStatusFocus] = useState(false);
  const [allBrands, setAllBrands] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const [linkType, setLinkType] = useState("");
  const [allCollection, setAllCollection] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [error, setError] = useState({
    message: "",
    status: false,
  });
  const [fetchingInfluencer, setFetchingInfluencer] = useState(false);
  const [fetchingInfluencers, setFetchingInfluencers] = useState(false);
  const [selectedSocial, setSelectedSocial] = useState("instagram");
  const [approvedInf, setApprovedInf] = useState([]);
  const [approvedStatus, setApprovedStatus] = useState("");

  // const [editCollection, setEditCollection] = useState({
  //   name: collection.name || "",
  //   description: collection.description || "",
  //   assigned_brand_id: collection.assigned_brand_id || null,
  //   influncers_id: collection.influncers_id || null,
  //   created_by: id,
  // });

  // console.log("influencers collection ", collection);
  const fetchMoreInfluencers = async () => {
    try {
      setFetchingInfluencers(true);
      const response = await getAllInfluencers();
      console.log("influencers ", response);
      if (response.length > 0) {
        setInfluencers(response);
      }
    } catch (error) {
      console.error("Error fetching influencers:", error);
    } finally {
      setFetchingInfluencers(false);
    }
  };

  useEffect(() => {
    fetchMoreInfluencers();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
  };

  const getCollections = async () => {
    setPending(true);
    try {
      const response = await getAllCollection();
      setAllCollection(response);
      console.log("allCollection :-", response);
    } catch (e) {
      console.error("error while fething all collections ", e.message);
    } finally {
      console.log(allCollection);
      console.log("all collection hit");
      setPending(false);
    }
  };

  useEffect(() => {
    getCollections();
  }, []);

  const handleCollectionSelect = (collectionId) => {
    console.log("Selected collection ID:", collectionId); // Debugging statement
    // Check if the selected collection is already in the list
    if (
      !selectedCollection.some((collection) => collection.id === collectionId)
    ) {
      // Find the selected collection object from allCollection
      const selectedCollectionObj = allCollection.find(
        (item) => item.id === collectionId
      );
      console.log("Selected Collection:", selectedCollectionObj); // Debugging statement
      // Update the list of selected collections
      setSelectedCollection([...selectedCollection, selectedCollectionObj]);
    }
    // Close the menu after selection
    setMenuOpen(false);
  };

  const handleCollectionChange = (e) => {
    const { name, value } = e.target;
    setCollection((prevCollection) => ({
      ...prevCollection,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError({ message: "", status: false });
    try {
      const response = await updateCollection(collection);
      console.log("updated collection ", response);
      setCollection(response);

      setIsSuccessful(true);
      setError({ message: "", status: false });
    } catch (e) {
      setError({ message: "Collection doesn't exists.", status: true });
      console.error("Error while submiting  data : " + e);

      setIsSuccessful(false);
    } finally {
      setLoading(false);
    }
  };

  const closeCreateCollectionDialog = () => {
    setCreateCollectionDialog(false);
  };
  const openCreateCollectionDialog = () => {
    setCreateCollectionDialog(true);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setCreateCollectionDialog(false);
    setError({ message: "", status: false });
    setIsSuccessful(false);
    setIsBrandFocus(false);
    setBrandName("");
    setCollection({ ...collection });
    setOpen(false), setSelectedCollection([]);
    // handleNewCollection(newCollection);
    // getCollection();
  };
  const filteredBrands = allBrands?.filter((brand) =>
    brand.name.toLowerCase().includes(brandName.toLowerCase())
  );

  const handleListItemClick = (name, id) => {
    setBrandName(name);
    console.log("brand name ", brandName);

    setCollection((prevCollection) => {
      console.log("Updated collection:", {
        ...prevCollection,
        assigned_brand_id: id,
      });
      return { ...prevCollection, assigned_brand_id: id };
    });

    console.log("Clicked on:", name, "with ID:", id);
    console.log("payload collectin :", collection);
    setIsBrandFocus(false);
  };

  const sortInfluencers = (influencers) => {
    return influencers.sort((a, b) => {
      let valueA, valueB;
      switch (sortCriteria) {
        case "followers":
          valueA = a.followers;
          valueB = b.followers;
          break;
        case "er":
          valueA = a.er; // Assuming 'er' is the property for engagement rate
          valueB = b.er;
          break;
        case "name":
          valueA = a.name.toLowerCase();
          valueB = b.name.toLowerCase();
          break;
        default:
          return 0; // No sorting
      }

      if (valueA < valueB) {
        return sortDirection === "asc" ? -1 : 1;
      }
      if (valueA > valueB) {
        return sortDirection === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  const handleStatusChanged = (value) => {
    setCollection((prevCollection) => ({
      ...prevCollection,
      status: value,
    }));
    setIsStatusFocus(false);
  };

  const getBrands = async () => {
    try {
      const response = await getAllBrands();
      //   console.log("all brands : ", response);
      setAllBrands(response);
    } catch (e) {
      console.error("Error while fetching all the collections" + e.message);
    } finally {
      console.log("api hit.");
    }
  };

  const openAddBrandDialog = () => {
    setAddBrandDialog(true);
  };
  const closeAddBrandDialog = () => {
    setAddBrandDialog(false);
  };

  const handleBrandChange = (e) => {
    const { value } = e.target;
    setBrandName(value);

    // setFilteredBrand
    // setFilteredBrand(filteredBrands)

    console.log(value);
  };

  const handleStatusChange = (e) => {
    const { value } = e.target;
    setStatus(value);
  };

  const brandDetails = async (brand_id) => {
    try {
      const brandID = parseInt(brand_id);
      // console.log(brandID);
      const id_ = { id: brandID };
      // console.log("brand id : ", id_);
      const response = await getBrandByID(id_);
      console.log("brand data  : ", response);
      const brand = response.data.user;
      console.log("brand : ", brand);
      setBrand(brand);
      console.log("brand state : ", brand);
    } catch (e) {
      console.error("error while fetching  brand data by id : ", e.message);
    } finally {
    }
  };

  const appendInfluencersByLink = async () => {
    try {
      if (linkType === "instagram") {
        // Call your API function to append influencers to the collection
        const response = await addInfluencerByInstaLink({
          instaurl: accountLink,
        });
        console.log("response 215", response);
      }
      if (linkType === "youtube") {
        // Call your API function to append influencers to the collection
        const response = await addInfluencerByYtLink({ yturl: accountLink });
        console.log("response 220", response);
      }
    } catch (e) {
      console.error("error while appending influencers by link : ", e.message);
    }
  };
  // Define a function to find influencer names by their IDs
  // const findInfluencerNames = () => {
  //   if (collection && collection.influencers_id && influencers.length > 0) {
  //     const influencerNames = collection.influencers_id.map(influencerId => {
  //       const foundInfluencer = influencers.find(influencer => influencer.id === influencerId);
  //       return foundInfluencer ? undInfluencer.name : ''; // Return the name if influencer is found, otherwise an empty string
  //     });
  //     return influencerNames;
  //   }
  //   return [];
  // }

  // const getInfluencers = async () => {

  //   try {
  //     // setFetchingInfluencer(true);
  //     const response = await getAllInfluencers();
  //     console.log("influencers on cp : ", response);
  //     // dispatch(setInfluencers(response));s
  //     setInfluencers(response)
  //     console.log("influencer on cp of state : ", influencers)

  //     // console.log("collection's influencers", collection.influncers_id);

  //   } catch (error) {
  //     console.error("Error fetching influencers:", error);
  //     // Handle the error (e.g., display an error message)
  //   } finally {
  //     // setFetchingInfluencer(false)
  //   }
  // };

  const handleCloseShareDialog = () => {
    setOpenShareDialog(false); // Close the ShareCollection dialog
  };

  const getBrand = (id) => {
    const brand = allBrands?.filter((brand) => {
      // console.log(brand, id, "brand-id");

      return brand.id == id;
    });
    console.log(brand, "brand");
    return brand;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        window.scroll(0, 0);
        const id = { id: decodedId };
        console.log("profile row id", id);
        const collectionResponse = await getCollectionById(id);
        const collectionData = collectionResponse.data.collection[0];
        console.log("collection by id : ", collectionData);
        setCollection(collectionData);
        setApprovedInf(collectionData.influncers_id_approved);
        // console.log("approved influencer", collectionData.influncers_id_approved)

        if (collectionData) {
          console.log("brand id on cp: ", collectionData.assigned_brand_id);
          await brandDetails(collectionData.assigned_brand_id);

          // Parse influncers_id string into an array of numbers
          const influencerIdsArray = JSON.parse(collectionData.influncers_id);
          console.log("Influencer IDs Array: ", influencerIdsArray);

          // Get influencers data
          const influencersData = await getAllInfluencers();
          console.log("All influencers data: ", influencersData);

          // Filter influencers from influencersData based on collection's influencer IDs
          const filteredInfluencers = influencersData.filter((influencer) =>
            influencerIdsArray.includes(influencer.id)
          );
          console.log("Filtered influencers: ", filteredInfluencers);

          setInfluencers(filteredInfluencers);
        } else {
          console.log("No influencers found for this collection.");
          // Set influencers to an empty array or handle as needed
          setInfluencers([]);
        }
      } catch (e) {
        console.error(
          "error while fetching data collection data by id : ",
          e.message
        );
      }
    };

    fetchData();
    getBrands();

    // getInfluencers()
  }, []);

  // useEffect(() => {
  //   window.scroll(0, 0)
  //   collectionDetails();

  // }, [])

  const [expanded, setExpanded] = useState("panel1");
  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  console.log("approved influencer", approvedInf);

  const [extraColumns, setExtraColumns] = useState(true);

  const [searchTerm, setSearchTerm] = useState("");
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    console.log("Search Term Updated:", event.target.value);
  };

  // Filter the displayed influencers based on the search input
  const filteredInfluencers = searchTerm
    ? influencers.filter((influencer) =>
        influencer.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : influencers;

  const useStyles = makeStyles((theme) => ({
    slideButton: {
      position: "relative",
      width: "160px",
      height: "50px",
      backgroundColor: "#F4F6F8",
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      padding: "10px 20px",
      overflow: "hidden",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      marginTop: "2rem",
    },
    slideIcon: {
      position: "absolute",
      left: 0,
      top: 3,
      bottom: 0,
      backgroundColor: "#3DBE72",
      borderRadius: "50%",
      width: "45px",
      height: "45px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#fff",
      transition: "left 0.3s ease-in-out",
    },
    slideText: {
      color: "#333",
      userSelect: "none",
    },
  }));

  const SlideButton = () => {
    const classes = useStyles();
    const [slide, setSlide] = useState(false);

    const handleSlide = () => {
      setSlide(true);
      setTimeout(() => {
        // Add your action here, for example, sending an invitation
        setSlide(false);
      }, 1000); // Adjust the timeout duration as needed
    };

    return (
      <Box className={classes.slideButton} onClick={handleSlide}>
        <Box
          className={classes.slideIcon}
          style={{ left: slide ? "160px" : "0" }}
        >
          <SendIcon
            style={{
              fontSize: "1.5rem",
            }}
          />
        </Box>
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "1.7rem",
            fontSize: "0.7rem",
            fontWeight: "thin",
          }}
          className={classes.slideText}
        >
          {slide ? <LoadingSpinner /> : "Click to Send invitation"}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      {/* Add Influencer Modal */}
      <Modal
        open={open}
        onClose={() => {
          handleClose;
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <Box sx={style}>
          <Typography
            sx={{
              marginY: "1rem",
            }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Choose a option to add account
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "90%",
              overflowY: "scroll",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                boxShadow: "none",
                gap: "1rem",
              }}
            >
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChangeAccordion("panel1")}
                sx={{
                  boxShadow: "none",
                  width: "100%",
                  borderRadius: 5,
                  border: "none",
                }}
              >
                <AccordionSummary
                  expandIcon=""
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    background: "#3dbf73ff",
                    color: "#000000",
                    fontWeight: "bold",
                    borderRadius: 5,
                    boxShadow: "none",
                    "&:hover": {
                      background: "#3dbf73ff",
                      color: "#000000",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "",
                        fontSize: "1.2rem",
                      }}
                    >
                      Upload CSV
                    </Typography>

                    <Checkbox
                      checked={expanded === "panel1" ? true : false}
                      label="Label"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "white",
                        },
                      }}
                    />
                  </Box>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    boxShadow: "none",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <CsvFileUploader />
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChangeAccordion("panel2")}
                sx={{
                  boxShadow: "none",
                  width: "100%",
                  borderRadius: 5,
                }}
              >
                <AccordionSummary
                  expandIcon=""
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    background: "#3dbf73ff",
                    color: "#000000",
                    fontWeight: "bold",
                    borderRadius: 5,
                    boxShadow: "none",

                    "&:hover": {
                      background: "#3dbf73ff",
                      color: "#000000",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "",
                        fontSize: "1.2rem",
                      }}
                    >
                      Select Existing Collection
                    </Typography>

                    <Checkbox
                      checked={expanded === "panel2" ? true : false}
                      label="Label"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "white",
                        },
                      }}
                    />
                  </Box>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    boxShadow: "none",
                  }}
                >
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Collections
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Collections"
                      >
                        {allCollection.map(
                          ({ name, id, influncers_id }, index) => (
                            <MenuItem
                              key={index}
                              value={id}
                              aria-label="list"
                              sx={{
                                textTransform: "none",
                                fontSize: "1rem",
                                fontWeight: "bold",
                                border: "none",
                              }}
                              onClick={() => handleCollectionSelect(id)} // Handle influencer selection
                            >
                              {name}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                    <div
                      style={{
                        marginTop: "1rem",
                      }}
                    >
                      <h4>Selected Influencers:</h4>
                      <ul
                        style={{
                          display: "flex",
                          width: "100%",
                          flexWrap: "wrap",
                          gap: "1rem",
                          marginTop: "1rem",
                          marginLeft: "1rem",
                        }}
                      >
                        {selectedCollection.map((Collection, index) => (
                          <li
                            style={{
                              marginRight: "1rem",
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                            key={index}
                          >
                            {Collection.name}
                          </li>
                        ))}
                      </ul>
                      {selectedCollection.length > 0 && (
                        <Button
                          onClick={() => {
                            appendInfluencersToCollection(), handleClose();
                          }}
                          variant="contained"
                          sx={{
                            padding: "0",
                            marginTop: "1rem",
                          }}
                        >
                          {" "}
                          Add{" "}
                        </Button>
                      )}
                    </div>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChangeAccordion("panel3")}
                sx={{
                  boxShadow: "none",
                  width: "100%",
                  borderRadius: 5,
                }}
              >
                <AccordionSummary
                  expandIcon=""
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    background: "#3dbf73ff",
                    color: "#000000",
                    fontWeight: "bold",
                    borderRadius: 5,

                    "&:hover": {
                      background: "#3dbf73ff",
                      color: "#000000",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "",
                        fontSize: "1.2rem",
                      }}
                    >
                      Add Account Links
                    </Typography>

                    <Checkbox
                      checked={expanded === "panel3" ? true : false}
                      label="Label"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "white",
                        },
                      }}
                    />
                  </Box>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    boxShadow: "none",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <input
                      onChange={(e) => {
                        setAccountLink(e.target.value);
                      }}
                      type="text"
                      placeholder="Enter Account Link"
                      style={{
                        padding: "1rem",
                        borderRadius: "20px",
                        border: "2px solid black",
                        width: "80%",
                      }}
                    />

                    <FormControl
                      sx={{
                        width: "20%",
                        marginLeft: "1rem",
                      }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Platform
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select Platform"
                        onChange={(e) => setLinkType(e.target.value)}
                        defaultValue={""}
                      >
                        <MenuItem
                          sx={{
                            padding: "5px",
                            margin: "5px",
                            borderRadius: "10px",
                            display: "flex",
                            justifyContent: "center",
                            mb: "1rem",
                            bgcolor: "#e6e6e6",
                          }}
                          value={"instagram"}
                        >
                          <Instagram sx={{ color: "red" }} />
                        </MenuItem>
                        <MenuItem
                          sx={{
                            padding: "5px",
                            margin: "5px",
                            borderRadius: "10px",
                            display: "flex",
                            justifyContent: "center",
                            mb: "1rem",
                            bgcolor: "#e6e6e6",
                          }}
                          value={"youtube"}
                        >
                          <YouTube sx={{ color: "red" }} />
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  {accountLink ? (
                    <>
                      <Button
                        onClick={appendInfluencersByLink}
                        variant="contained"
                        sx={{
                          padding: "0",
                          marginTop: "1rem",
                        }}
                      >
                        Add
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                gap: "1rem",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Button
                variant="outlined"
                size="small"
                sx={{
                  textTransform: "none",
                  borderRadius: 10,
                  fontWeight: "bold",
                  marginRight: 1,
                  background: "#c5edd6ff",
                  borderWidth: "2px",
                  color: "black",
                  borderColor: "#3dbf73ff",
                  "&:hover": {
                    borderWidth: "2px",
                    borderColor: "#3dbf73ff",
                  },
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                size="small"
                variant="contained"
                sx={{
                  bgcolor: error.status ? "#e81e61ff" : "#3dbf73ff",
                  boxShadow: "none",
                  fontWeight: "bold",
                  borderRadius: 10,
                  // padding: '10px 30px',
                  "&:hover": {
                    bgcolor: error.status ? "#e81e61ff" : "#4cb04fff",
                  },
                }}
                onClick={handleClose}
                endIcon={
                  loading ? (
                    <LoadingSpinner size={25} />
                  ) : isSucessful ? (
                    <AddTaskOutlined />
                  ) : (
                    error.status && <WarningAmberOutlined />
                  )
                }
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            mt: "1rem",
            ml: "1rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.5rem",
              color: "#3dbf73ff",
              fontWeight: "bold",
              width: "25%",
            }}
          >
            Collection
          </Typography>

          <Box
            sx={{
              display: "flex",
              gap: "2rem",
              width: "100%",
            }}
          >
            <TextField
              size="small"
              outline="false"
              placeholder="Search Influencers..."
              value={searchTerm} // Bind the searchTerm state to the TextField
              onChange={handleSearchChange} // Handle changes in the TextField
              sx={{
                width: "50%",
                borderRadius: 8,
                borderColor: "black",
                "& .MuiOutlinedInput-root": {
                  borderRadius: 8,
                  "&:hover fieldset": {
                    borderColor: "black",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "black",
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box
              sx={{
                display: "flex",
                width: "100%",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <Button
                sx={{
                  fontWeight: "bold",
                  color: "#3dbf73ff",
                  borderRadius: 20,
                  borderColor: "#3dbf73ff",
                  textTransform: "none",
                  "&:hover": { borderColor: "#3dbf73ff", borderWidth: "2px" },
                  borderWidth: "2px",
                  width: "40%",
                }}
                variant="outlined"
                startIcon={<AddRounded />}
                onClick={handleOpen}
              >
                Add Influencers
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  openCreateCollectionDialog();
                  setCopy(false);
                }}
                // fullWidth
                sx={{
                  background: "#1f2933ff",
                  fontWeight: "bold",
                  borderRadius: 8,
                  boxShadow: "none",
                  width: "20%",
                  "&:hover": {
                    background: "#1f2933ff",
                  },
                }}
              >
                Edit
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  openCreateCollectionDialog();
                  setCopy(true);
                }}
                // fullWidth
                sx={{
                  background: "#1f2933ff",
                  fontWeight: "bold",
                  borderRadius: 8,
                  boxShadow: "none",
                  "&:hover": {
                    background: "#1f2933ff",
                  },
                  width: "15%",
                  mr: "1rem",
                }}
              >
                Copy
              </Button>
              <ShareRounded
                onClick={() => setOpenShareDialog(true)}
                sx={{
                  color: "rgb(61, 191, 115)",
                  ":hover": {
                    cursor: "pointer",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          <Box
            sx={{
              ml: "1rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AvatarGroup
              total={24}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Avatar
                sx={{ width: 56, height: 56 }}
                alt="Remy Sharp"
                src="/static/images/avatar/1.jpg"
              />
              <Avatar
                sx={{ width: 46, height: 46 }}
                alt="Travis Howard"
                src="/static/images/avatar/2.jpg"
              />
              <Avatar
                sx={{ width: 36, height: 36 }}
                alt="Agnes Walker"
                src="/static/images/avatar/4.jpg"
              />
              <Avatar
                sx={{ width: 16, height: 16 }}
                alt="Trevor Henderson"
                src="/static/images/avatar/5.jpg"
              />
            </AvatarGroup>
            <Button
              size="small"
              sx={{
                bgcolor: "#3dbf73ff",
                ":hover": {
                  bgcolor: "#3dbf73ff",
                  color: "white",
                },
                borderRadius: 20,
                mt: "1rem",
              }}
              variant="contained"
            >
              Assign to Campaign
            </Button>
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                mt: "1rem",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  ml: "3rem",
                }}
              >
                <Typography
                  sx={{ fontWeight: "bold", fontSize: "1.5rem", width: "100%" }}
                >
                  {collection?.name}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "thun",
                    fontSize: "1.2rem",
                    color: "grey",
                  }}
                >
                  {brand ? brand.name : "No brand found"}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "thun",
                    fontSize: "1.2rem",
                    color: "grey",
                  }}
                >
                  {collection?.description}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box>
        <Dialog
          open={openShareDialog}
          onClose={handleCloseShareDialog}
          PaperProps={{
            sx: { borderRadius: 5, border: "2px solid black" },
          }}
        >
          <ShareCollection
            sharePopUp={setOpenShareDialog}
            clickedData={collection}
          />
        </Dialog>

        <Dialog
          open={createCollectionDialog}
          onClose={handleClose}
          // 👇 Props passed to Paper (modal content)
          PaperProps={{
            sx: { borderRadius: 5, border: "2px solid black", width: "50%" },
          }}
        >
          {isSucessful ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 3,
              }}
            >
              <AddTaskOutlined
                sx={{ fontSize: "2.2rem", color: "#3dbe72ff" }}
              />

              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                  color: "#3dbe72ff",
                  marginTop: 2,
                }}
              >
                Collection Successfully Updated.
              </Typography>

              <Box
                mt={4}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  width: "100%",
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    bgcolor: "#3dbe72ff",
                    // color : '#3dbe72ff',
                    fontWeight: "bold",
                    // border: '1px solid black',
                    boxShadow: "none",
                    textTransform: "none",
                    border: "1px solid #3dbe72ff",
                    marginRight: 1,
                    "&:hover": {
                      bgcolor: "#3dbe72ff",
                      color: "white",
                      borderColor: "black",
                    },
                  }}
                  onClick={() => handleClose()}
                >
                  Close
                </Button>
              </Box>
            </Box>
          ) : (
            <Box container p={4}>
              <Box
                item
                md={11}
                xs={11}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                    color: "#4caf50",
                  }}
                >
                  {copy ? "Copy Collection" : "Edit Collection"}
                </Typography>
              </Box>
              <Box
                item
                md={1}
                xs={1}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Tooltip title="Close" arrow placement="left">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setCreateCollectionDialog(false);
                      handleClose();
                    }}
                  >
                    <CancelOutlined />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box item md={12} xs={12}>
                <form>
                  <Box mt={2}>
                    <Mylabel htmlFor="collection">Name of Collection</Mylabel>
                    <Box>
                      <MyInput
                        type="text"
                        name="name"
                        id="collection"
                        value={collection?.name}
                        disabled={!isEditing}
                        placeholder="Enter the Name of collection"
                        onChange={(e) => handleCollectionChange(e)}
                        required
                        onFocus={() => setIsBrandFocus(false)}
                      />
                    </Box>
                  </Box>
                  <Box mt={2}>
                    <Mylabel htmlFor="description">Description</Mylabel>
                    <Box>
                      <MyTextArea
                        rows={3}
                        type="text"
                        name="description"
                        id="description"
                        disabled={!isEditing}
                        placeholder="Write short description"
                        onChange={(e) => handleCollectionChange(e)}
                        required
                        value={collection.description}
                        onFocus={() => setIsBrandFocus(false)}
                      />
                    </Box>
                  </Box>
                  <Box mt={2}>
                    <Mylabel htmlFor="brand">Brand Name</Mylabel>
                    <Box position={"relative"}>
                      <MyInput
                        type="text"
                        name="brand_name"
                        id="brand"
                        disabled={!isEditing}
                        value={
                          getBrand(collection?.assigned_brand_id)[0]?.name || ""
                        }
                        placeholder="search the brand "
                        required
                        onChange={(e) => handleBrandChange(e)}
                        onFocus={() => {
                          setIsBrandFocus(true);
                        }}
                        onBlur={() => setIsBrandFocus(false)}
                      />
                      <IconButton
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                        }}
                        onClick={openAddBrandDialog}
                      >
                        <Tooltip title="Add a new Brand" arrow>
                          <Add />
                        </Tooltip>
                      </IconButton>
                      {isBrandFocus && (
                        <Box
                          sx={{
                            padding: "5px 10px",
                            position: "absolute",
                            width: "100%",
                            background: "white",
                            marginTop: 1,
                            borderRadius: 2,
                            maxHeight: "140px",
                            overflowY: "scroll",
                            zIndex: 10,
                          }}
                        >
                          {filteredBrands?.reverse()?.map(({ name, id }) => (
                            <ListItem
                              key={id}
                              component="div"
                              sx={{ cursor: "pointer" }}
                              onClick={() => handleListItemClick(name, id)}
                            >
                              {name}
                            </ListItem>
                          ))}
                        </Box>
                      )}
                    </Box>
                  </Box>

                  <Box mt={2}>
                    <Mylabel htmlFor="collection">Status</Mylabel>
                    <Box position={"relative"}>
                      <MyInput
                        value={collection.status}
                        type="text"
                        name="brand_name"
                        id="brand"
                        disabled={!isEditing}
                        placeholder="search the brand "
                        required
                        onChange={(e) => handleStatusChange(e)}
                        onFocus={() => {
                          setIsStatusFocus(true);
                        }}
                        onBlur={() => setIsStatusFocus(false)}
                      />

                      {isStatusFocus && (
                        <Box
                          sx={{
                            padding: "5px 10px",
                            position: "absolute",
                            width: "100%",
                            background: "white",
                            marginTop: 1,
                            borderRadius: 2,
                            maxHeight: "100px",
                            overflowY: "auto",
                            zIndex: 10,
                          }}
                        >
                          <ListItem
                            key={id}
                            component="div"
                            sx={{ cursor: "pointer" }}
                            onClick={() => handleStatusChanged("Active")}
                          >
                            Active
                          </ListItem>
                          <ListItem
                            key={id}
                            component="div"
                            sx={{ cursor: "pointer" }}
                            onClick={() => handleStatusChanged("Draft")}
                          >
                            Draft
                          </ListItem>
                          <ListItem
                            key={id}
                            component="div"
                            sx={{ cursor: "pointer" }}
                            onClick={() => handleStatusChanged("Rejected")}
                          >
                            Rejected
                          </ListItem>
                          <ListItem
                            key={id}
                            component="div"
                            sx={{ cursor: "pointer" }}
                            onClick={() => handleStatusChanged("Archieved")}
                          >
                            Archieved
                          </ListItem>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  {copy && (
                    <FormControl sx={{ mt: 2 }}>
                      <Mylabel id="demo-row-radio-buttons-group-label">
                        Influencers
                      </Mylabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="All"
                          control={<Radio />}
                          label="All"
                        />
                        <FormControlLabel
                          value="Selected"
                          control={<Radio />}
                          label="Selected"
                        />
                        <FormControlLabel
                          value="Rejected"
                          control={<Radio />}
                          label="Rejected"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      mt: 2,
                    }}
                  >
                    {copy ? (
                      <Box>
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{
                            textTransform: "none",
                            borderRadius: 10,
                            fontWeight: "bold",
                            marginRight: 1,
                            background: "#c5edd6ff",
                            borderWidth: "2px",
                            color: "black",
                            borderColor: "#3dbf73ff",
                            "&:hover": {
                              borderWidth: "2px",
                              borderColor: "#3dbf73ff",
                            },
                          }}
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          sx={{
                            bgcolor: error.status ? "#e81e61ff" : "#3dbf73ff",
                            boxShadow: "none",
                            fontWeight: "bold",
                            borderRadius: 10,
                            // padding: '10px 30px',
                            "&:hover": {
                              bgcolor: error.status ? "#e81e61ff" : "#4cb04fff",
                            },
                          }}
                          onClick={handleSubmit}
                          endIcon={
                            loading ? (
                              <LoadingSpinner size={25} />
                            ) : isSucessful ? (
                              <AddTaskOutlined />
                            ) : (
                              error.status && <WarningAmberOutlined />
                            )
                          }
                        >
                          Save
                        </Button>
                      </Box>
                    ) : (
                      <Box>
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{
                            textTransform: "none",
                            borderRadius: 10,
                            fontWeight: "bold",
                            marginRight: 1,
                            background: "#c5edd6ff",
                            borderWidth: "2px",
                            color: "black",
                            borderColor: "#3dbf73ff",
                            "&:hover": {
                              borderWidth: "2px",
                              borderColor: "#3dbf73ff",
                            },
                          }}
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          sx={{
                            bgcolor: error.status ? "#e81e61ff" : "#3dbf73ff",
                            boxShadow: "none",
                            fontWeight: "bold",
                            borderRadius: 10,
                            // padding: '10px 30px',
                            "&:hover": {
                              bgcolor: error.status ? "#e81e61ff" : "#4cb04fff",
                            },
                          }}
                          onClick={handleSubmit}
                          endIcon={
                            loading ? (
                              <LoadingSpinner size={25} />
                            ) : isSucessful ? (
                              <AddTaskOutlined />
                            ) : (
                              error.status && <WarningAmberOutlined />
                            )
                          }
                        >
                          Save
                        </Button>
                      </Box>
                    )}
                  </Box>
                </form>
              </Box>
            </Box>
          )}
        </Dialog>

        <Dialog open={addBrandDialog} onClose={closeAddBrandDialog}>
          <AddNewBrand close={closeAddBrandDialog} />
        </Dialog>

        <Box
          sx={{
            display: "flex",
            ml: "1rem",
            mb: "2rem",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              mr: "1rem",
            }}
          >
            <SlideButton />
          </Box>

          <div>
            <Tabs
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#3dbf73ff",
                },
              }}

              // textColor="secondary"
              // indicatorColor="secondary"
              // aria-label="secondary tabs example"
            >
              <Tab
                icon={<img src={InstaIcon} width={25} />}
                value="one"
                sx={{
                  textTransform: "none",
                  fontWeight: "bold",
                  color: "black",
                  border: "none",
                  mt: "1rem",
                  fontSize: "1rem",
                }}
                label="Instagram"
                iconPosition="start"
              />
              <Tab
                icon={<img src={YoutubeIcon} width={25} />}
                sx={{
                  textTransform: "none",
                  fontWeight: "bold",
                  color: "black",
                  mt: "1rem",
                  fontSize: "1rem",
                }}
                value="two"
                label="Youtube"
                iconPosition="start"
              />
            </Tabs>
          </div>
        </Box>
        <Tabs
          value={value2}
          onChange={handleChange2}
          // textColor="secondary"
          // indicatorColor="secondary"
          // aria-label="secondary tabs example"
        >
          <Tab
            value="one"
            sx={{
              textTransform: "none",
              fontWeight: "bold",
            }}
            label="Influencers"
          />

          <Tab
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              color: "black",
            }}
            value="two"
            label="Analytics"
          />
        </Tabs>
      </Box>
      <Box>
        {/* <Outlet /> */}
        {value === "one" ? (
          <Box>
            {value2 === "one" ? (
              <div
                style={{
                  width: "79vw",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <InfluencersDataTable
                    extraColumns={extraColumns}
                    influencers={sortInfluencers(filteredInfluencers)}
                    fetching={fetchingInfluencer}
                    fetchMoreInfluencers={fetchMoreInfluencers}
                    onScrollFetching={fetchingInfluencers}
                    selectedSocial={selectedSocial}
                    approvedInf={approvedInf}
                    collectionId={decodedId}
                  />
                </Box>
              </div>
            ) : (
              <Analytics />
            )}
          </Box>
        ) : (
          <Box>
            {value2 === "one" ? (
              <div
                style={{
                  width: "79vw",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <InfluencersDataTable
                    extraColumns={extraColumns}
                    influencers={sortInfluencers(filteredInfluencers)}
                    fetching={fetchingInfluencer}
                    fetchMoreInfluencers={fetchMoreInfluencers}
                    onScrollFetching={fetchingInfluencers}
                    selectedSocial={selectedSocial}
                  />
                </Box>
              </div>
            ) : (
              <AnalyticsYoutube />
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default CollectionProfile;
