const employees = [
    {
        id: 1,
        name: 'Courtney Henry',
        insta: '@johnsmith',
        facebook: 'john.smith',
        youtube: 'johnsmithofficial',
        data: '08/02/19',
        decided: 18
    },
    {
        id: 2,
        name: 'John Smith',
        insta: '@johnsmith',
        facebook: 'john.smith',
        youtube: 'johnsmithofficial',
        data: '12/15/20',
        decided: 25
    },
    {
        id: 3,
        name: 'Emily Davis',
        insta: '@emilydavis',
        facebook: 'emily.davis.92',
        youtube: 'emilydofficial',
        data: '05/07/18',
        decided: 22
    },
    {
        id: 4,
        name: 'Michael Johnson',
        insta: '@michaeljohnson',
        facebook: 'michael.johnson.45',
        youtube: 'mjohnsonofficial',
        data: '11/30/21',
        decided: 30
    },
    {
        id: 5,
        name: 'Jessica Brown',
        insta: '@jessicabrown',
        facebook: 'jessica.brown.78',
        youtube: 'jessicabofficial',
        data: '09/14/17',
        decided: 15
    },
    {
        id: 6,
        name: 'Brian Miller',
        insta: '@brianmiller',
        facebook: 'brian.miller.33',
        youtube: 'brianmofficial',
        data: '03/22/19',
        decided: 20
    },
    {
        id: 7,
        name: 'Amanda White',
        insta: '@amandawhite',
        facebook: 'amanda.white.56',
        youtube: 'amandawofficial',
        data: '07/10/20',
        decided: 28
    },
    {
        id: 8,
        name: 'David Taylor',
        insta: '@davidtaylor',
        facebook: 'david.taylor.21',
        youtube: 'davidtofficial',
        data: '01/05/22',
        decided: 35
    },
    {
        id: 9,
        name: 'Olivia Harris',
        insta: '@oliviaharris',
        facebook: 'olivia.harris.14',
        youtube: 'oliviahofficial',
        data: '04/18/18',
        decided: 19
    },
    {
        id: 10,
        name: 'Chris Anderson',
        insta: '@chrisanderson',
        facebook: 'chris.anderson.89',
        youtube: 'chrisaofficial',
        data: '10/11/21',
        decided: 24
    }
];


export { employees }