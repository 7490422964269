// sharedStateSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
};

export const sharedStateSlice = createSlice({
  name: "sharedState",
  initialState,
  reducers: {
    setIsOpen: (state, action) => {
      state.isOpen = action.payload;
    },
  },
});

export const { setIsOpen } = sharedStateSlice.actions;

export default sharedStateSlice.reducer;
