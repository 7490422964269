import * as React from "react";
import { useEffect } from "react";

//! REDUX TOOLKIT MODULE
import { useSelector, useDispatch } from "react-redux";

//! Material UI Styled Components Imported Below :
import { styled, useTheme } from "@mui/material/styles";

//! React-Router-Dom Modules Imported Below :
import {
  BrowserRouter,
  Route,
  Link,
  NavLink,
  useLocation,
  useNavigate,
} from "react-router-dom";

//! Material Ui Components Imported Below :
import { Box, Modal } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import useMediaQuery from "@mui/material/useMediaQuery";

//! Material UI Icons Imported Below :
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import PersonSearchRoundedIcon from "@mui/icons-material/PersonSearchRounded";
import LightbulbRoundedIcon from "@mui/icons-material/LightbulbRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import TextSnippetRoundedIcon from "@mui/icons-material/TextSnippetRounded";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import LogoutIcon from "@mui/icons-material/Logout";

//! Custom JSX Components Imported Below :
import Header from "./subcomponents/miniDrawer/Header";
import DrawerList from "./subcomponents/miniDrawer/DrawerList";
import MiniDrawerRoute from "./subcomponents/miniDrawer/MiniDrawerRoute";

//! ACTIVE LINK CSS STYLESHEET :
import "../styles/navlink.module.css";
import { Logout } from "@mui/icons-material";
import { setIsAuthorized } from "../../features/authorization/AuthorizationSlice";
import { setIsOpen } from "../../features/drawerStatus/SharedStateSlice";

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: "#1F2933",
  color: "#FFFFFF",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: "#1F2933",
  color: "#FFFFFF",
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  padding: theme.spacing(0, 1),
  marginBottom: "1rem",

  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Navbar = styled(NavLink)(({ theme }) => ({
  textDecoration: "none",
}));

// const isXs = useMediaQuery(theme.breakpoints.down('md'));

const Navigation = ({ navItems, open }) => {
  const { role } = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const location = useLocation();

  const navigate = useNavigate();

  const isHomeActive =
    location.pathname === "/" || location.pathname === "/home";

  const handleNavbarClick = (event) => {
    event.preventDefault();
    const userPermission = window.confirm("Are you sure to Logout?");

    if (userPermission) {
      localStorage.removeItem("authToken");
      dispatch(setIsAuthorized(false));
      navigate(navItems[9].path);
    }
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "75%",

        // experimental code
        alignItems: open && "start",
        paddingLeft: open && "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Navbar
          to={navItems[0].path}
          exact={true}
          style={({ isActive }) => ({
            color: isActive ? "black" : "white",
            background: isActive && "white",
            borderRadius: open && "10px",
            marginTop: open && "1rem",
          })}
        >
          <ListItemButton
            sx={
              {
                // background: 'yellow',
                // borderRadius: '10px',
              }
            }
          >
            <Tooltip
              title={navItems[0].text}
              arrow
              placement="right"
              componentsProps={{
                arrow: {
                  sx: {
                    color: "#3dbf73ff",
                  },
                },
                tooltip: {
                  sx: {
                    backgroundColor: "#3dbf73ff",
                    color: "white",
                  },
                },
              }}
            >
              {navItems[0].icon}
            </Tooltip>
            {open && (
              <ListItemText
                primary={navItems[0].text}
                sx={{ paddingLeft: 2 }}
              />
            )}
          </ListItemButton>
        </Navbar>

        <Navbar
          to={navItems[1].path}
          exact
          style={({ isActive }) => ({
            color: isActive ? "black" : "white",
            background: isActive && "white",
            borderRadius: open && "10px",
            // marginTop: open && '1rem'
          })}
        >
          <ListItemButton>
            <Tooltip
              title={navItems[1].text}
              arrow
              placement="right"
              componentsProps={{
                arrow: {
                  sx: {
                    color: "#3dbf73ff",
                  },
                },
                tooltip: {
                  sx: {
                    backgroundColor: "#3dbf73ff",
                    color: "white",
                  },
                },
              }}
            >
              {navItems[1].icon}
            </Tooltip>
            {open && (
              <ListItemText
                primary={navItems[1].text}
                sx={{ paddingLeft: 2 }}
              />
            )}
          </ListItemButton>
        </Navbar>

        <Navbar
          to={navItems[2].path}
          exact
          style={({ isActive }) => ({
            color: isActive ? "black" : "white",
            background: isActive && "white",
            borderRadius: open && "10px",
          })}
        >
          <ListItemButton>
            <Tooltip
              title={navItems[2].text}
              arrow
              placement="right"
              componentsProps={{
                arrow: {
                  sx: {
                    color: "#3dbf73ff",
                  },
                },
                tooltip: {
                  sx: {
                    backgroundColor: "#3dbf73ff",
                    color: "white",
                  },
                },
              }}
            >
              {navItems[2].icon}
            </Tooltip>
            {open && (
              <ListItemText
                primary={navItems[2].text}
                sx={{ paddingLeft: 2 }}
              />
            )}
          </ListItemButton>
        </Navbar>

        <Navbar
          to={navItems[3].path}
          exact
          style={({ isActive }) => ({
            color: isActive ? "black" : "white",
            background: isActive && "white",
            borderRadius: open && "10px",
          })}
        >
          <ListItemButton>
            <Tooltip
              title={navItems[3].text}
              arrow
              placement="right"
              componentsProps={{
                arrow: {
                  sx: {
                    color: "#3dbf73ff",
                  },
                },
                tooltip: {
                  sx: {
                    backgroundColor: "#3dbf73ff",
                    color: "white",
                  },
                },
              }}
            >
              {navItems[3].icon}
            </Tooltip>
            {open && (
              <ListItemText
                primary={navItems[3].text}
                sx={{ paddingLeft: 2 }}
              />
            )}
          </ListItemButton>
        </Navbar>

        {role === "1" && (
          <Navbar
            to={navItems[4].path}
            exact
            style={({ isActive }) => ({
              color: isActive ? "black" : "white",
              background: isActive && "white",
              borderRadius: open && "10px",
            })}
          >
            <ListItemButton>
              <Tooltip
                title={navItems[4].text}
                arrow
                placement="right"
                componentsProps={{
                  arrow: {
                    sx: {
                      color: "#3dbf73ff",
                    },
                  },
                  tooltip: {
                    sx: {
                      backgroundColor: "#3dbf73ff",
                      color: "white",
                    },
                  },
                }}
              >
                {navItems[4].icon}
              </Tooltip>
              {open && (
                <ListItemText
                  primary={navItems[4].text}
                  sx={{ paddingLeft: 2 }}
                />
              )}
            </ListItemButton>
          </Navbar>
        )}

        <Navbar
          to={navItems[5].path}
          exact
          style={({ isActive }) => ({
            color: isActive ? "black" : "white",
            background: isActive && "white",
            borderRadius: open && "10px",
          })}
        >
          <ListItemButton>
            <Tooltip
              title={navItems[5].text}
              arrow
              placement="right"
              componentsProps={{
                arrow: {
                  sx: {
                    color: "#3dbf73ff",
                  },
                },
                tooltip: {
                  sx: {
                    backgroundColor: "#3dbf73ff",
                    color: "white",
                  },
                },
              }}
            >
              {navItems[5].icon}
            </Tooltip>
            {open && (
              <ListItemText
                primary={navItems[5].text}
                sx={{ paddingLeft: 2 }}
              />
            )}
          </ListItemButton>
        </Navbar>

        <Navbar
          to={navItems[6].path}
          exact
          style={({ isActive }) => ({
            color: isActive ? "black" : "white",
            background: isActive && "white",
            borderRadius: open && "10px",
          })}
        >
          <ListItemButton>
            <Tooltip
              title={navItems[6].text}
              arrow
              placement="right"
              componentsProps={{
                arrow: {
                  sx: {
                    color: "#3dbf73ff",
                  },
                },
                tooltip: {
                  sx: {
                    backgroundColor: "#3dbf73ff",
                    color: "white",
                  },
                },
              }}
            >
              {navItems[6].icon}
            </Tooltip>
            {open && (
              <ListItemText
                primary={navItems[6].text}
                sx={{ paddingLeft: 2 }}
              />
            )}
          </ListItemButton>
        </Navbar>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Navbar
          to={navItems[7].path}
          exact
          style={({ isActive }) => ({
            color: isActive ? "black" : "white",
            background: isActive && "white",
            borderRadius: open && "10px",
          })}
        >
          <ListItemButton>
            <Tooltip
              title={navItems[7].text}
              componentsProps={{
                arrow: {
                  sx: {
                    color: "#3dbf73ff",
                  },
                },
                tooltip: {
                  sx: {
                    backgroundColor: "#3dbf73ff",
                    color: "white",
                  },
                },
              }}
              arrow
              placement="right"
            >
              {navItems[7].icon}
            </Tooltip>

            {open && (
              <ListItemText
                primary={navItems[7].text}
                sx={{ paddingLeft: 2 }}
              />
            )}
          </ListItemButton>
        </Navbar>

        <Navbar
          to={navItems[9].path}
          exact
          onClick={handleNavbarClick}
          style={({ isActive }) => ({
            color: isActive ? "black" : "white",
            background: isActive && "white",
            borderRadius: open && "10px",
          })}
        >
          <ListItemButton>
            <Tooltip
              title={navItems[9].text}
              arrow
              placement="right"
              componentsProps={{
                arrow: {
                  sx: {
                    color: "#3dbf73ff",
                  },
                },
                tooltip: {
                  sx: {
                    backgroundColor: "#3dbf73ff",
                    color: "white",
                  },
                },
              }}
            >
              {navItems[9].icon}
            </Tooltip>
            {open && (
              <ListItemText
                primary={navItems[9].text}
                sx={{ paddingLeft: 2 }}
              />
            )}
          </ListItemButton>
        </Navbar>

        <Navbar
          to={navItems[8].path}
          exact
          style={({ isActive }) => ({
            color: isActive ? "black" : "white",
            background: isActive && "white",
            borderRadius: open && "10px",
          })}
        >
          <ListItemButton>
            <Tooltip
              title={navItems[8].text}
              arrow
              placement="right"
              componentsProps={{
                arrow: {
                  sx: {
                    color: "#3dbf73ff",
                  },
                },
                tooltip: {
                  sx: {
                    backgroundColor: "#3dbf73ff",
                    color: "white",
                  },
                },
              }}
            >
              {navItems[8].icon}
            </Tooltip>
            {open && (
              <ListItemText
                primary={navItems[8].text}
                sx={{ paddingLeft: 2 }}
              />
            )}
          </ListItemButton>
        </Navbar>
      </Box>
    </Box>
  );
};

export default function MiniDrawer() {
  const isXsScreen = useMediaQuery("(max-width: 600px)"); // adjust size for XS

  const navItems = [
    { text: "Home", icon: <HomeRoundedIcon />, path: "/dashboard/home" },
    {
      text: "Find Influencer",
      icon: <PersonSearchRoundedIcon />,
      path: "/dashboard/find-influencers",
    },
    {
      text: "Collection",
      icon: <LightbulbRoundedIcon />,
      path: "/dashboard/collection",
    },
    {
      text: "Campaign",
      icon: <GroupsRoundedIcon />,
      path: "/dashboard/campaign",
    },
    {
      text: "Manage Brands",
      icon: <TextSnippetRoundedIcon />,
      path: "/dashboard/manage-brands",
    },
    {
      text: "Manage Influencers",
      icon: <ManageAccountsRoundedIcon />,
      path: "/dashboard/manage-influencers",
    },
    {
      text: "Manage Employees",
      icon: <ManageAccountsRoundedIcon />,
      path: "/dashboard/manage-employees",
    },
    {
      text: "Setting",
      icon: <SettingsRoundedIcon />,
      path: "/dashboard/setting",
    },
    {
      text: "Profile",
      icon: <PersonRoundedIcon />,
      path: "/dashboard/profile",
    },
    { text: "Logout", icon: <LogoutIcon />, path: "/login" },
  ];

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const isAuthorized = useSelector((state) => state.authorization);
  const [active, setActive] = React.useState(false);
  const navigate = useNavigate();

  const isMiniOpen = useSelector((state) => state.sharedState.isOpen);
  const dispatch = useDispatch();

  const handleDrawer = () => {
    setOpen((x) => (x == true ? false : true));
    dispatch(setIsOpen(!isMiniOpen));
  };

  useEffect(() => {
    if (isXsScreen) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isXsScreen]);




  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          position: isXsScreen && "absolute",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <DrawerHeader>
          {open && <Header />}
          <Tooltip title={open ? "Close" : "Open"} placement="right" arrow>
            {/* <IconButton onClick={handleDrawer} style={{ color: '#FFF', position : 'absolute', right : '-15px',background : 'green'  }} size='small'>
              {open ? <ArrowBackIosNewRoundedIcon /> : <ArrowForwardIosRoundedIcon />}
            </IconButton> */}

            <IconButton
              onClick={handleDrawer}
              style={{ color: "#FFF" }}
              size="small"
            >
              {/* {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />} */}
              {open ? (
                <ArrowBackIosNewRoundedIcon />
              ) : (
                <ArrowForwardIosRoundedIcon />
              )}
            </IconButton>
          </Tooltip>
        </DrawerHeader>
        <Divider sx={{ background: "grey" }} />

        <Navigation navItems={navItems} open={open} set={setOpen} />
      </Drawer>

      <MiniDrawerRoute />
    </Box>
  );
}
