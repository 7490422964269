import axios from "axios";

const URL = process.env.REACT_APP_BASE_URL

export const getAllInfluencers = async () => {
    try {
        const response = await axios.post(`${URL}/getallinfluncers`);
        if (response.data.status === 200) {
            const data = response.data.users;
            if (data) {
                return data;
            } else {
                console.error("Error: influencer data is empty");
                return false;
            }
        } else {
            console.error(`Error: ${response.status} - ${response.statusText}`);
            return false;
        }


    }
    catch (error) {
        console.error('error occurs while fetch all influencers data : ' + error.message);

    }


}



export const getAllBrands = async () => {
    try {
        const response = await axios.post(`${URL}/getallbrands`);
        if (response.data.status === 200) {
            const data = response.data.users;
            if (data) {
                return data;
            } else {
                console.error("Error: Brands data is empty");
                return false;
            }
        } else {
            console.error(`Error: ${response.status} - ${response.statusText}`);
            return false;
        }


    }
    catch (error) {
        console.error('error occurs while fetch all brands data : ' + error.message);

    }

}

export const getAllEmployees = async () => {
    try {
        const response = await axios.post(`${URL}/getallemp`);
        if (response.data.status === 200) {
            const data = response.data.users;
            if (data) {
                return data;
            } else {
                console.error("Error: emp data is empty");
                return false;
            }
        } else {
            console.error(`Error: ${response.status} - ${response.statusText}`);
            return false;
        }


    }
    catch (error) {
        console.error('error occurs while fetch all employee data : ' + error.message);

    }

}