import {
  Button,
  Grid,
  IconButton,
  Typography,
  Box,
  Paper,
} from "@mui/material";
import React, { useState } from "react";
import {
  CloudDownloadOutlined,
  HelpOutlineOutlined,
  FiberManualRecord,
} from "@mui/icons-material";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { BarChart } from "@mui/x-charts/BarChart";
import Stack from "@mui/material/Stack";
import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";




const Content = ({ options }) => {

  const data2 = [
      { label: "Group A", value: 2400 },
      { label: "Group B", value: 4567 },
      { label: "Group C", value: 1398 },
      { label: "Group D", value: 9800 },
      { label: "Group E", value: 3908 },
      { label: "Group F", value: 4800 },
  ];

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
      height: 10,
      borderRadius: 5,
      [`&.${linearProgressClasses.colorPrimary}`]: {
          backgroundColor:
              theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
      },
      [`& .${linearProgressClasses.bar}`]: {
          borderRadius: 5,
          backgroundColor: theme.palette.mode === "light" ? "#e36e46" : "#308fe8",
      },
  }));

  function CustomizedProgressBars({ data }) {
      return (
          <Stack spacing={2} sx={{ flexGrow: 1, padding: "10px 40px" }}>
              <Box
                  sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                  }}
              >
                  <Typography>{data.label}</Typography>
                  <Typography>{data.value / 100} %</Typography>
              </Box>
              <BorderLinearProgress variant="determinate" value={data.value / 100} />
          </Stack>
      );
  }
  function Bar1() {
      return (
          <BarChart
              series={[
                  { data: [35, 44, 24, 34] },
                  { data: [51, 6, 49, 30] },
                  { data: [15, 25, 30, 50] },
                  { data: [60, 50, 15, 25] },
              ]}
              height={250}
              xAxis={[{ data: ["Q1", "Q2", "Q3", "Q4"], scaleType: "band" }]}
              margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
          />
      );
  }
  function Bar2() {
      return (
          <>
              {data2.map((data) => {
                  return <CustomizedProgressBars data={data} />;
              })}
          </>
      );
  }
  function PieChart1() {
      return (
          <Box>
              <PieChart
                  series={[
                      {
                          data: data2,
                          cx: 500,
                          cy: 800,
                          innerRadius: 500,
                          outerRadius: 1000,
                          color: [
                              "#FF69B4",
                              "#33CC33",
                              "#6666FF",
                              "#CCCCCC",
                              "#FF9900",
                              "#111",
                          ],
                      },
                  ]}
                  height={400}
                  slotProps={{
                      legend: { hidden: true },
                  }}
              />
              {data2.map((data) => {
                  return <CustomizedProgressBars data={data} />;
              })}
          </Box>
      );
  }



  return (
      <>
          {
              options === "Profile Audience Languages Percentage" || options === "Profile Audience Interests Percentage" || options === "Profile Audience Likers Countries Percentage" || options === "Profile Audience Likers Cities Percentage" || options === "Profile Audience Likers Gender Age Distribution Percentage" || options === "Profile Audience Likers Cities Percentage" || options === "Profile Audience Likers Languages Percentage" || options === "Profile Audience Likers Languages Percentage" || options === "Profile Audience Likers Languages Percentage" ? <Bar1 /> : null}

          {
              options === "Profile Audience Followers Countries Percentage" || options === "Profile Audience Followers Countries" ? <Bar2 /> : null
          }
          {
              options === "Profile Audience Follower Types Percentage" || options === "Profile Audience Followers Gender Age Distribution - Gender" || options === "Profile Audience Likers Gender Age Distribution - Gender" || options === "Profile Audience Likers Followers Types Percentage" ? <PieChart1 /> : null
          }
      </>
  )
}

export default Content