import { useEffect, useState } from "react";
import React from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Slider,
  TextField,
  Box,
  styled,
  IconButton,
  Tooltip,
} from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";

const SliderInputFieldGroup = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "15px",
}));

const PerformanceSlider = ({
  text,
  setPerformanceSliderFilterState,
  setERState,
  setSponsoredVideoPerformanceState,
  setAvgLikesState,
  clear,
  tooltipInfo,
}) => {
  const [sliderValue, setSliderValue] = useState(50);
  const [inputValue, setInputValue] = useState(50);

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
    setInputValue(newValue);
    setPerformanceSliderFilterState(inputValue);
  };

  const handleInputChange = (event) => {
    const newInputValue = parseInt(event.target.value, 10);
    if (!isNaN(newInputValue)) {
      setSliderValue(newInputValue);
      setInputValue(newInputValue);
      setPerformanceSliderFilterState(newInputValue);
    }
  };

  useEffect(() => {
    if (clear) {
      setSliderValue(50);
      setInputValue(50);
    }
  }, [clear]);

  if (setERState) setERState(sliderValue);
  else if (setAvgLikesState) setAvgLikesState(sliderValue);
  else setSponsoredVideoPerformanceState(sliderValue);

  return (
    <Accordion sx={{ width: { md: "80%", xs: "90%" }, boxShadow: "none" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ textAlign: "start" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: 1,
          }}
        >
          <IconButton style={{ color: "#3DBE72" }}>
            <Tooltip title={tooltipInfo}>
              <HelpOutlineRoundedIcon
                style={{ fontSize: 12, padding: 0, margin: 0 }}
              />
            </Tooltip>
          </IconButton>
          <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ width: "100%", padding: "0 10px" }}>
          <Slider
            value={sliderValue}
            onChange={handleSliderChange}
            valueLabelDisplay="auto"
            style={{ color: "#3dbe72", padding: 0, width: "100%" }}
            size="small"
          />
          <SliderInputFieldGroup>
            <TextField
              variant="outlined"
              type="number"
              value={inputValue}
              onChange={handleInputChange}
              label="From"
              size="small"
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#3dbe72",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#3dbe72",
                },
              }}
            />
          </SliderInputFieldGroup>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default PerformanceSlider;
