import { configureStore } from "@reduxjs/toolkit";

import userReducer from "../features/user/userSlice.js";
import influencerReducer from "../features/influencers/influencersSlice.js";
import authorizationReducer from "../features/authorization/AuthorizationSlice.js";
import sharedStateReducer from "../features/drawerStatus/SharedStateSlice.js";
import customColumnStateReducer  from "../features/customColumn/CustomColumnSlice.js";

export const store = configureStore({
  reducer: {
    user: userReducer,
    influencers: influencerReducer,
    authorization: authorizationReducer,
    sharedState: sharedStateReducer,
    customColumnState: customColumnStateReducer,
  },
});
