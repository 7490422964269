import axios from "axios"
const URL = process.env.REACT_APP_BASE_URL

export const createNewCollection = async (data) => {
    try {
        const response = await axios.post(`${URL}/createcollection`, data);
        console.log("new collection fdsc ", response);

        if (response.status == 201) {
            const data = response.data.collection;
            if (data) {
                console.log("created collection is : ", data);
                return data;
            } else {
                console.error("Error: collections is empty");
                return false;
            }
        } else {
            console.error(`Error: ${response.status} - ${response.statusText}`);
            return false;
        }


    }
    catch (error) {
        console.error('error occurs while create new collections : ' + error.message);

    }

}

export const updateCollection = async (data) => {
    try {
        const response = await axios.post(`${URL}/updatecollection`, data);
        if (response.data.status == 200) {
            const data = response.data.collection;
            if (data) {
                console.log("updated collection is : ", data);
                return data;
            } else {
                console.error("Error: collections is empty");
                return false;
            }
        } else {
            console.error(`Error: ${response.status} - ${response.statusText}`);
            return false;
        }


    }
    catch (error) {
        console.error('error occurs while updating collections : ' + error.message);

    }

}