import { Grid, Typography, TextField, InputAdornment, IconButton, styled, Button, Box } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { Search, Add } from '@mui/icons-material'
import DataTable from 'react-data-table-component';
import { employees } from '../manage employees/employeesData';

import facebookIcon from '../../images/facebook.png'
import instaIcon from '../../images/insta-sm.png'
import youtubeIcon from '../../images/youtube-sm.png'
import { getAllInfluencers } from '../../../service/getAllApi';

const SearchBar = styled(TextField)(({ theme }) => ({
    //    border : 'none'

    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none', // Remove the outline
    },
    border: '1px solid black',
    borderRadius: 20,




}))

const customStyles = {
    head: {
        style: {
            fontSize: '13px', // Set the desired font size for the header
        },
    },
};


const CustomSocial = ({ row }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', }}>
        <img src={instaIcon} alt="" />
        <img src={youtubeIcon} alt="" width={25} style={{ margin: '0px 5px' }} />
        <img src={facebookIcon} alt="" />
    </Box>
)

const CustomAction = ({ row }) => (
    <span>Actions</span>
)

const columns = [

    {
        name: 'ID',
        selector: row => row.id,
        sortable: true,
        width: '80px',
        // cell: row => <CustomName row={row} />
    },
    {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
        reorder: true,
        // width: '190px',
        // cell: row => <Customcategory row={row} />
    },
    {
        name: 'Social',
        selector: row => row.insta,
        sortable: true,
        reorder: true,
        // width: '120px',
        cell: row => <CustomSocial row={row} />
    },
    {
        name: 'Date',
        selector: row => '12/15/20',
        sortable: true,
        reorder: true,

        // width: '120px',
    },
    {
        name: 'To Be Decided',
        selector: row => 25,
        sortable: true,
        reorder: true,

        // cell: row => <CustomAverage row={row} />
    },
    {
        name: 'Action',
        // selector: row => row.decided,
        // sortable: true,
        // reorder: true,

        cell: row => <CustomAction row={row} />
    },
].map(column => ({
    ...column,
    style: {
        fontSize: '13px',
    }
}))


const ManageInfluencers = () => {
    const [influencers, setInfluencers] = useState([]);
    const [fetching, setFetching] = useState(false);

    const getInfluencers = async () => {

        try {
            setFetching(true);
            const response = await getAllInfluencers();
            console.log(response);
            // dispatch(setInfluencers(response));
            setInfluencers(response)
        } catch (error) {
            console.error("Error fetching influencers:", error);
            // Handle the error (e.g., display an error message)
        } finally {
            setFetching(false)
        }
    };

    useEffect(() => {
        getInfluencers();
    }, [])

    return (
        <Grid container sx={{ p: 3 }}>
            <Grid item md={12} xs={12} mb={2}>
                <Typography sx={{ fontWeight: 'bold', fontSize: '1.3rem' }}>Manage Influencers</Typography>
            </Grid>
            <Grid item md={8} xs={12} mt={2}>
                <SearchBar size='small' placeholder='Search by Employees Name...' InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <IconButton>
                                <Search />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                    sx={{ width: '60%' }}
                />
            </Grid>
            <Grid item md={4} xs={12} mt={2}>
                <Button startIcon={<Add />} variant='contained' sx={{ borderRadius: 8, bgcolor: '#000000', boxShadow: 'none', textTransform: 'none', '&:hover': { bgcolor: '#000000', boxShadow: 'none' } }}>Add Influencer</Button>
            </Grid>
            <Grid item md={12} xs={12}>

            </Grid>
            <Grid item md={12} xs={12} mt={4}>
                <DataTable
                    title="Influencers"
                    columns={columns}
                    data={influencers}
                    pagination
                    fixedHeader
                    progressPending={fetching}
                    // fixedHeaderScrollHeight="100%"
                    customStyles={customStyles}
                    // onRowClicked={handleRowClick}
                    selectableRows
                />
            </Grid>
        </Grid>
    )
}

export default ManageInfluencers