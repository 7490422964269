import axios from "axios";
const URL = process.env.REACT_APP_BASE_URL

export const genConfirmYTOTP = async (id) => {
    try {
        const response = await axios.post(`${URL}/generateotpyoutube`, id)
        return response;
    }
    catch (error) {
        console.error("Error while generating otp for youtube verification : " + error.message);

    }
}

