import { createSlice } from "@reduxjs/toolkit";

const token = localStorage.getItem("authToken");
const initialState = token ? true : false;

export const authorizationSlice = createSlice({
  name: "isAuthorized",
  initialState: initialState,
  reducers: {
    setIsAuthorized: (state, action) => {
      return action.payload;
    },
  },
});

export const { setIsAuthorized } = authorizationSlice.actions;

export default authorizationSlice.reducer;
