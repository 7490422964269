import { useState } from "react";
import React from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Slider,
  TextField,
  Box,
  styled,
} from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function valuetext(value) {
  return `${value}°C`;
}

const MinMaxFieldGroup = styled(Box)(({ theme }) => ({
  display: "flex",
  marginTop: "1rem",
  alignItems: "center",
  marginBottom: "10px",
}));

const CustomSearchMinMax = ({ text }) => {
  const [value2, setValue2] = React.useState([20, 37]);
  const [minValue, setMinValue] = useState(value2[0]);
  const [maxValue, setMaxValue] = useState(value2[1]);

  const minDistance = 10;

  const handleChange2 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        setValue2([clamped, clamped + minDistance]);
        setMinValue(clamped);
        setMaxValue(clamped + minDistance);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setValue2([clamped - minDistance, clamped]);
        setMinValue(clamped - minDistance);
        setMaxValue(clamped);
      }
    } else {
      setValue2(newValue);
      setMinValue(newValue[0]);
      setMaxValue(newValue[1]);
    }
  };

  const handleMinChange = (event) => {
    const newMinValue = parseInt(event.target.value, 10);
    if (isNaN(newMinValue)) {
      setMinValue(0); // set min value to 0 when input is emptied
      if (maxValue < 0) {
        setMaxValue(0 + minDistance); // ensure maxValue does not become negative
      }
    } else if (newMinValue >= maxValue - minDistance) {
      setMaxValue(newMinValue + minDistance);
    }
    setMinValue(newMinValue);
    setValue2([newMinValue, maxValue]);
  };

  const handleMaxChange = (event) => {
    const newMaxValue = parseInt(event.target.value, 10);
    if (isNaN(newMaxValue)) {
      setMaxValue(100); // set max value to 100 when input is emptied
      if (minValue > 100) {
        setMinValue(100 - minDistance); // ensure minValue does not become negative
      }
    } else if (newMaxValue <= minValue + minDistance) {
      setMinValue(newMaxValue - minDistance);
    }
    setMaxValue(newMaxValue);
    setValue2([minValue, newMaxValue]);
  };

  return (
    <Accordion sx={{ width: { md: "80%", xs: "90%" }, boxShadow: "none" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ textAlign: "start" }}
      >
        <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          id="outlined-basic"
          label={text}
          variant="outlined"
          placeholder="Total views"
          sx={{
            width: "100%",
            "& .MuiInputLabel-root": {
              color: "#3dbe72",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#3dbe72",
              },
              "&:hover fieldset": {
                borderColor: "#3dbe72",
              },
            },
            "& .MuiInputBase-input": {
              color: "#3dbe72",
            },
          }}
        />
        <Slider
          getAriaLabel={() => "Minimum distance shift"}
          value={value2}
          onChange={handleChange2}
          valueLabelDisplay="auto"
          getAriaValueText={valuetext}
          disableSwap
          style={{ color: "#3dbe72", padding: 0, width: "100%" }}
          size="small"
        />
        <MinMaxFieldGroup>
          <TextField
            variant="outlined"
            type="number"
            value={minValue}
            onChange={handleMinChange}
            style={{ marginRight: "10px" }}
            size="small"
            label="min days"
            sx={{
              width: "50%",
              padding: 0,
              margin: 0,
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#3dbe72",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#3dbe72",
              },
            }}
          />
          <SwapHorizIcon style={{ marginRight: 6, color: "#3dbe72" }} />
          <TextField
            variant="outlined"
            type="number"
            label="max days"
            value={maxValue}
            onChange={handleMaxChange}
            size="small"
            sx={{
              width: "50%",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#3dbe72",
                color: "#3dbe72",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                color: "#3dbe72",
                borderColor: "#3dbe72",
              },
            }}
          />
        </MinMaxFieldGroup>
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomSearchMinMax;
