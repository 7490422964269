import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Select,
  Typography,
  IconButton,
  Checkbox,
  FormControlLabel,
  AppBar,
  Toolbar,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  AttachFile,
  Send,
  SaveAlt,
  Inbox,
  Mail as MailIcon,
  Drafts,
} from '@mui/icons-material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { sendEmail } from '../../../../service/api';

const drawerWidth = 240;

const Mail = () => {
  const [from, setFrom] = useState('abcd@kuchbhi.com');
  const [to, setTo] = useState('');
  const [subject, setSubject] = useState('');
  const [editorType, setEditorType] = useState('HTML');
  const [priority, setPriority] = useState('Normal');
  const [returnReceipt, setReturnReceipt] = useState(false);
  const [deliveryStatus, setDeliveryStatus] = useState(false);
  const [keepFormatting, setKeepFormatting] = useState(false);
  const [saveLocation, setSaveLocation] = useState('Sent');
  const [message, setMessage] = useState('');
  const [activeTemplate, setActiveTemplate] = useState('Blank');

  // const handleSend = () => {
  //   // Add logic to send email
  //   console.log('Sending email:', { from, to, subject, editorType, priority, returnReceipt, deliveryStatus, keepFormatting, saveLocation, message });
  // };

  const handleSave = () => {
    // Add logic to save email
    console.log('Saving email:', { from, to, subject, editorType, priority, returnReceipt, deliveryStatus, keepFormatting, saveLocation, message });
  };

  const handleAttachFile = (event) => {
    // Add logic to handle file attachment
    const file = event.target.files[0];
    console.log('Attaching file:', file);
  };

  const handleTemplateChange = (template) => {
    setActiveTemplate(template);
    switch (template) {
      case 'Blank':
        setMessage('');
        break;
      case 'Template 1':
        setMessage('<p>This is Template 1</p>');
        break;
      case 'Template 2':
        setMessage('<p>This is Template 2</p>');
        break;
      case 'Template 3':
        setMessage('<p>This is Template 3</p>');
        break;
      default:
        setMessage('');
    }
  };

  const handleSend = async () => {
    try {
      const response = await sendEmail({
        email: to,
        subject: subject,
        body: message,
      });
      console.log(response);
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };



  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar sx={{
          backgroundColor: '#3DBE72',
        }}>
          <Box display="flex" flexGrow={1} alignItems="center">
            <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
              Compose Email
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" sx={{ mr: 2 }}>
            <Typography variant="body1" sx={{ mr: 2, fontSize: '1.3rem', }}>From</Typography>
            <TextField
              value={from}
              variant="outlined"
              disabled
              onChange={(e) => setFrom(e.target.value)}
              placeholder='From'
              size="small"
              sx={{ "& fieldset": { border: 'none', color: "white" }, width: 200, border: '1px solid white', borderRadius: '5px' }}


            />
          </Box>
          <Box display="flex" alignItems="center" sx={{ mr: 2 }}>
            <Typography variant="body1" sx={{ mr: 2, fontSize: '1.3rem', }}>To</Typography>
            <TextField
              value={to}
              onChange={(e) => setTo(e.target.value)}
              placeholder="Recipient"
              variant="outlined"
              size="small"


              sx={{ "& fieldset": { border: 'none' }, width: 200, border: '1px solid white', borderRadius: '5px' }}
            />
          </Box>
          <Box display="flex" alignItems="center">
            <Typography variant="body1" sx={{ mr: 2, fontSize: '1.3rem', }}>Subject</Typography>
            <TextField
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              placeholder="Subject"
              size="small"
              sx={{ "& fieldset": { border: 'none' }, width: 200, border: '1px solid white', borderRadius: '5px' }}


            />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {['Blank', 'Template 1', 'Template 2', 'Template 3'].map((text) => (
              <ListItem
                button
                key={text}
                selected={activeTemplate === text}
                onClick={() => handleTemplateChange(text)}
              >
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, mt: 8 }}
      >
        <ReactQuill
          value={message}
          onChange={setMessage}
          theme="snow"
          style={{ height: 300 }}
          modules={{
            toolbar: [
              [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
              [{ size: [] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
              ['link', 'image', 'video'],
              ['clean'],
              [{ 'align': [] }],
            ]
          }}
          formats={[
            'header', 'font', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image', 'video', 'align'
          ]}
          placeholder="Compose your email..."
        />
        <Box display="flex" alignItems="center" mt={"3rem"}>
          <Button variant="contained" color="primary" startIcon={<Send />} onClick={handleSend} sx={{ mr: 2 }}>
            Send
          </Button>
          <Button variant="outlined" component="label" startIcon={<AttachFile />}>
            Attach a file
            <input type="file" hidden onChange={handleAttachFile} />
          </Button>
          <Typography variant="body2" sx={{ ml: 2 }}>Maximum allowed file size is 512 MB</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Mail;
