import { Grid, Typography, TextField, InputAdornment, IconButton, styled, Button, Box, Dialog, CircularProgress, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Search, Add, AddTaskOutlined, WarningAmberOutlined } from '@mui/icons-material'
import DataTable from 'react-data-table-component';
import { brands } from './rowsData';
import CloseIcon from '@mui/icons-material/Close';
import facebookIcon from '../../images/facebook.png'
import instaIcon from '../../images/insta-sm.png'
import youtubeIcon from '../../images/youtube-sm.png'

// api 
import { registerBrand } from '../../../service/api';
import { getAllBrands } from '../../../service/getAllApi';

const SearchBar = styled(TextField)(({ theme }) => ({
    //    border : 'none'

    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none', // Remove the outline
    },
    border: '1px solid black',
    borderRadius: 20,




}))

const customStyles = {
    head: {
        style: {
            fontSize: '13px', // Set the desired font size for the header
        },
    },
};


const CustomSocial = ({ row }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', }}>
        <img src={instaIcon} alt="" />
        <img src={youtubeIcon} alt="" width={25} style={{ margin: '0px 5px' }} />
        <img src={facebookIcon} alt="" />
    </Box>
)

const CustomAction = ({ row }) => (
    <span>Actions</span>
)

const columns = [

    {
        name: 'ID',
        selector: row => row.id,
        sortable: true,
        width: '80px',
        // cell: row => <CustomName row={row} />
    },
    {
        name: 'Brand Name',
        selector: row => row.name,
        sortable: true,
        reorder: true,
        // width: '190px',
        // cell: row => <Customcategory row={row} />
    },
    {
        name: 'Total Collection',
        selector: row => 795,
        sortable: true,
        reorder: true,
        // width: '120px',
        // cell : row => <CustomSocial  row={row}/>
    },
    {
        name: 'Total Campaign',
        selector: row => 9,
        sortable: true,
        reorder: true,

        // width: '120px',
    },
    {
        name: 'Active Campaign',
        selector: row => 2,
        sortable: true,
        reorder: true,

        // cell: row => <CustomAverage row={row} />
    },
    {
        name: 'Action',
        // selector: row => row.decided,
        // sortable: true,
        // reorder: true,

        cell: row => <CustomAction row={row} />
    },
].map(column => ({
    ...column,
    style: {
        fontSize: '13px',
    }
}))



const MyInput = styled('input')(`
    background : #dce7f7ff;
    outlined : none;
    border : none;
    width : 100%;
    padding : 15px 10px;
    border-radius : 8px;
    font-size : 15px;
    margin-top : 5px;
`)

const Mylabel = styled('label')(`
    color : #828282ff;
`)

const LoadingSpinner = styled(CircularProgress)(({ theme }) => ({
    color: '#FFFFFF',


}))


const ManageBrands = () => {
    const [data, setData] = useState(brands);
    const [loading, setLoading] = useState(false);
    const [addBrandDialog, setAddBrandDialog] = useState(false);
    const [isSucessful, setIsSuccessful] = useState(false);
    const [error, setError] = useState(
        {
            message: '',
            status: false
        }
    )
    const [brand, setBrand] = useState({
        name: '',
        email: '',
        password: ''
    })

    const handleChange = (e) => {
        setBrand({ ...brand, [e.target.name]: e.target.value })
        console.log(brand);
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError({ message: "", status: false })
        try {
            const response = await registerBrand(brand)
            if (response.data.status === 201) {
                setIsSuccessful(true);
                setError({ message: '', status: false })
            }
        }
        catch (e) {
            setError({ message: "Brand already exists.", status: true })
            console.error("Error while submiting register brand data : " + e)


            setIsSuccessful(false)
        }
        finally {
            setLoading(false);
        }
    }

    const handleClose = () => {
        setError({ message: "", status: false })
        setIsSuccessful(false);
    }



    
    const getBrands = async () => {
        try {
            const response = await getAllBrands();
            console.log(response);
            // dispatch(setInfluencers(response));
            setData(response)
        } catch (error) {
            console.error("Error fetching brands:", error);
            // Handle the error (e.g., display an error message)
        }
    };

    useEffect(() => {
        getBrands();
    }, [])
    return (

        <>
            <Dialog open={addBrandDialog}>
                <Grid container p={3}>
                    <Grid item md={11} xs={11} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '1.2rem', color: '#4caf50' }}>Add Brands</Typography>
                        {
                            error.status &&
                            <Box>
                                <p style={{ color: '#e81e61ff', fontSize: 13 }} >{error.message}</p>
                            </Box>
                        }
                    </Grid>
                    <Grid item md={1} xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title='Close' arrow placement='left'>
                            <IconButton size='small' onClick={() => { setAddBrandDialog(false); handleClose() }}>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <form onSubmit={handleSubmit}>
                            <Box mt={2}>
                                <Mylabel htmlFor="name">Name</Mylabel>
                                <Box>
                                    <MyInput type="text" name="name" id="name" placeholder='Enter Brand name...' onChange={(e) => handleChange(e)} required />
                                </Box>
                            </Box>
                            <Box mt={2}>
                                <Mylabel htmlFor="email">Email</Mylabel>
                                <Box>
                                    <MyInput type="email" name="email" id="email" placeholder="Enter brand's email address..." onChange={(e) => handleChange(e)} required />
                                </Box>
                            </Box>
                            <Box mt={2}>
                                <Mylabel htmlFor="password">Password</Mylabel>
                                <Box>
                                    <MyInput type="password" name="password" id="password" placeholder='Enter Strong Password...' onChange={(e) => handleChange(e)} required />
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 2 }}>
                                <Button type='submit' size='small' variant='contained'
                                    sx={{
                                        bgcolor: error.status ? '#e81e61ff' : '#4cb04fff',
                                        boxShadow: 'none',
                                        fontWeight: 'bold',
                                        // padding: '10px 30px',
                                        '&:hover': {
                                            bgcolor: error.status ? '#e81e61ff' : '#4cb04fff',
                                        }
                                    }}
                                    endIcon={
                                        loading ? (
                                            <LoadingSpinner size={25} />
                                        ) : (
                                            isSucessful ? (
                                                <AddTaskOutlined />
                                            ) : (
                                                error.status && <WarningAmberOutlined />
                                            )
                                        )
                                    }

                                    disabled={loading}
                                >
                                    {
                                        isSucessful ? 'Registered' : 'Register'
                                    }

                                </Button>
                            </Box>
                        </form>


                    </Grid>
                </Grid>
            </Dialog>

            <Grid container sx={{ p: 3 }}>
                <Grid item md={12} xs={12} mb={2}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '1.3rem' }}>Manage Brands</Typography>
                </Grid>
                <Grid item md={8} xs={12} mt={2}>
                    <SearchBar size='small' placeholder='Search by Employees Name...' InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconButton>
                                    <Search />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                        sx={{ width: '60%' }}
                    />
                </Grid>
                <Grid item md={4} xs={12} mt={2}>
                    <Button onClick={() => setAddBrandDialog(true)} startIcon={<Add />} variant='contained' sx={{ borderRadius: 8, bgcolor: '#000000', boxShadow: 'none', textTransform: 'none', '&:hover': { bgcolor: '#000000', boxShadow: 'none' } }}>Add Brand</Button>
                </Grid>
                <Grid item md={12} xs={12}>

                </Grid>
                <Grid item md={12} xs={12} mt={4}>
                    <DataTable
                        title="Brands"
                        columns={columns}
                        data={data}
                        pagination
                        fixedHeader
                        // fixedHeaderScrollHeight="100%"
                        customStyles={customStyles}
                        // onRowClicked={handleRowClick}
                        selectableRows
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default ManageBrands