import { Typography, Grid, Box, Icon, IconButton, Button } from "@mui/material";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
// import { Box } from '@mui/system'

// import DP from '../../../images/dp-lg.png'
// import dp from '../../../images/dp.png'

import TurnRightRoundedIcon from "@mui/icons-material/TurnRightRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const avglikes = "25K";
const avgcomments = "25K";
const er = "25K";

const CollectionAnalytics = ({ heading, data }) => {
  return (
    <Box
      sx={{ padding: { md: "30px", xs: "20px" } }}
      style={{ background: "#f2f7ff", borderRadius: 20, marginTop: 30 }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: { md: "center", xs: "flex-start" },
          marginBottom: "20px",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Typography style={{ fontWeight: "bolder", fontSize: 15 }}>
          {heading}
        </Typography>

        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            size="small"
            variant="contained"
            sx={{ marginTop: { md: 0, xs: 1 } }}
            style={{
              borderRadius: 30,
              background: "none",
              color: "#3DBE72",
              fontWeight: "bold",
              boxShadow: "none",
            }}
          >
            <CloudDownloadOutlinedIcon />
          </Button>
          {/* <Button
            size="small"
            variant="contained"
            sx={{ marginTop: { md: 0, xs: 1 } }}
            style={{
              borderRadius: 30,
              color: "#FFFFFF",
              fontWeight: "bold",
              background: "#3DBE72",
              boxShadow: "none",
            }}
          >
            View Full Report
          </Button> */}
        </Box>
      </Box>

      <Grid
        container
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 15,
        }}
      >
        {data.map((data) => {
          return (
            <Grid
              item
              md={5.8}
              xs={12}
              style={{
                background: "#FFFFFF",
                padding: 15,
                borderRadius: 10,
                marginBottom: 15,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 1,
                }}
              >
                <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                  {data.title}
                </Typography>
                <Box>
                  <span style={{ fontSize: 8 }}>Average</span>
                  <IconButton style={{ color: "#3DBE72" }}>
                    <HelpOutlineRoundedIcon style={{ fontSize: 15 }} />
                  </IconButton>
                </Box>
              </Box>
              <Typography style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                {data.desc}
              </Typography>
            </Grid>
          );
        })}
      </Grid>

      {/* <Grid
        container
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 15,
        }}
      >
        <Grid
          item
          md={5.8}
          xs={12}
          style={{ background: "#FFFFFF", padding: 15, borderRadius: 10 }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
              ER
            </Typography>
            <Box>
              <span style={{ fontSize: 8 }}>Average</span>
              <IconButton style={{ color: "#3DBE72" }}>
                <HelpOutlineRoundedIcon style={{ fontSize: 15 }} />
              </IconButton>
            </Box>
          </Box>
          <Typography style={{ fontWeight: "bold", fontSize: "1.7rem" }}>
            {er ? er : "0.12"}%
          </Typography>
        </Grid>

        <Grid
          item
          md={5.8}
          xs={12}
          sx={{ marginTop: { xs: 2, md: 0 } }}
          style={{ background: "#FFFFFF", padding: 15, borderRadius: 10 }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
              Suggested Price
            </Typography>
            <IconButton style={{ color: "#3DBE72" }}>
              <HelpOutlineRoundedIcon style={{ fontSize: 15 }} />
            </IconButton>
          </Box>
          <Typography style={{ fontWeight: "bold", fontSize: "1.7rem" }}>
            $1.5K - $3k
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 15,
        }}
      >
        <Grid
          item
          md={5.8}
          xs={12}
          style={{ background: "#FFFFFF", padding: 15, borderRadius: 10 }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
              Average Likes
            </Typography>
            <IconButton style={{ color: "#3DBE72" }}>
              <HelpOutlineRoundedIcon style={{ fontSize: 15 }} />
            </IconButton>
          </Box>
          <Typography style={{ fontWeight: "bold", fontSize: "1.7rem" }}>
            {avglikes ? avglikes : "266"}
          </Typography>
        </Grid>

        <Grid
          item
          md={5.8}
          xs={12}
          sx={{ marginTop: { xs: 2, md: 0 } }}
          style={{ background: "#FFFFFF", padding: 15, borderRadius: 10 }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
              Average Comments
            </Typography>
            <IconButton style={{ color: "#3DBE72" }}>
              <HelpOutlineRoundedIcon style={{ fontSize: 15 }} />
            </IconButton>
          </Box>
          <Typography style={{ fontWeight: "bold", fontSize: "1.7rem" }}>
            {avgcomments ? avgcomments : 156}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 15,
        }}
      >
        <Grid
          item
          md={5.8}
          xs={12}
          style={{ background: "#FFFFFF", padding: 15, borderRadius: 10 }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
              Brand Mentions
            </Typography>
            <IconButton style={{ color: "#3DBE72" }}>
              <HelpOutlineRoundedIcon style={{ fontSize: 15 }} />
            </IconButton>
          </Box>
          <Typography>
            {" "}
            <span style={{ fontWeight: "bold", fontSize: "1.7rem" }}>
              O
            </span>{" "}
            <span>in last 180d</span>
          </Typography>
        </Grid>

        <Grid
          item
          md={5.8}
          xs={12}
          sx={{ marginTop: { xs: 2, md: 0 } }}
          style={{ background: "#FFFFFF", padding: 15, borderRadius: 10 }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
              Yearly Growth
            </Typography>
            <Box>
              <span style={{ fontSize: 8 }}>Low</span>
              <IconButton style={{ color: "#3DBE72" }}>
                <HelpOutlineRoundedIcon style={{ fontSize: 15 }} />
              </IconButton>
            </Box>
          </Box>
          <Typography style={{ fontWeight: "bold", fontSize: "1.7rem" }}>
            -0.8%
          </Typography>
        </Grid>
      </Grid> */}
    </Box>
  );
};

export default CollectionAnalytics;
