import React, { useEffect, useState } from "react";
import {
  FormControl,
  Divider,
  Select,
  MenuItem,
  Slider,
  TextField,
  Box,
  styled,
  ListItemButton,
  ListItemText,
  Menu,
  IconButton,
  Tooltip,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/Check";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";

const KeywordSearch = ({
  text,
  options,
  setKeywordFilterState,
  clear,
  tooltipInfo,
}) => {
  const [value, setValue] = useState(options[0]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [textFieldValue, setTextFieldValue] = useState("");

  const keywordsArr = [];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (option) => {
    setValue(option);
    keywordsArr.push(option);
    setAnchorEl(null);
    setKeywordFilterState(keywordsArr);
  };

  useEffect(() => {
    if (clear) {
      setValue(options[0]);
      setTextFieldValue("");
    }
  }, [clear]);

  return (
    <Accordion sx={{ width: { md: "80%", xs: "90%" }, boxShadow: "none" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: 1,
          }}
        >
          <IconButton style={{ color: "#3DBE72" }}>
            <Tooltip title={tooltipInfo}>
              <HelpOutlineRoundedIcon
                style={{ fontSize: 12, padding: 0, margin: 0 }}
              />
            </Tooltip>
          </IconButton>
          <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
        </Box>
      </AccordionSummary>

      <AccordionDetails sx={{ textAlign: "start", paddingTop: 0 }}>
        <Box>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            Keywords{" "}
            <span style={{ fontWeight: "normal", color: "#5296dd" }}>
              <ListItemButton onClick={handleClick}>{value}</ListItemButton>
            </span>
          </Typography>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {options.map((option) => (
              <MenuItem key={option} onClick={() => handleSelect(option)}>
                {option}
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <TextField
          value={textFieldValue}
          onChange={(event) => {
            setTextFieldValue(event.target.value);
            keywordsArr.push(event.target.value);
          }}
          label="Enter keyword.."
          sx={{
            width: "100%",
            marginTop: 2,
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default KeywordSearch;
