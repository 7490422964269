import React, { useEffect, useState } from 'react'
import { Box, Button, TextField, Typography, styled, Grid, Badge, Divider, Avatar, IconButton } from '@mui/material'
import { AdsClick } from '@mui/icons-material'
import { useMediaQuery } from '@material-ui/core';
import { Settings, ManageAccounts, Verified } from '@mui/icons-material'
import Logo from '../images/logo_CreatorHunt-white.png'
import insta from '../images/insta-sm.png'
import youtube from '../images/youtube-sm.png'
import DP from '../images/dp.png'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import MailIcon from '@mui/icons-material/Mail';
//AXIOS API :
import { genConfirmInstaOTP } from '../../service/api';
import { genConfirmYTOTP } from '../../service/genOtpApi';
import QRCode from "react-qr-code";

const Container = styled(Box)(({ theme }) => ({

    display: 'flex',
    // height : '100%',

    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '2rem',
    marginBottom: '3rem',
    // justifyContent : 'center'   ,

    [theme.breakpoints.down('sm')]: {
        // paddingLeft: '1rem'
        marginTop: '0rem',

    }


}))

const Subcontainer = styled(Box)(({ theme }) => ({

    width: '40%',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: '1.4rem'
    }


}))

const QRBox = styled(Box)(({ theme }) => ({

    width: '120px',
    marginBottom: 5,
    [theme.breakpoints.down('sm')]: {
        width: '120px'
    }


}))

const SecondSubContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    background: '#DDE8F8',
    alignItems: 'center',
    padding: 20,
    borderRadius: 5,
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        marginTop: 15
    }

}))

const Influencer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px',
    borderRadius: 5,

    margin: '2rem 0px',
    [theme.breakpoints.down('sm')]: {
        padding: 0
    }


}))

const InfluencerTitle = styled(Box)(({ theme }) => ({
    marginRight: '5rem',
    [
        theme.breakpoints.down('sm')
    ]: {
        marginRight: 15
    }
}))

const MyButton = styled(Button)(({ theme }) => ({

    color: 'Black',
    width: '100%',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: '13px',
    // background: '#3DBE7233',
    // border: '1px solid #3DBE72',
    boxShadow: 'none',
    marginRight: 10,

    '&:hover': {
        // background: '#3DBE7233',
        border: '1px solid #3DBE72',
        boxShadow: 'none',
    },
    [theme.breakpoints.down('sm')]: {
        width: 'auto',
    }

}))

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid white`,
    background: 'white'
}));



const InstagramConfirm = () => {

    // this is for insta 
    const [isTimeUp, setIsTimeUp] = useState({
        status: false,
        message: "The opt is valid for a limited period only"
    })

    const [instaOTPcountdown, setInstaOtpCountdown] = useState(120); // 2 minutes in seconds

    // this is for youtube 

    const [isYtTimeUp, setIsYtTimeUp] = useState({
        status: false,
        message: "The opt is valid for a limited period only"
    })


    const [YtOTPcountdown, setYtOtpCountdown] = useState(300); // 2 minutes in seconds

    // Use the useMediaQuery hook to check for screen size

    const isSmallScreen = useMediaQuery('(max-width:600px)');


    const [isICopy, setIsICopy] = useState(false);
    const [isYCopy, setIsYCopy] = useState(false);


    const Inumber = null;
    const Ynumber = null;
    const [instaOTP, setInstaOTP] = useState(Inumber)
    const [YoutubeOTP, setYoutubeOTP] = useState(Ynumber);



    const InstaDigits = Array.from(String(instaOTP, ''), Number);
    const YoutubeDigits = Array.from(String(YoutubeOTP, ''), Number);



    const handleInstaCopy = async () => {
        try {
            await navigator.clipboard.writeText(instaOTP);
            setIsICopy(true);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    const handleYoutubeCopy = async () => {
        try {
            await navigator.clipboard.writeText(YoutubeOTP);
            setIsYCopy(true);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };




    // Generate opt for insta comments : 
    const genInstaOTP = async () => {
        const user_id = {
            id: 1
        };
        const res = await genConfirmInstaOTP(user_id);
        console.log(res);
        const OTP = res.data.user.db_insta_comments;
        console.log(OTP);
        setInstaOTP(OTP);
        setIsTimeUp({ status: false, message: "The opt is valid for a limited period only" })
        setInstaOtpCountdown(120); // Reset the countdown timer

    }

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    useEffect(() => {
        setIsTimeUp({ status: false, message: "The opt is valid for a limited period only" })
        const timer = setInterval(() => {
            if (instaOTPcountdown > 0) {
                setInstaOtpCountdown(instaOTPcountdown - 1);
            } else {
                clearInterval(timer);
                // Handle countdown completion if needed
                setInstaOTP(null)
                setIsTimeUp({ status: true, message: "TIME OUT" })
            }
        }, 1000);

        return () => clearInterval(timer);
        setInstaOTP(null); // Reset the OTP value in the cleanup function

    }, [instaOTPcountdown]);



    // Generate opt for Youtube verification : 

    const genYtOTP = async () => {
        const user_id = {
            id: 1
        };
        const res = await genConfirmYTOTP(user_id);
        console.log(res);
        const OTP = res.data.user.db_yt_comment;
        console.log(OTP);
        setYoutubeOTP(OTP);
        setIsYtTimeUp({ status: false, message: "The opt is valid for a limited period only" })
        setYtOtpCountdown(300); // Reset the countdown timer

    }


    useEffect(() => {
        setIsYtTimeUp({ status: false, message: "The opt is valid for a limited period only" })
        const timer = setInterval(() => {
            if (YtOTPcountdown > 0) {
                setYtOtpCountdown(YtOTPcountdown - 1);
            } else {
                clearInterval(timer);
                // Handle countdown completion if needed
                setYoutubeOTP(null)
                setIsYtTimeUp({ status: true, message: "TIME OUT" })
            }
        }, 1000);

        return () => clearInterval(timer);
        setYoutubeOTP(null); // Reset the OTP value in the cleanup function

    }, [YtOTPcountdown]);




    useEffect(() => {
        // for instagram 
        genInstaOTP();
        setIsTimeUp({ status: false, message: "The opt is valid for a limited period only" })

        // for youtube 
        genYtOTP();
        setIsYtTimeUp({ status: false, message: "The opt is valid for a limited period only" })

    }, [])


    return (
        <Container >
            <Subcontainer>
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={Logo} alt="" width={170} />
                </Box>

                <Typography style={{ fontWeight: 'bold', fontSize: '1.2rem', textAlign: 'center' }}>Confirm Your Social Media Account</Typography>


                <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                    <Grid item md={12} xs={12} sx={{ mt: 3, mb: 3 }}>
                        <Grid container >
                            <Grid item md={2} xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }} >
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <SmallAvatar alt="Remy Sharp" src={insta} />
                                    }
                                >
                                    <Avatar alt="influencer profile pic" src={DP} sx={{ height: 60, width: 60 }} />
                                </Badge>
                            </Grid>
                            <Grid item md={6} xs={7} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center' }}>
                                <Typography style={{ fontWeight: 'bold', fontSize: '1rem' }}>Abhishek Malviya</Typography>
                                <Typography style={{ color: '#00000080', fontSize: '0.8rem' }}>@abhishek.malviya20</Typography>

                            </Grid>
                            <Grid item md={4} xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {
                                    isSmallScreen
                                        ?

                                        <IconButton size='small'>
                                            <ManageAccounts sx={{ color: '#3DBE72' }} />
                                        </IconButton>
                                        :
                                        <MyButton startIcon={<ManageAccounts />} variant='text' size='small' style={{ textTransform: 'capitalize', color: '#3DBE72' }}>Change account</MyButton>

                                }

                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item md={12} xs={12} sx={{ mb: 2 }}>
                        <Divider />
                    </Grid>

                </Grid>

                <Grid container>
                    <Grid item md={12} xs={12}>
                        <Grid container>
                            <Grid item md={12} sx={{ display: 'flex', alignItems: 'center', marginTop: 1, marginBottom: 3 }}>
                                <img src={insta} alt="" />
                                <Typography sx={{ fontSize: '1.2rem', marginLeft: .5 }}>Confirm Your Instagram</Typography>
                            </Grid>
                            <Grid item md={6}>
                                <Typography sx={{ fontSize: '.8rem' }}>1. Remember or copy this 4-digit confirmation code to your clipboard</Typography>

                                <Box style={{ display: 'flex', flexDirection: 'column', margin: '10px 0px' }}>

                                    {
                                        instaOTP !== null &&
                                        <Box mt={1}>
                                            {
                                                InstaDigits?.map((value, index) => (
                                                    <span key={index} style={{
                                                        border: '1px solid #ccc',
                                                        borderRadius: '4px',
                                                        padding: '6px 12px',
                                                        marginRight: '5px',
                                                        width: '40px',
                                                        textAlign: 'center',
                                                        background: 'rgba(0, 255, 105, 0.2)',
                                                    }}>{value}</span>
                                                ))
                                            }


                                        </Box>




                                    }


                                    <Box mt={2}>
                                        <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold', color: isTimeUp.status ? '#e91e63' : '#3dbf73ff' }}>
                                            {`${isTimeUp.message} : ${formatTime(instaOTPcountdown)}`}
                                        </Typography>


                                        {
                                            isTimeUp.status &&
                                            <Button variant='text'
                                                sx={{ textTransform: 'none', padding: 0, color: '#3dbf73ff', fontWeight: 'bold' }}

                                                endIcon={<AdsClick />}
                                                onClick={() => genInstaOTP()}
                                            >
                                                Generate OTP
                                            </Button>
                                        }


                                    </Box>



                                    <Box mt={2}>
                                        <Button onClick={() => handleInstaCopy()} size='small' style={{ color: '#3DBE72', textTransform: 'capitalize' }} variant='text' startIcon={isICopy ? <InventoryOutlinedIcon /> : <ContentPasteOutlinedIcon />} > {isICopy ? 'Copied' : 'Copy to ClipBoard'}</Button>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={6} sx={{ marginTop: { md: 0, xs: 4 } }}>
                                <Grid container>

                                    <Grid item md={5} xs={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', bgcolor: '#dce7f7ff', padding: 2, borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}>
                                        <QRBox>
                                            <QRCode value={'https://www.instagram.com/p/C19HYwxy--Z/'} size={256}
                                                style={{ height: "auto", maxWidth: "100%", width: "100%" }} />
                                        </QRBox>
                                    </Grid>
                                    <Grid item md={7} xs={7} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: '#dce7f7ff', borderBottomRightRadius: 8, borderTopRightRadius: 8 }}>
                                        <Typography sx={{ fontSize: '.8rem', marginLeft: { md: 1, xs: 0 }, }}>Open Instagram post by clicking the link below and leave comment with 4-digit confirmation
                                            code to it from <span style={{ display: 'flex', alignItems: 'center' }}>@zamanastiva <Verified fontSize='.8rem' sx={{ color: '#0093f5ff' }} /></span> </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item mt={2} md={12} xs={12} align='end'>
                                <Button size='small' variant='contained'
                                    sx={{
                                        boxShadow: 'none',
                                        fontWeight: 'bold',
                                        bgcolor: '#4caf50',
                                        padding: '5px 15px',
                                        '&:hover': { bgcolor: '#4caf50', }
                                    }}
                                >Verify</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={12} xs={12} mt={3} mb={3}>
                        <Divider />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Grid container>
                            <Grid item md={12} sx={{ display: 'flex', alignItems: 'center', marginTop: 1, marginBottom: 3 }}>
                                <img src={youtube} alt="" width={24} />
                                <Typography sx={{ fontSize: '1.2rem', marginLeft: .5 }}>Confirm Your Youtube </Typography>
                            </Grid>
                            <Grid item md={6}>
                                <Typography sx={{ fontSize: '.9rem', }}>1. Remember or copy this 4-digit confirmation code to your clipboard</Typography>

                                <Box style={{ display: 'flex', flexDirection: 'column', margin: '10px 0px' }}>

                                    {
                                        YoutubeOTP !== null
                                        &&
                                        <Box mt={1}>
                                            {
                                                YoutubeDigits?.map((value, index) => (
                                                    <span key={index} style={{
                                                        border: '1px solid #ccc',
                                                        borderRadius: '4px',
                                                        padding: '6px 12px',
                                                        marginRight: '5px',
                                                        width: '40px',
                                                        textAlign: 'center',
                                                        background: 'rgba(0, 255, 105, 0.2)',
                                                    }}>{value}</span>
                                                ))
                                            }


                                        </Box>
                                    }



                                    <Box mt={2}>
                                        <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold', color: isYtTimeUp.status ? '#e91e63' : '#3dbf73ff' }}>
                                            {`${isYtTimeUp.message} : ${formatTime(YtOTPcountdown)}`}
                                        </Typography>


                                        {
                                            isYtTimeUp.status &&
                                            <Button variant='text'
                                                sx={{ textTransform: 'none', padding: 0, color: '#3dbf73ff', fontWeight: 'bold' }}

                                                endIcon={<AdsClick />}
                                                onClick={() => genYtOTP()}
                                            >
                                                Generate OTP
                                            </Button>
                                        }


                                    </Box>


                                    <Box mt={2}>
                                        <Button onClick={() => handleYoutubeCopy()} size='small' style={{ color: '#3DBE72', textTransform: 'capitalize' }} variant='text' startIcon={isYCopy ? <InventoryOutlinedIcon /> : <ContentPasteOutlinedIcon />} > {isYCopy ? 'Copied' : 'Copy to ClipBoard'}</Button>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={6} sx={{ marginTop: { md: 0, xs: 4 } }}>
                                <Grid container>

                                    <Grid item md={5} xs={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', bgcolor: '#dce7f7ff', padding: 2, borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}>
                                        <QRBox>
                                            <QRCode value={'https://www.instagram.com/'} size={256}
                                                style={{ height: "auto", maxWidth: "100%", width: "100%" }} />
                                        </QRBox>
                                    </Grid>
                                    <Grid item md={7} xs={7} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: '#dce7f7ff', borderBottomRightRadius: 8, borderTopRightRadius: 8 }}>
                                        <Typography sx={{ fontSize: '.8rem', marginLeft: { md: 1, xs: 0 }, }}>Open Instagram post by clicking the link below and leave comment with 4-digit confirmation
                                            code to it from <span>@abhishek._.malviya</span></Typography>
                                    </Grid>
                                    <Grid item mt={2} md={12} xs={12} align='end'>
                                        <Button size='small' variant='contained'
                                            sx={{
                                                boxShadow: 'none',
                                                fontWeight: 'bold',
                                                bgcolor: '#4caf50',
                                                padding: '5px 15px',
                                                '&:hover': { bgcolor: '#4caf50', }
                                            }}
                                        >Verify</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>




            </Subcontainer>
        </Container>
    )
}

export default InstagramConfirm