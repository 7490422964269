import React from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const SelectComp = () => {
    const [action, setAction] = React.useState('');

    const handleChange = (event) => {
        setAction(event.target.value);
    };
  

    return (
        <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
                value={action}
                size='small'
                onChange={handleChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{borderRadius : 20}}
            >
                <MenuItem value="">
                    <em>Select</em>
                </MenuItem>
                <MenuItem value={10}>Action I</MenuItem>
                <MenuItem value={20}>Action II</MenuItem>
                <MenuItem value={30}>ACtion III</MenuItem>
            </Select>
        </FormControl>
    )
}

export default SelectComp