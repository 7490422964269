import React from 'react'
import { Box, Typography, styled } from '@mui/material'

const Container = styled(Typography)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '4rem',
  marginBottom: '4rem',
  padding: 10,
  [theme.breakpoints.down('sm')]: {
    marginTop: '2rem',

  }
}))

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1.7rem',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.4rem',
  }
}))

const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  maxWidth: "fit-content",
  color: '#A7A7A7',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.1rem',
  }
}))


const SpanCSS = styled('span')`
background-color : #3DBE72;
color : #FFFFFF;
padding : 0px 5px;
`

const FIHeader = () => {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '4rem',
    }}>
      <Title sx={{
        width: 'auto',
        mb: '1rem'
      }}>Find the <SpanCSS>Ideal Influencers</SpanCSS> in your niche</Title>

      <SubTitle>Discover influencers according to your requirements from our expansive database of influencers
      </SubTitle>
    </Box>
  )
}

export default FIHeader