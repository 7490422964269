import React, { useEffect, useRef, useState } from 'react';

// REDUX TOOLKIT 
import { UseDispatch } from 'react-redux';
import { setUser } from '../../features/user/userSlice';
import { setIsAuthorized } from '../../features/authorization/AuthorizationSlice'

import { Box, Typography, TextField, Button, Divider, InputAdornment, IconButton, CircularProgress } from "@mui/material"
import { ReportProblem, RemoveRedEyeRounded, VisibilityOffRounded } from '@mui/icons-material'
import { styled } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import { loginUser } from '../../service/api';
//images

import Logo from "../images/logo_CreatorHunt.png"
import Logo2 from "../images/logo_CreatorHunt-white.png"
import Prancheta from "../images/Prancheta.png"
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

//css
const Container = styled(Box)(({ theme }) => ({

    width: '100%',
    // height:' 100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
        display: 'block',
    },
    [theme.breakpoints.down('md')]: {
        display: 'block'
    }

}))

const LeftBox = styled(Box)(({ theme }) => ({
    width: '50%',
    minHeight: '100vh',
    backgroundColor: '#1F2933',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',

    [theme.breakpoints.down('sm')]: {
        display: 'none'
    },
    [theme.breakpoints.down('md')]: {
        display: 'none',
        width: '100%',
    },
    [theme.breakpoints.down('lg')]: {
        // width : '90%',

    }


}))



const Header = styled(Typography)(({ theme }) => ({
    fontSize: '1.6rem',
    fontWeight: 700
}))

const Title = styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    fontSize: '12px'
}))

const MyLogo = styled('img')({
    width: '120px',
    height: 'auto',
    marginTop: '20px'
});

const Contant = styled(Box)`
color: #FFF;
width: 85%;
`

const ImgBox = styled(Box)`
    max-width:60%;
    display: flex;  
    justify-content: center;
`

//RIGHT BOX
const RightBox = styled(Box)(({ theme }) => ({
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
        width: '100%',

    },

    [theme.breakpoints.down('md')]: {
        width: '100%',
    },
    [theme.breakpoints.down('lg')]: {
        width: '100%',

    }

}))

const RightChild = styled(Box)(({ theme }) => ({
    width: '60%',

    [theme.breakpoints.down('sm')]: {
        width: '90%',

    },
    [theme.breakpoints.down('lg')]: {
        width: '90%',

    }




}))

const InputBox = styled(Box)`
    margin : 20px 0px;
`

const Label = styled('label')({

    fontSize: '10px',
    fontWeight: 400,
    // color : 'grey'

})

const InputField = styled(TextField)(({ theme }) => ({
    width: '100%',
    paddingTop: '5px',
    borderRadius: '5px',
    fontSize: 10,
    '& .MuiOutlinedInput-root': {
        '&:not(.Mui-focused)': {
            borderColor: 'initial', // Reset default border color
        },
        '&.Mui-focused': {
            borderColor: 'green', // Red border color when focused
            borderWidth: '5px'
        },
    },
}))

const RightHeader = styled(Box)`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
margin : 30px;
font-weight : 400;
`

const RightSubtitle = styled('p')`
font-size : 16px;
`

const LoginButton = styled(Button)(({ theme }) => ({
    width: "100%",
    background: "#3DBE72",
    padding: 10,

    '&:hover': {
        background: "#3DBE72",
    }
}))

const ToSignupBtn = styled(Link)(({ theme }) => ({
    textTransform: "lowercase",
    color: "#3DBE72",
    cursor: 'pointer',
    textDecoration: "none"

}))

const Line = styled(Divider)`
    margin : 30px 0px 10px 0px;

`

const Footer = styled(Box)`
    display : flex;
    align-items: center;
    justify-content: space-between;
 
    
    
`

const FooterContent = styled(Typography)`
    font-size : 15px;

`

const LoadingSpinner = styled(CircularProgress)(({ theme }) => ({
    color: 'white',
    marginRight: '8px', // Some spacing between spinner and text
}));



const Login = () => {

    const [errors, setErrors] = useState({
        email: "",
    });
    const [logStatus, setLogStatus] = useState("");

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const [status, setStatus] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // Create refs for each input field
    const email = useRef(null);
    const password = useRef(null);




    const handleInputChange = (event) => {
        const { name, value } = event.target;

        // Validate input and update error message accordingly
        let errorMessage = "";

        if (name === 'email') {

            const isValidEmail = (email) => {
                // Regular expression for basic email validation
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                return emailRegex.test(email);
            };


            if (isValidEmail(value) || value == "") {
                errorMessage = "";
            } else {
                errorMessage = (
                    <div>Invalid email address.</div>
                )
            }
        }

        // Add more validation logic for other fields if needed

        // Update the errors state
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: errorMessage
        }));

    };


    const handleHideAndShowPassword = () => {
        setShow((x) => x == true ? false : true);
    }


    // Event handler for form submission
    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoading(true);

        const user = {
            'email': email.current.value,
            'password': password.current.value
        }

        try {
            const response = await loginUser(user);
            console.log(response);

            if (response?.data.status === 201) {
                dispatch(setUser(response?.data.user.id));
                dispatch(setIsAuthorized(true))
                navigate('/dashboard/home');
            }
            else if (response.data.status === 401) {
                setLogStatus(response?.data.errorMessage)
            }

        }
        catch (error) {
            console.error("error while logging user : ", error);
        }
        finally {
            setLoading(false)

        }




    };
    const saveToLocalStorage = (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    };




    const CurrUser = useSelector((state) => state.user.user);

    useEffect(() => {
        if (CurrUser) {

            saveToLocalStorage('currUser', CurrUser);
            console.log("CurrUser saved to local storage:", CurrUser);
        }
    }, [CurrUser]);

    useEffect(() => {
        localStorage.removeItem("authToken")
    }, [])

    return (
        <Container >

            <LeftBox>
                <Contant >
                    <MyLogo src={Logo} alt="" />

                    <Header variant="h3" >Complete solutions to execute seamless influencer marketing campaigns.
                    </Header>
                    <br />
                    <Title >You can seamlessly oversee the entire campaign process from start to finish with our campaign
                        management tool. We prioritize making the process smooth and efficient, ensuring that you achieve
                        your desired results effortlessly.
                    </Title>
                </Contant>

                <ImgBox >
                    <img src={Prancheta} alt="" width="100%" />
                </ImgBox>
            </LeftBox>

            {/* *********RIGHT SIDE*********** */}
            <RightBox>
                <RightChild>

                    <RightHeader>
                        <img src={Logo2} alt="" width="140px" />
                        <RightSubtitle>Login To  Your Account</RightSubtitle>
                        {
                            logStatus &&
                            <Box mt={1}>

                                <div style={{ display: 'flex', alignItem: 'center', fontSize: '.7rem', color: '#e91e63', marginTop: 1 }}>  <ReportProblem sx={{ fontSize: 15, color: '#e91e63', marginRight: 1 }} />{logStatus}  </div>
                            </Box>
                        }
                    </RightHeader>

                    <form action="" onSubmit={handleSubmit}>
                        <Box>
                            <Label htmlFor="email" style={{ fontSize: 12 }}>Enter Your Bussiness E-Mail</Label>
                            <InputField
                                id="email"
                                // className={styles.inputField}
                                type="email"
                                size="small"
                                name="email"
                                placeholder="Abc@CompanyName.com"
                                inputRef={email}
                                autoComplete='email'
                                required
                                onChange={handleInputChange}
                                sx={{
                                    // Adjust the value as needed for your desired border radius

                                    '& .MuiOutlinedInput-root': {
                                        // Adjust the value as needed for your desired border radius
                                        paddingRight: 0.4,
                                        '&:hover fieldset': {
                                            borderColor: errors.email ? '#e91e63' : '#3dbf73ff', // Set the border color for hover state
                                            boxShadow: errors.email ? '0 0 10px rgba(233, 30, 99, 0.7)' : '0 0 10px rgba(61, 191, 115, .5)'

                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: errors.email ? '#e91e63' : '#3dbf73ff', // Set the border color for hover state
                                            boxShadow: errors.email ? '0 0 10px rgba(233, 30, 99, 0.7)' : '0 0 10px rgba(61, 191, 115, .5)'
                                        },
                                    },
                                }}
                            />
                            {
                                errors.email &&
                                <Box>

                                    <Typography sx={{ display: 'flex', alignItem: 'center', fontSize: '.7rem', color: '#e91e63', marginTop: 1 }}>  <ReportProblem sx={{ fontSize: 15, color: '#e91e63', marginRight: 1 }} />{errors.email}  </Typography>
                                </Box>
                            }
                        </Box>

                        <InputBox>
                            <Label htmlFor="password" style={{ fontSize: 12 }}>Enter Password</Label>
                            <InputField
                                id="password"
                                // className={styles.inputField}
                                type={show ? 'text' : 'password'}
                                size="small"
                                placeholder="Enter Your Password"
                                inputRef={password}
                                required

                                sx={{
                                    // Adjust the value as needed for your desired border radius

                                    '& .MuiOutlinedInput-root': {
                                        // Adjust the value as needed for your desired border radius
                                        paddingRight: 0.4,
                                        '&:hover fieldset': {
                                            borderColor: '#3dbf73ff', // Set the border color for hover state
                                            boxShadow: '0 0 10px rgba(61, 191, 115, .5)'
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#3dbf73ff', // Set the border color for focused state
                                            boxShadow: '0 0 10px rgba(61, 191, 115, .5)'
                                        },
                                    },
                                }}

                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton size="small" onClick={handleHideAndShowPassword}>
                                                {
                                                    show
                                                        ?
                                                        <VisibilityOffRounded

                                                            style={{
                                                                color: '#3dbf73ff'
                                                            }}

                                                        />
                                                        :
                                                        <RemoveRedEyeRounded

                                                            style={{
                                                                color: '#3dbf73ff'
                                                            }}

                                                        />
                                                }

                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </InputBox>

                        <LoginButton variant="contained" type='submit' disabled={loading} startIcon={loading && <LoadingSpinner size={25} />}>
                            {
                                loading ? 'Logging in...' : 'Login'
                            }


                        </LoginButton>
                    </form>
                    <Line />
                    <Footer >
                        <FooterContent>or don&rsquo;t have an account ?</FooterContent>
                        <ToSignupBtn to="/signup" variant="text">Sign up</ToSignupBtn>
                    </Footer>
                </RightChild>

            </RightBox>

        </Container>
    )
}

export default Login