import React, { useEffect, useState } from "react";
import {
  FormControl,
  Divider,
  Select,
  MenuItem,
  Slider,
  TextField,
  Box,
  styled,
  IconButton,
  Tooltip,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Avatar from "@mui/material/Avatar";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";

const SearchDropdownFilter2 = ({
  text,
  options,
  setDropdownFilter2State,
  setInfluencersLookalikeState,
  setAudienceLookalikeState,
  clear,
  tooltipInfo,
}) => {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (clear) setValue(null);
  }, [clear]);

  if (setInfluencersLookalikeState) setInfluencersLookalikeState(value);
  else setAudienceLookalikeState(value);

  return (
    <Accordion sx={{ width: { md: "80%", xs: "90%" }, boxShadow: "none" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: 1,
          }}
        >
          <IconButton style={{ color: "#3DBE72" }}>
            <Tooltip title={tooltipInfo}>
              <HelpOutlineRoundedIcon
                style={{ fontSize: 12, padding: 0, margin: 0 }}
              />
            </Tooltip>
          </IconButton>
          <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
        </Box>
      </AccordionSummary>

      <AccordionDetails sx={{ textAlign: "start" }}>
        <Autocomplete
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue ? newValue.username : "");
            setInputValue(newValue ? newValue.username : "");
            setDropdownFilter2State(event.target.value);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          id="controllable-states-demo"
          options={options}
          sx={{
            width: "100%",
            "& .MuiInputLabel-root": {
              color: "#3dbe72",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#3dbe72",
              },
              "&:hover fieldset": {
                borderColor: "#3dbe72",
              },
            },
            "& .MuiInputBase-input": {
              color: "#3dbe72",
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={text}
              sx={{
                "&.MuiInputLabel-root": {
                  color: "#3dbe72",
                },
                "&.MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#3dbe72",
                  },
                  "&:hover fieldset": {
                    borderColor: "#3dbe72",
                  },
                },
                "&.MuiInputBase-input": {
                  color: "#3dbe72",
                },
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              <Avatar src={option.avatar} sx={{ mr: 2 }} />
              {option.username}
            </li>
          )}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default SearchDropdownFilter2;
