const brands = [
    {
      id: 1,
      name: 'MasterCard',
      totalcollection: 1747,
      totalcampaign: 18,
      activecampaign: 6
    },
    {
      id: 2,
      name: 'Visa',
      totalcollection: 1325,
      totalcampaign: 15,
      activecampaign: 4
    },
    {
      id: 3,
      name: 'American Express',
      totalcollection: 986,
      totalcampaign: 12,
      activecampaign: 3
    },
    {
      id: 4,
      name: 'Discover',
      totalcollection: 754,
      totalcampaign: 10,
      activecampaign: 2
    },
    {
      id: 5,
      name: 'PayPal',
      totalcollection: 1201,
      totalcampaign: 14,
      activecampaign: 5
    },
    {
      id: 6,
      name: 'Apple Pay',
      totalcollection: 865,
      totalcampaign: 11,
      activecampaign: 3
    },
    {
      id: 7,
      name: 'Samsung Pay',
      totalcollection: 642,
      totalcampaign: 9,
      activecampaign: 2
    },
    {
      id: 8,
      name: 'Google Pay',
      totalcollection: 1189,
      totalcampaign: 13,
      activecampaign: 4
    },
    {
      id: 9,
      name: 'Square',
      totalcollection: 503,
      totalcampaign: 8,
      activecampaign: 1
    },
    {
      id: 10,
      name: 'Chase Pay',
      totalcollection: 879,
      totalcampaign: 12,
      activecampaign: 3
    },
    {
        id: 11,
        name: 'Capital One',
        totalcollection: 1236,
        totalcampaign: 14,
        activecampaign: 5
      },
      {
        id: 12,
        name: 'Wells Fargo',
        totalcollection: 1054,
        totalcampaign: 12,
        activecampaign: 4
      },
      {
        id: 13,
        name: 'Bank of America',
        totalcollection: 1498,
        totalcampaign: 16,
        activecampaign: 7
      },
      {
        id: 14,
        name: 'Citi',
        totalcollection: 862,
        totalcampaign: 10,
        activecampaign: 3
      },
      {
        id: 15,
        name: 'JP Morgan Chase',
        totalcollection: 1367,
        totalcampaign: 15,
        activecampaign: 6
      },
      {
        id: 16,
        name: 'Barclays',
        totalcollection: 927,
        totalcampaign: 11,
        activecampaign: 4
      },
      {
        id: 17,
        name: 'TD Bank',
        totalcollection: 734,
        totalcampaign: 9,
        activecampaign: 2
      },
      {
        id: 18,
        name: 'HSBC',
        totalcollection: 1105,
        totalcampaign: 13,
        activecampaign: 5
      },
      {
        id: 19,
        name: 'SunTrust',
        totalcollection: 589,
        totalcampaign: 8,
        activecampaign: 2
      },
      {
        id: 20,
        name: 'PNC',
        totalcollection: 978,
        totalcampaign: 11,
        activecampaign: 4
      }
  ];
  

export { brands }