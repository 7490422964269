import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Box,
  Icon,
  IconButton,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Stack,
  styled,
} from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import AccordionActions from "@mui/material/AccordionActions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { Box } from '@mui/system'
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import AccountAnalytics from "./AccountAnalytics";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Instagram, YouTube } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import OverViewChart from "./OverViewChart";
import ContentChart from "./ContentChart";
import AudienceChart from "./AudienceChart";
import LookalikeChart from "./LookalikeChart";
import CustomCarousel1 from "./CustomCarousel1";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts/PieChart";
import InfluencersDataTable from "./InfluencersDataTable";
import { getAllInfluencers } from "../../../../service/getAllApi";
import InfluencersDataTable1 from "./InfluencersDataTable1";

const useStyles = makeStyles({
  accordionContainer: {
    margin: "10px 0",
    padding: 0,
    "&::before": {
      content: "none",
    },
  },
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AccordionOptionsInsta = [
  {
    name: "Overview",
    titles: [
      {
        name: "Engagement",
        options: [
          "Engagement Rate",
          "Average Number of Likes on Posts",
          "Average Number of Views on Posts",
          "Average Number of Comments",
          "Average Posts Per Week",
          "Average Posts Per Month",
        ],
      },
      {
        name: "Credibility",
        options: [
          "Percentage of Profile Followers Credibility",
          // "Age Group of the Profile",
          // "Language of the Profile",
          // "Profile content count",
          // "Credibility of Profile Likers",
          // "Performance of sponsored posts vs organic posts",
        ],
      },
      {
        name: "Location",
        options: [
          "Profile Location Country",
          "Profile Location State",
          "Profile Location City",
        ],
      },
      {
        name: "Growth",
        options: [
          "Followers Growth Rate",
          "Following Growth Rate",
          "Brand Mentions (In the past 3 months)",
        ],
      },
    ],
  },
  {
    name: "Content",
    titles: [
      {
        name: "Top",
        options: [
          "List of top hashtags used by the creator",
          "List of top mentions used by the creator",
          "List of top interests of the creator",
          "Name of the creator brand affinity",
          // "Profile Top Contents Type",
          // "Profile Top Contents URL",
          // "Profile Top Contents Description",
          // "Profile Top Contents Thumbnail URL",
          // "Profile Top Contents Engagement Like Count",
          // "Profile Top Contents Engagement Comment Count",
        ],
      },
      {
        name: "Recent",
        options: [
          "Profile Recent Contents Type",
          "Profile Recent Contents URL",
          "Profile Recent Contents Description",
          "Profile Recent Contents Thumbnail URL",
          // "Profile Recent Contents Engagement Like Count",
          // "Profile Recent Contents Engagement Comment Count",
          // "Profile Recent Contents Published at",
        ],
      },
      {
        name: "Sponsored",
        options: [
          "Profile Sponsored Contents Type",
          "Profile Sponsored Contents URL",
          "Profile Sponsored Contents Description",
          "Profile Sponsored Contents Thumbnail URL",
          // "Profile Sponsored Contents Engagement Like Count",
          // "Profile Sponsored Contents Engagement Comment Count",
          // "Profile Sponsored Contents Published at",
        ],
      },
    ],
  },
  {
    name: "Audience",
    titles: [
      {
        name: "Followers",
        options: [
          "Profile Audience Credibility Score",
          "Profile Significant Followers Percentage",
          "Profile Audience Languages",
          "Profile Audience Languages Percentage",
          "Profile Audience Interests Name",
          "Profile Audience Interests Percentage",
          "Profile Audience Follower Types Name",
          "Profile Audience Follower Types Percentage",
          "Profile Audience Followers Countries",
          "Profile Audience Followers Countries Percentage",
          "Profile Audience Followers Cities Name",
          "Profile Audience Followers Cities Percentage",
          "Profile Audience Followers Gender Age Distribution - Gender",
          "Profile Audience Followers Gender Age Distribution - Age Range",
          "Profile Audience Followers Gender Age Distribution Percentage",
          "Profile Audience Lookalikes",
          // "Profile Audience Lookalikes Platform Username",
          // "Profile Audience Lookalikes URL",
          // "Profile Audience Lookalikes Image URL",
          // "Profile Audience Lookalikes Follower Count",
          // "Profile Audience Lookalikes Verified Status",
          "Profile Significant Followers",

          // "Profile Significant Followers Platform Username",
          // "Profile Significant Followers URL",
          // "Profile Significant Followers Image URL",
          // "Profile Significant Followers Follower Count",
          // "Profile Significant Followers Verified Status",
        ],
      },
      {
        name: "Likers",
        options: [
          "Profile Audience Likers Credibility Score",
          "Profile Audience Likers Significant Followers Percentage",
          "Profile Audience Likers",
          "Profile Audience Likers Countries",
          "Profile Audience Likers Countries Percentage",
          "Profile Audience Likers Cities Name",
          "Profile Audience Likers Cities Percentage",
          "Profile Audience Likers Gender Age Distribution - Gender",
          "Profile Audience Likers Gender Age Distribution - Age Range",
          "Profile Audience Likers Gender Age Distribution Percentage",
          "Profile Audience Likers Languages",
          "Profile Audience Likers Languages Percentage",
          "Profile Audience Likers Interests Name",
          "Profile Audience Likers Interests Value",
          "Profile Audience Likers Followers Types Name",
          "Profile Audience Likers Followers Types Percentage",
          "Profile Audience Likers Lookalikes",
          // "Profile Audience Likers Lookalikes Platform Username",
          // "Profile Audience Likers Lookalikes URL",
          // "Profile Audience Likers Lookalikes Image URL",
          // "Profile Audience Likers Lookalikes Follower Count",
          // "Profile Audience Likers Lookalikes Verified Status",
          "Profile Audience Likers Significant Followers",
          // "Profile Audience Likers Significant Followers Platform Username",
          // "Profile Audience Likers Significant Followers URL",
          // "Profile Audience Likers Significant Followers Image URL",
          // "Profile Audience Likers Significant Followers Follower Count",
          // "Profile Audience Likers Significant Followers Verified Status",
        ],
      },
    ],
  },
  {
    name: "Lookalike",
    titles: [
      {
        name: "Profile Lookalikes",
        options: [
          "Profile Lookalikes Platform Username",
          "Profile Lookalikes URL",
          "Profile Lookalikes Image URL",
          "Profile Lookalikes Follower Count",
          "Profile Lookalikes Verified Status",
        ],
      },
    ],
  },
];

const AccordionOptionsYoutube = [
  {
    name: "Overview",
    titles: [
      {
        name: "Engagement",
        options: [
          "Videos Engagement Rate",
          "Shorts Engagement Rate",
          "Average Number of Views on Videos",
          "Average Number of Views on Shorts",
          "Average Number of Comments",
          "Average Posts Per Week",
          "Average Posts Per Month",
        ],
      },
      {
        name: "Credibility",
        options: [
          "Percentage of Channel Subscribers Credibility",
          "Age Group of the Channel",
          "Language of the Channel",
          "Credibility of Channel Viewers",
          "Channel Content Count",
          "Performance of Sponsored Videos vs Organic Videos",
        ],
      },
      {
        name: "Location",
        options: [
          "Channel Location Country",
          "Channel Location State",
          "Channel Location City",
        ],
      },
      {
        name: "Growth",
        options: [
          "Subscribers Growth Rate",
          "Channel Views Growth Rate",
          "Brand Mentions (In the Past 3 Months)",
        ],
      },
    ],
  },
  {
    name: "Content",
    titles: [
      {
        name: "Top",
        options: [
          "List of top hashtags used by the creator",
          "List of top mentions used by the creator",
          "List of top interests of the creator",
          "Name of the creator brand affinity",
          "Channel Top Contents Type",
          "Channel Top Contents URL",
          "Channel Top Contents Description",
          "Channel Top Contents Thumbnail URL",
          "Channel Top Contents Engagement Views Count",
          "Channel Top Contents Engagement Like Count",
          "Channel Top Contents Engagement Comment Count",
        ],
      },
      {
        name: "Recent",
        options: [
          "Channel Recent Contents Type",
          "Channel Recent Contents URL",
          "Channel Recent Contents Description",
          "Channel Recent Contents Thumbnail URL",
          "Channel Recent Contents Engagement Views Count",
          "Channel Recent Contents Engagement Like Count",
          "Channel Recent Contents Engagement Comment Count",
          "Channel Recent Contents Published at",
        ],
      },
      {
        name: "Sponsored",
        options: [
          "Channel Sponsored Contents Type",
          "Channel Sponsored Contents URL",
          "Channel Sponsored Contents Description",
          "Channel Sponsored Contents Thumbnail URL",
          "Channel Sponsored Contents Engagement Views Count",
          "Channel Sponsored Contents Engagement Like Count",
          "Channel Sponsored Contents Engagement Comment Count",
          "Channel Sponsored Contents Published at",
        ],
      },
    ],
  },
  {
    name: "Audience",
    titles: [
      {
        name: "Followers",
        options: [
          "Channel Audience Languages",
          "Channel Audience Languages Percentage",
          "Channel Audience Interests Name",
          "Channel Audience Interests Percentage",
          "Channel Audience Subscribers Types Name",
          "Channel Audience Subscribers Types Percentage",
          "Channel Audience Subscribers Countries",
          "Channel Audience Subscribers Countries Percentage",
          "Channel Audience Subscribers Cities Name",
          "Channel Audience Subscribers Cities Percentage",
          "Channel Audience Subscribers Gender Age Distribution - Gender",
          "Channel Audience Subscribers Gender Age Distribution - Age Range",
          "Channel Audience Subscribers Gender Age Distribution Percentage",
          "Channel Audience Lookalikes Platform Username",
          "Channel Audience Lookalikes URL",
          "Channel Audience Lookalikes Image URL",
          "Channel Audience Lookalikes Subscribers Count",
          "Channel Audience Lookalikes Verified Status",
          "Channel Audience Credibility Score",
        ],
      },
      {
        name: "Likers",
        options: [
          "Channel Audience Likers",
          "Channel Audience Likers Countries",
          "Channel Audience Likers Countries Percentage",
          "Channel Audience Likers Cities Name",
          "Channel Audience Likers Cities Percentage",
          "Channel Audience Likers Gender Age Distribution - Gender",
          "Channel Audience Likers Gender Age Distribution - Age Range",
          "Channel Audience Likers Gender Age Distribution Percentage",
          "Channel Audience Likers Languages",
          "Channel Audience Likers Languages Percentage",
          "Channel Audience Likers Interests Name",
          "Channel Audience Likers Interests Value",
          "Channel Audience Likers Followers Types Name",
          "Channel Audience Likers Followers Types Percentage",
          "Channel Audience Likers Lookalikes Platform Username",
          "Channel Audience Likers Lookalikes URL",
          "Channel Audience Likers Lookalikes Image URL",
          "Channel Audience Likers Lookalikes Subscribers Count",
          "Channel Audience Likers Lookalikes Verified Status",
          "Channel Audience Likers Credibility Score",
        ],
      },
      {
        name: "Viewers",
        options: [
          "Channel Audience Viewers",
          "Channel Audience Viewers Countries",
          "Channel Audience Viewers Countries Percentage",
          "Channel Audience Viewers Cities Name",
          "Channel Audience Viewers Cities Percentage",
          "Channel Audience Viewers Gender Age Distribution - Gender",
          "Channel Audience Viewers Gender Age Distribution - Age Range",
          "Channel Audience Viewers Gender Age Distribution Percentage",
          "Channel Audience Viewers Languages",
          "Channel Audience Viewers Languages Percentage",
          "Channel Audience Viewers Interests Name",
          "Channel Audience Viewers Interests Value",
          "Channel Audience Viewers Followers Types Name",
          "Channel Audience Viewers Followers Types Percentage",
          "Channel Audience Viewers Lookalikes Platform Username",
          "Channel Audience Viewers Lookalikes URL",
          "Channel Audience Viewers Lookalikes Image URL",
          "Channel Audience Viewers Lookalikes Subscribers Count",
          "Channel Audience Viewers Lookalikes Verified Status",
          "Channel Audience Viewers Credibility Score",
        ],
      },
    ],
  },
  {
    name: "Lookalike",
    titles: [
      {
        name: "Profile Lookalikes",
        options: [
          "Channel Lookalikes Platform Username",
          "Channel Lookalikes URL",
          "Channel Lookalikes Image URL",
          "Channel Lookalikes Subscribers Count",
          "Channel Lookalikes Verified Status",
        ],
      },
    ],
  },
];

const BasicTabs = () => {
  const [value, setValue] = React.useState(0);
  const [social, setSocial] = React.useState("Instagram");
  const [currentTab, setCurrentTab] = useState(0);
  const [influencers, setInfluencers] = useState([]);
  const [fetchingInfluencer, setFetchingInfluencer] = useState(false);
  const [fetchingInfluencers, setFetchingInfluencers] = useState(false);

  const classes = useStyles();

  const handleSocialChange = (event, newValue) => {
    setSocial(newValue);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentTab(newValue);
  };

  const fetchMoreInfluencers = async () => {
    try {
      setFetchingInfluencers(true);
      const response = await getAllInfluencers();
      console.log("influencers ", response);
      if (response.length > 0) {
        setInfluencers(response);
      }
    } catch (error) {
      console.error("Error fetching influencers:", error);
    } finally {
      setFetchingInfluencers(false);
    }
  };

  useEffect(() => {
    fetchMoreInfluencers();
  }, []);

  // data for charts and progress bar
  const data2 = [
    { label: "Group A", value: 2400 },
    { label: "Group B", value: 4567 },
    { label: "Group C", value: 1398 },
    { label: "Group D", value: 9800 },
    { label: "Group E", value: 3908 },
    { label: "Group F", value: 4800 },
  ];

  // custom progress bar

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#e36e46" : "#308fe8",
    },
  }));

  function CustomizedProgressBars({ data }) {
    return (
      <Stack spacing={1} sx={{ flexGrow: 1, padding: "10px 20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography>{data.label}</Typography>
          <Typography>{data.value / 100} %</Typography>
        </Box>
        <BorderLinearProgress variant="determinate" value={data.value / 100} />
      </Stack>
    );
  }

  function Bar1() {
    return (
      <BarChart
        series={[
          { data: [35, 44, 24, 34] },
          { data: [51, 6, 49, 30] },
          { data: [15, 25, 30, 50] },
          { data: [60, 50, 15, 25] },
        ]}
        height={150}
        xAxis={[{ data: ["Q1", "Q2", "Q3", "Q4"], scaleType: "band" }]}
        margin={{ top: 10, bottom: 20, left: 40, right: 10 }}
      />
    );
  }
  function Bar2() {
    return (
      <>
        {data2.map((data) => {
          return <CustomizedProgressBars data={data} />;
        })}
      </>
    );
  }

  function PieChart1() {
    return (
      <Box>
        <PieChart
          series={[
            {
              data: data2,
              cx: "50%",
              cy: "50%",
              innerRadius: 50,
              outerRadius: 100,
              color: [
                "#FF69B4",
                "#33CC33",
                "#6666FF",
                "#CCCCCC",
                "#FF9900",
                "#111",
              ],
            },
          ]}
          height={200}
          slotProps={{
            legend: { hidden: true },
          }}
        />
        {data2.map((data) => {
          return <CustomizedProgressBars data={data} />;
        })}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        maxWidth: "100%",
        padding: 0,
      }}
    >
      <TabContext value={social}>
        <Box
          sx={{
            maxWidth: "100%",
            ml: 5,
          }}
        >
          <TabList
            onChange={handleSocialChange}
            aria-label="lab API tabs example"
          >
            <Tab
              icon={
                <img
                  src="https://cdn-icons-png.flaticon.com/512/2111/2111463.png"
                  alt="Instagram"
                  width={20}
                />
              }
              iconPosition="start"
              sx={{ color: "black" }}
              label="Instagram"
              value="Instagram"
            />
            <Tab
              icon={
                <img
                  src="https://cdn-icons-png.flaticon.com/512/1384/1384060.png"
                  alt="Youtube"
                  width={20}
                />
              }
              iconPosition="start"
              sx={{ color: "black" }}
              label="Youtube"
              value="Youtube"
            />
          </TabList>
        </Box>
        <TabPanel value="Instagram">
          <Box
            sx={{
              maxWidth: "100%",
              padding: 0,
            }}
          >
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                padding: 0,
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  style={{
                    fontSize: "12px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  iconPosition="start"
                  label="Overview"
                  {...a11yProps(0)}
                />
                <Tab
                  style={{
                    fontSize: "12px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  iconPosition="start"
                  label="Content"
                  {...a11yProps(0)}
                />
                <Tab
                  style={{
                    fontSize: "12px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  iconPosition="start"
                  label="Audience"
                  {...a11yProps(0)}
                />
                <Tab
                  style={{
                    fontSize: "12px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  iconPosition="start"
                  label="Lookalike"
                  {...a11yProps(0)}
                />
              </Tabs>
            </Box>

            {AccordionOptionsInsta.map((option, index) => (
              <CustomTabPanel value={value} index={index} key={index}>
                {option.titles.map((title, index) => (
                  <div key={index} className={classes.accordionContainer}>
                    <Accordion
                      defaultExpanded
                      style={{
                        background: "#F2F7FF",
                        border: "0",
                        boxShadow: "0",
                        borderRadius: 20,
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<GridExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: { md: "center", xs: "flex-start" },
                            flexDirection: { xs: "column", md: "row" },
                          }}
                        >
                          <Typography
                            style={{ fontWeight: "bold", fontSize: 15 }}
                          >
                            {title.name}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns:
                              "repeat(auto-fit, minmax(500px, 1fr))",
                            gap: 20,
                          }}
                        >
                          {title.name === "Followers" ||
                          title.name === "Likers" ? (
                            <>
                              {title.options
                                .filter(
                                  (option) =>
                                    title.name === "Followers" ||
                                    title.name === "Likers"
                                )
                                .map((option, index) => (
                                  <div
                                    item
                                    key={index}
                                    style={{
                                      background: "#FFFFFF",
                                      padding: 15,
                                      borderRadius: 10,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: 12,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {option}
                                      </Typography>
                                      <IconButton style={{ color: "#3DBE72" }}>
                                        <HelpOutlineRoundedIcon
                                          style={{ fontSize: 15 }}
                                        />
                                      </IconButton>
                                    </Box>
                                    {option ===
                                      "Profile Audience Languages" && (
                                      <Box sx={{ width: "100%" }}>
                                        {data2.map((data) => (
                                          <CustomizedProgressBars data={data} />
                                        ))}
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Languages Percentage" && (
                                      <Box sx={{ width: "100%", marginTop: 2 }}>
                                        {
                                          <>
                                            <Bar1 />
                                            <Bar2 />
                                          </>
                                        }
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Interests Name" && (
                                      <Box sx={{ width: "100%" }}>
                                        {data2.map((data) => (
                                          <CustomizedProgressBars data={data} />
                                        ))}
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Interests Percentage" && (
                                      <Box sx={{ width: "100%", marginTop: 2 }}>
                                        {
                                          <>
                                            <Bar1 />
                                            <Bar2 />
                                          </>
                                        }
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Follower Types Name" && (
                                      <Box sx={{ width: "100%" }}>
                                        {data2.map((data) => (
                                          <CustomizedProgressBars data={data} />
                                        ))}
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Follower Types Percentage" && (
                                      <Box sx={{ width: "100%", marginTop: 2 }}>
                                        {
                                          <>
                                            <Bar1 />
                                            <Bar2 />
                                          </>
                                        }
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Followers Countries" && (
                                      <Box sx={{ width: "100%" }}>
                                        {data2.map((data) => (
                                          <CustomizedProgressBars data={data} />
                                        ))}
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Followers Countries Percentage" && (
                                      <Box sx={{ width: "100%", marginTop: 2 }}>
                                        {
                                          <>
                                            <Bar1 />
                                            <Bar2 />
                                          </>
                                        }
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Followers Cities Name" && (
                                      <Box sx={{ width: "100%" }}>
                                        {data2.map((data) => (
                                          <CustomizedProgressBars data={data} />
                                        ))}
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Followers Cities Percentage" && (
                                      <Box sx={{ width: "100%", marginTop: 2 }}>
                                        {
                                          <>
                                            <Bar1 />
                                            <Bar2 />
                                          </>
                                        }
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Followers Gender Age Distribution - Gender" && (
                                      <Box
                                        sx={{
                                          width: "100%",
                                          marginTop: 2,
                                        }}
                                      >
                                        <PieChart1 />
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Followers Gender Age Distribution - Age Range" && (
                                      <Box sx={{ width: "100%", marginTop: 2 }}>
                                        {
                                          <>
                                            <Bar1 />
                                            <Bar2 />
                                          </>
                                        }
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Followers Gender Age Distribution Percentage" && (
                                      <Box sx={{ width: "100%", marginTop: 2 }}>
                                        {
                                          <>
                                            <Bar1 />
                                            <Bar2 />
                                          </>
                                        }
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Lookalikes" && (
                                      <Box
                                        sx={{
                                          marginTop: 2,
                                          overflow: "hidden",
                                        }}
                                      >
                                        <InfluencersDataTable1
                                          influencers={influencers}
                                          fetching={fetchingInfluencer}
                                          fetchMoreInfluencers={
                                            fetchMoreInfluencers
                                          }
                                          onScrollFetching={fetchingInfluencers}
                                        />
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Significant Followers" && (
                                      <Box
                                        sx={{
                                          width: "100%",
                                          marginTop: 2,
                                          overflow: "hidden",
                                        }}
                                      >
                                        <InfluencersDataTable1
                                          influencers={influencers}
                                          fetching={fetchingInfluencer}
                                          fetchMoreInfluencers={
                                            fetchMoreInfluencers
                                          }
                                          onScrollFetching={fetchingInfluencers}
                                        />
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Credibility Score" && (
                                      <Box sx={{ width: "100%" }}>
                                        Credibility Score
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Significant Followers Percentage" && (
                                      <Box sx={{ width: "100%" }}>
                                        Percentage:
                                      </Box>
                                    )}
                                    {option === "Profile Audience Likers" && (
                                      <Box sx={{ width: "100%", marginTop: 2 }}>
                                        {
                                          <Box sx={{ width: "100%" }}>
                                            {data2.map((data) => (
                                              <CustomizedProgressBars
                                                data={data}
                                              />
                                            ))}
                                          </Box>
                                        }
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Likers Countries" && (
                                      <Box sx={{ width: "100%", marginTop: 2 }}>
                                        {
                                          <Box sx={{ width: "100%" }}>
                                            {data2.map((data) => (
                                              <CustomizedProgressBars
                                                data={data}
                                              />
                                            ))}
                                          </Box>
                                        }
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Likers Countries Percentage" && (
                                      <Box sx={{ width: "100%", marginTop: 2 }}>
                                        {
                                          <>
                                            <Bar1 />
                                            <Bar2 />
                                          </>
                                        }
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Likers Cities Name" && (
                                      <Box sx={{ width: "100%" }}>
                                        {data2.map((data) => (
                                          <CustomizedProgressBars data={data} />
                                        ))}
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Likers Cities Percentage" && (
                                      <Box sx={{ width: "100%", marginTop: 2 }}>
                                        {
                                          <>
                                            <Bar1 />
                                            <Bar2 />
                                          </>
                                        }
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Likers Gender Age Distribution - Gender" && (
                                      <Box
                                        sx={{
                                          width: "100%",
                                          marginTop: 2,
                                        }}
                                      >
                                        <PieChart1 />
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Likers Gender Age Distribution - Age Range" && (
                                      <Box sx={{ width: "100%", marginTop: 2 }}>
                                        {
                                          <>
                                            <Bar1 />
                                            <Bar2 />
                                          </>
                                        }
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Likers Gender Age Distribution Percentage" && (
                                      <Box sx={{ width: "100%", marginTop: 2 }}>
                                        {
                                          <>
                                            <Bar1 />
                                            <Bar2 />
                                          </>
                                        }
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Likers Languages" && (
                                      <Box sx={{ width: "100%" }}>
                                        {data2.map((data) => (
                                          <CustomizedProgressBars data={data} />
                                        ))}
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Likers Languages Percentage" && (
                                      <Box sx={{ width: "100%", marginTop: 2 }}>
                                        {
                                          <>
                                            <Bar1 />
                                            <Bar2 />
                                          </>
                                        }
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Likers Interests Name" && (
                                      <Box sx={{ width: "100%" }}>
                                        {data2.map((data) => (
                                          <CustomizedProgressBars data={data} />
                                        ))}
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Likers Interests Value" && (
                                      <Box sx={{ width: "100%", marginTop: 2 }}>
                                        {
                                          <>
                                            <Bar1 />
                                            <Bar2 />
                                          </>
                                        }
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Likers Followers Types Name" && (
                                      <Box sx={{ width: "100%" }}>
                                        {data2.map((data) => (
                                          <CustomizedProgressBars data={data} />
                                        ))}
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Likers Followers Types Percentage" && (
                                      <Box
                                        sx={{
                                          width: "100%",
                                          marginTop: 2,
                                        }}
                                      >
                                        <PieChart1 />
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Likers Lookalikes" && (
                                      <Box
                                        sx={{
                                          width: "100%",
                                          marginTop: 2,
                                          overflow: "hidden",
                                        }}
                                      >
                                        <InfluencersDataTable1
                                          influencers={influencers}
                                          fetching={fetchingInfluencer}
                                          fetchMoreInfluencers={
                                            fetchMoreInfluencers
                                          }
                                          onScrollFetching={fetchingInfluencers}
                                        />
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Likers Significant Followers Percentage" && (
                                      <Box sx={{ width: "100%" }}>
                                        Percentage:
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Likers Credibility Score" && (
                                      <Box sx={{ width: "100%" }}>
                                        Credibility Score
                                      </Box>
                                    )}
                                    {option ===
                                      "Profile Audience Likers Significant Followers" && (
                                      <Box
                                        sx={{
                                          width: "100%",
                                          marginTop: 2,
                                          overflow: "hidden",
                                        }}
                                      >
                                        <InfluencersDataTable1
                                          influencers={influencers}
                                          fetching={fetchingInfluencer}
                                          fetchMoreInfluencers={
                                            fetchMoreInfluencers
                                          }
                                          onScrollFetching={fetchingInfluencers}
                                        />
                                      </Box>
                                    )}
                                  </div>
                                ))}
                            </>
                          ) : (
                            <>
                              {title.options
                                .filter(
                                  (option) =>
                                    !option.includes("Percentage") &&
                                    title.name !== "Growth" &&
                                    title.name !== "Recent" &&
                                    title.name !== "Sponsored" &&
                                    title.name !== "Followers" &&
                                    title.name !== "Likers"
                                )
                                .map((option, index) => (
                                  <div
                                    item
                                    key={index}
                                    style={{
                                      background: "#FFFFFF",
                                      padding: 15,
                                      borderRadius: 10,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: 12,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {option}
                                      </Typography>
                                      <IconButton style={{ color: "#3DBE72" }}>
                                        <HelpOutlineRoundedIcon
                                          style={{ fontSize: 15 }}
                                        />
                                      </IconButton>
                                    </Box>
                                    <Typography
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "1.7rem",
                                      }}
                                    >
                                      Kuch Toh
                                    </Typography>
                                  </div>
                                ))}
                            </>
                          )}
                        </div>
                        <div>
                          {title.options
                            .filter(
                              (option) =>
                                option.includes("Percentage") ||
                                option.includes("Growth") ||
                                (option ===
                                  "Profile Audience Followers Countries" &&
                                  title.name !== "Likers" &&
                                  title.name !== "Followers")
                            )
                            .map(
                              (option, index) =>
                                title.name !== "Likers" &&
                                title.name !== "Followers" && (
                                  <div
                                    item
                                    key={index}
                                    style={{
                                      background: "#FFFFFF",
                                      padding: 15,
                                      borderRadius: 10,
                                      marginTop: 20,
                                    }}
                                  >
                                    <Box>
                                      <Typography
                                        sx={{
                                          fontSize: 12,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {option}
                                      </Typography>
                                      <IconButton style={{ color: "#3DBE72" }}>
                                        <HelpOutlineRoundedIcon
                                          style={{ fontSize: 15 }}
                                        />
                                      </IconButton>
                                    </Box>
                                    {currentTab === 0 && (
                                      <OverViewChart key={index} />
                                    )}
                                    {currentTab === 1 && (
                                      <ContentChart key={index} />
                                    )}
                                    {currentTab === 2 && (
                                      <AudienceChart
                                        key={index}
                                        options={option}
                                      />
                                    )}
                                    {currentTab === 3 && (
                                      <LookalikeChart key={index} />
                                    )}
                                  </div>
                                )
                            )}
                          {title.name == "Growth" && (
                            <Grid
                              container
                              // spacing={1}
                              sx={{
                                borderRadius: 2,
                                padding: 1,
                                background: "#fff",
                                "&": {
                                  width: "100%",
                                  margin: "0 auto",
                                  marginTop: 2,
                                },
                              }}
                            >
                              <Grid
                                item
                                sm={12}
                                sx={{
                                  marginBottom: 2,
                                  padding: 1,
                                }}
                              >
                                <Typography variant="h6">
                                  Brand Mentions
                                </Typography>
                                <Typography variant="body2">
                                  Lorem ipsum dolor sit amet consectetur,
                                  adipisicing elit. Nesciunt animi obcaecati at
                                  quo iure dolorem. At error blanditiis, quis
                                  velit doloribus quo est in, perferendis culpa
                                  eius necessitatibus unde fuga veniam accusamus
                                  vitae repudiandae saepe, sequi quibusdam
                                  laboriosam reiciendis! Tempora.
                                </Typography>
                              </Grid>
                              <Grid item sm={12} md={6} sx={{ padding: 1 }}>
                                <Box
                                  sx={{
                                    padding: 2,
                                    border: "1px solid grey",
                                    borderRadius: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "start",
                                    justifyContent: "space-between",
                                    gap: 4,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: 1,
                                      alignItems: "start",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Avatar />
                                    <Typography
                                      variant="body1"
                                      sx={{ fontWeight: "bold" }}
                                    >
                                      google.com
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      fontSize: 12,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: 2,
                                    }}
                                  >
                                    <Typography variant="caption">
                                      1 video
                                    </Typography>
                                    <Typography variant="caption">
                                      140k total views
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item sm={12} md={6} sx={{ padding: 1 }}>
                                <Box
                                  sx={{
                                    padding: 2,
                                    border: "1px solid grey",
                                    borderRadius: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "start",
                                    justifyContent: "space-between",
                                    gap: 4,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: 1,
                                      alignItems: "start",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Avatar />
                                    <Typography
                                      variant="body1"
                                      sx={{ fontWeight: "bold" }}
                                    >
                                      google.com
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      fontSize: 12,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: 2,
                                    }}
                                  >
                                    <Typography variant="caption">
                                      1 video
                                    </Typography>
                                    <Typography variant="caption">
                                      140k total views
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item sm={12} md={6} sx={{ padding: 1 }}>
                                <Box
                                  sx={{
                                    padding: 2,
                                    border: "1px solid grey",
                                    borderRadius: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "start",
                                    justifyContent: "space-between",
                                    gap: 4,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: 1,
                                      alignItems: "start",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Avatar />
                                    <Typography
                                      variant="body1"
                                      sx={{ fontWeight: "bold" }}
                                    >
                                      google.com
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      fontSize: 12,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: 2,
                                    }}
                                  >
                                    <Typography variant="caption">
                                      1 video
                                    </Typography>
                                    <Typography variant="caption">
                                      140k total views
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item sm={12} md={6} sx={{ padding: 1 }}>
                                <Box
                                  sx={{
                                    padding: 2,
                                    border: "1px solid grey",
                                    borderRadius: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "start",
                                    justifyContent: "space-between",
                                    gap: 4,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: 1,
                                      alignItems: "start",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Avatar />
                                    <Typography
                                      variant="body1"
                                      sx={{ fontWeight: "bold" }}
                                    >
                                      google.com
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      fontSize: 12,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: 2,
                                    }}
                                  >
                                    <Typography variant="caption">
                                      1 video
                                    </Typography>
                                    <Typography variant="caption">
                                      140k total views
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          )}
                        </div>
                        {option.name === "Content" && (
                          <Box
                            sx={{
                              marginTop: 2,
                              width: "100%",
                            }}
                          >
                            <CustomCarousel1 />
                          </Box>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
              </CustomTabPanel>
            ))}
          </Box>
        </TabPanel>
        <TabPanel value="Youtube">
          <Box
            sx={{
              maxWidth: "100%",
              padding: 0,
            }}
          >
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                padding: 0,
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  style={{
                    fontSize: "12px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  iconPosition="start"
                  label="Overview"
                  {...a11yProps(0)}
                />
                <Tab
                  style={{
                    fontSize: "12px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  iconPosition="start"
                  label="Content"
                  {...a11yProps(0)}
                />
                <Tab
                  style={{
                    fontSize: "12px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  iconPosition="start"
                  label="Audience"
                  {...a11yProps(0)}
                />
                <Tab
                  style={{
                    fontSize: "12px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  iconPosition="start"
                  label="Lookalike"
                  {...a11yProps(0)}
                />
              </Tabs>
            </Box>

            {AccordionOptionsYoutube.map((option, index) => (
              <CustomTabPanel value={value} index={index} key={index}>
                {option.titles.map((title, index) => (
                  <div key={index} className={classes.accordionContainer}>
                    <Accordion
                      defaultExpanded
                      style={{
                        background: "#F2F7FF",
                        border: "0",
                        boxShadow: "0",
                        borderRadius: 20,
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<GridExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: { md: "center", xs: "flex-start" },
                            flexDirection: { xs: "column", md: "row" },
                          }}
                        >
                          <Typography
                            style={{ fontWeight: "bold", fontSize: 15 }}
                          >
                            {title.name}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns:
                              "repeat(auto-fit, minmax(500px, 1fr))",
                            gap: 20,
                          }}
                        >
                          {title.options.map((option, index) => (
                            <div
                              item
                              key={index}
                              style={{
                                background: "#FFFFFF",
                                padding: 15,
                                borderRadius: 10,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  sx={{ fontSize: 12, fontWeight: "bold" }}
                                >
                                  {option}
                                </Typography>
                                <IconButton style={{ color: "#3DBE72" }}>
                                  <HelpOutlineRoundedIcon
                                    style={{ fontSize: 15 }}
                                  />
                                </IconButton>
                              </Box>
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "1.7rem",
                                }}
                              ></Typography>
                            </div>
                          ))}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
              </CustomTabPanel>
            ))}
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default BasicTabs;
