import { Box, Grid, Avatar, AvatarGroup, Typography, Button, IconButton } from '@mui/material'
import { Shortcut, Add, Download, RemoveRedEye, ShareOutlined } from '@mui/icons-material'
import MyBarChart from './MyBarChart'


import React from 'react'
import InstaIcon from '../../images/insta-sm.png'
import AssignedTable from './AssignedTable'
import RecentUpdate from './RecentUpdate'







const CampaignProfile = () => {
    
    return (
        <>
            <Box p={3} width={'100%'}>
                <Grid container>
                    <Grid item md={12} xs={12}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '1.4rem' }}>Campaigns</Typography>
                    </Grid>
                    <Grid item md={3} mt={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <AvatarGroup total={24} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', position: 'relative' }}
                            componentsProps={{
                                additionalAvatar: {
                                    sx: {
                                        height: '50px',
                                        width: '50px',
                                        background: "grey",
                                        color: '#FFFFFF',
                                        border: '2px solid green',
                                        position: 'relative',
                                        zIndex: 1
                                    }
                                }
                            }
                            }
                        >

                            <Avatar sx={{
                                width: 70,
                                height: 70,

                            }} alt="Remy Sharp" src="/static/images/avatar/1.jpg" />

                            <Avatar sx={{
                                width: 50,
                                height: 50
                            }} alt="Travis Howard" src="/static/images/avatar/2.jpg" />

                            <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />

                            <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />

                        </AvatarGroup>
                    </Grid>
                    <Grid item md={9}>
                        <Grid container >
                            <Grid item md={5} >
                                <Typography sx={{ fontWeight: 'bold', fontSize: '1.3rem' }}>Food Campaign</Typography>
                                <Typography>Created by <span>Parle</span></Typography>
                                <Box mt={2}>
                                    <Button size='small' variant='outlined' sx={{ textTransform: 'none', borderRadius: 8, fontWeight: 'bold', padding: '5px 20px', color: 'black', borderColor: 'black', '&:hover': { borderColor: 'black' }, marginRight: 2 }}>
                                        Edit Campaign
                                    </Button>
                                    <IconButton size='small' sx={{ border: '1px solid #3dbf73ff', borderRadius: 1, '&:hover': { borderColor: '#3dbf73ff' } }}>
                                        <Shortcut sx={{ color: '#3dbf73ff' }} />
                                    </IconButton>
                                </Box>
                            </Grid>
                            <Grid item md={3}>
                                <Button variant='contained' startIcon={<Add />} sx={{ textTransform: 'none', background: '#3dbf73ff', borderRadius: 8, fontWeight: 'bold', boxShadow: 'none', '&:hover': { background: '#3dbf73ff', boxShadow: 'none' } }}>Add Updates</Button>
                            </Grid>
                            <Grid item md={12} mt={2}>
                                <Button variant='contained' startIcon={<Download />} sx={{ textTransform: 'none', background: '#3dbf73ff', borderRadius: 8, fontWeight: 'bold', boxShadow: 'none', '&:hover': { background: '#3dbf73ff', boxShadow: 'none' } }}>Download Report</Button>
                                <Button variant='contained' startIcon={<RemoveRedEye />} sx={{ margin: '0px 10px', textTransform: 'none', background: '#3dbf73ff', borderRadius: 8, fontWeight: 'bold', boxShadow: 'none', '&:hover': { background: '#3dbf73ff', boxShadow: 'none' } }}>View Report</Button>
                                <Button variant='contained' startIcon={<ShareOutlined />} sx={{ textTransform: 'none', background: '#3dbf73ff', borderRadius: 8, fontWeight: 'bold', boxShadow: 'none', '&:hover': { background: '#3dbf73ff', boxShadow: 'none' } }}>Share Report</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={12}>
                        <Button startIcon={<img src={InstaIcon} />} variant='text' sx={{ textTransform: 'none', fontWeight: 'bold', fontSize: '1.2rem', color: 'black' }}>Instagram</Button>
                    </Grid>
                    <Grid item md={12} mt={2}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <Box sx={{ width: '20%', p: 2, border: "1px solid black", borderRadius: 2, bgcolor : '#bce6d5ff' }}>
                                <Typography sx={{ fontSize: '1.1rem', fontWeight: "bold" }}>Posts</Typography>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '1.8rem', textAlign: 'center' }}>39/100</Typography>
                            </Box>
                            <Box sx={{ width: '20%', p: 2, border: "1px solid black", borderRadius: 2, bgcolor : '#bce6d5ff' }}>
                                <Typography sx={{ fontSize: '1.1rem', fontWeight: "bold" }}>Reels</Typography>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '1.8rem', textAlign: 'center' }}>12</Typography>
                            </Box>
                            <Box sx={{ width: '20%', p: 2, border: "1px solid black", borderRadius: 2, bgcolor : '#bce6d5ff' }}>
                                <Typography sx={{ fontSize: '1.1rem', fontWeight: "bold" }}>Stories</Typography>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '1.8rem', textAlign: 'center' }}>55</Typography>
                            </Box>
                            <Box sx={{ width: '20%', p: 2, border: "1px solid black", borderRadius: 2, bgcolor : '#bce6d5ff' }}>
                                <Typography sx={{ fontSize: '1.1rem', fontWeight: "bold" }}>Stories</Typography>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '1.8rem', textAlign: 'center' }}>5</Typography>
                            </Box>

                        </Box>

                    </Grid>
                    <Grid item md={12}>
                        <MyBarChart/>
                    </Grid>
                    <Grid item md={12}>
                        <AssignedTable/>
                    </Grid>
                    <Grid item md={12}>
                     <RecentUpdate/>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default CampaignProfile