const influencers = [
  {
    id: 1,
    account: {
      name: 'Bhardwaj Rowan',
      dp: 'https://dpwalay.com/wp-content/uploads/2023/07/single-dp-8.jpg',
      instagram: '',
      category: 'Astrology'
    },
    followers: {
      total: '6.8m',
      current: '5.8m'
    },
    action: {
      action1: 'action1',
      action2: 'action2',
      action3: 'action3',
    },
    engagement: {
      rate: '23.84%',
      status: 'Good'
    },
    average: {
      likes: '16.6k/99',
      status: 'Very Good'
    }
  },
  {
    id: 2,
    account: {
      name: 'Raju',
      dp: 'https://mastimorning.com/wp-content/uploads/2023/08/Download-Free-New-Boy-Black-DP-Pics-Images.jpg',
      instagram: '',
      category: 'Astrology'
    },
    followers: {
      total: '7.8m',
      current: '6.8m'
    },
    action: {
      action1: 'action1',
      action2: 'action2',
      action3: 'action3',
    },
    engagement: {
      rate: '2.84%',
      status: 'Good'
    },
    average: {
      likes: '306.6k/99',
      status: 'Very Good'
    }
  },
  {
    id: 3,
    account: {
      name: 'Babu Bhaiiya',
      dp: 'https://dpwalay.com/wp-content/uploads/2023/07/single-boy-pic-7.jpg',
      instagram: '',
      category: 'Astrology'
    },
    followers: {
      total: '3.8m',
      current: '2.8m'
    },
    action: {
      action1: 'action1',
      action2: 'action2',
      action3: 'action3',
    },
    engagement: {
      rate: '3.84%',
      status: 'Good'
    },
    average: {
      likes: '106.6k/99',
      status: 'Very Good'
    }
  },
  {
    id: 4,
    account: {
      name: 'Sunder Lal',
      dp: 'https://mastimorning.com/wp-content/uploads/2023/08/Download-Free-New-Boy-Black-DP-Pics-Images.jpg',
      instagram: '',
      category: 'Astrology'
    },
    followers: {
      total: '8.8m',
      current: '8.8m'
    },
    action: {
      action1: 'action1',
      action2: 'action2',
      action3: 'action3',
    },
    engagement: {
      rate: '5.84%',
      status: 'Good'
    },
    average: {
      likes: '106.6k/99',
      status: 'Very Good'
    }
  },
  {
    id: 5,
    account: {
      name: 'Shubham Singh',
      dp: 'https://dpwalay.com/wp-content/uploads/2023/07/single-boy-pic-7.jpg',
      instagram: '',
      category: 'Astrology'
    },
    followers: {
      total: '3.8m',
      current: '1.8m'
    },
    action: {
      action1: 'action1',
      action2: 'action2',
      action3: 'action3',
    },
    engagement: {
      rate: '1.84%',
      status: 'Good'
    },
    average: {
      likes: '126.6k/29',
      status: 'Very Good'
    }
  },
  {
    id: 6,
    account: {
      name: 'Shubham Singh',
      dp: 'https://dpwalay.com/wp-content/uploads/2023/07/single-boy-pic-7.jpg',
      instagram: '',
      category: 'Astrology'
    },
    followers: {
      total: '3.8m',
      current: '1.8m'
    },
    action: {
      action1: 'action1',
      action2: 'action2',
      action3: 'action3',
    },
    engagement: {
      rate: '1.84%',
      status: 'Good'
    },
    average: {
      likes: '126.6k/29',
      status: 'Very Good'
    }
  },
  {
    id: 7,
    account: {
      name: 'Shubham Singh',
      dp: 'https://dpwalay.com/wp-content/uploads/2023/07/single-boy-pic-7.jpg',
      instagram: '',
      category: 'Astrology'
    },
    followers: {
      total: '3.8m',
      current: '1.8m'
    },
    action: {
      action1: 'action1',
      action2: 'action2',
      action3: 'action3',
    },
    engagement: {
      rate: '1.84%',
      status: 'Good'
    },
    average: {
      likes: '126.6k/29',
      status: 'Very Good'
    }
  },
  {
    id: 8,
    account: {
      name: 'Shubham Singh',
      dp: 'https://dpwalay.com/wp-content/uploads/2023/07/single-boy-pic-7.jpg',
      instagram: '',
      category: 'Astrology'
    },
    followers: {
      total: '3.8m',
      current: '1.8m'
    },
    action: {
      action1: 'action1',
      action2: 'action2',
      action3: 'action3',
    },
    engagement: {
      rate: '1.84%',
      status: 'Good'
    },
    average: {
      likes: '126.6k/29',
      status: 'Very Good'
    }
  },
  {
    id: 9,
    account: {
      name: 'Shubham Singh',
      dp: 'https://dpwalay.com/wp-content/uploads/2023/07/single-boy-pic-7.jpg',
      instagram: '',
      category: 'Astrology'
    },
    followers: {
      total: '3.8m',
      current: '1.8m'
    },
    action: {
      action1: 'action1',
      action2: 'action2',
      action3: 'action3',
    },
    engagement: {
      rate: '1.84%',
      status: 'Good'
    },
    average: {
      likes: '126.6k/29',
      status: 'Very Good'
    }
  },
  {
    id: 10,
    account: {
      name: 'Shubham Singh',
      dp: 'https://dpwalay.com/wp-content/uploads/2023/07/single-dp-8.jpg',
      instagram: '',
      category: 'Astrology'
    },
    followers: {
      total: '3.8m',
      current: '1.8m'
    },
    action: {
      action1: 'action1',
      action2: 'action2',
      action3: 'action3',
    },
    engagement: {
      rate: '1.84%',
      status: 'Good'
    },
    average: {
      likes: '126.6k/29',
      status: 'Very Good'
    }
  },
  {
    id: 11,
    account: {
      name: "Alex Johnson",
      dp: "https://dpwalay.com/wp-content/uploads/2023/07/single-dp-8.jpg",
      instagram: "alex.j",
      category: "Travel"
    },
    followers: {
      total: "4.5m",
      current: "3.9m"
    },
    action: {
      action1: "like",
      action2: "comment",
      action3: "share"
    },
    engagement: {
      rate: "18.76%",
      status: "Good"
    },
    average: {
      likes: "12.3k/88",
      status: "Very Good"
    }
  },
  {
    id: 12,
    account: {
      name: "Sophie Miller",
      dp: "https://dpwalay.com/wp-content/uploads/2023/07/single-dp-8.jpg",
      instagram: "sophie.m",
      category: "Food"
    },
    followers: {
      total: "2.1m",
      current: "1.8m"
    },
    action: {
      "action1": "follow",
      "action2": "unfollow",
      "action3": "save"
    },
    engagement: {
      rate: "14.92%",
      status: "Moderate"
    },
    average: {
      likes: "6.7k/64",
      status: "Good"
    }
  },
  {
    id: 13,
    account: {
      name: "Daniel Brown",
      dp: "https://dpwalay.com/wp-content/uploads/2023/07/single-dp-8.jpg",
      instagram: "daniel.brown",
      category: "Fitness"
    },
    followers: {
      total: "7.2m",
      current: "6.5m"
    },
    action: {
      "action1": "subscribe",
      "action2": "unsubscribe",
      "action3": "share"
    },
    engagement: {
      rate: "21.45%",
      status: "Good"
    },
    average: {
      likes: "15.8k/102",
      status: "Very Good"
    }
  },
  {
    id: 14,
    account: {
      name: "Emily White",
      dp: "https://dpwalay.com/wp-content/uploads/2023/07/single-dp-8.jpg",
      instagram: "emily.w",
      category: "Beauty"
    },
    followers: {
      total: "5.8m",
      current: "5.2m"
    },
    action: {
      "action1": "heart",
      "action2": "comment",
      "action3": "save"
    },
    engagement: {
      rate: "19.73%",
      status: "Good"
    },
    average: {
      likes: "14.2k/94",
      status: "Very Good"
    }
  },
  {
    id: 15,
    account: {
      name: "David Taylor",
      dp: "https://dpwalay.com/wp-content/uploads/2023/07/single-dp-8.jpg",
      instagram: "david.taylor",
      category: "Tech"
    },
    followers: {
      total: "3.5m",
      current: "3.1m"
    },
    action: {
      "action1": "follow",
      "action2": "unfollow",
      "action3": "share"
    },
    engagement: {
      rate: "17.29%",
      status: "Good"
    },
    average: {
      likes: "10.9k/78",
      status: "Very Good"
    }
  },
  {
    id: 16,
    account: {
      name: "Olivia Wilson",
      dp: "https://dpwalay.com/wp-content/uploads/2023/07/single-dp-8.jpg",
      instagram: "olivia.w",
      category: "Fashion"
    },
    followers: {
      total: "4.2m",
      current: "3.6m"
    },
    action: {
      "action1": "like",
      "action2": "comment",
      "action3": "save"
    },
    engagement: {
      rate: "18.11%",
      status: "Good"
    },
    average: {
      likes: "12.7k/86",
      status: "Very Good"
    }
  },
  {
    id: 17,
    account: {
      name: "Michael Clark",
      dp: "https://dpwalay.com/wp-content/uploads/2023/07/single-dp-8.jpg",
      instagram: "michael.c",
      category: "Gaming"
    },
    followers: {
      total: "6.5m",
      current: "5.9m"
    },
    action: {
      "action1": "subscribe",
      "action2": "unsubscribe",
      "action3": "share"
    },
    engagement: {
      rate: "20.34%",
      status: "Good"
    },
    average: {
      likes: "14.5k/97",
      status: "Very Good"
    }
  },
  {
    id: 18,
    account: {
      name: "Emma Harris",
      dp: "https://dpwalay.com/wp-content/uploads/2023/07/single-dp-8.jpg",
      instagram: "emma.h",
      category: "Lifestyle"
    },
    followers: {
      total: "3.9m",
      current: "3.4m"
    },
    action: {
      "action1": "heart",
      "action2": "comment",
      "action3": "save"
    },
    engagement: {
      rate: "16.45%",
      status: "Good"
    },
    average: {
      likes: "11.1k/81",
      status: "Very Good"
    }
  },
  {
    id: 19,
    account: {
      name: "Ryan Turner",
      dp: "https://dpwalay.com/wp-content/uploads/2023/07/single-dp-8.jpg",
      instagram: "ryan.t",
      category: "Music"
    },
    followers: {
      total: "5.1m",
      current: "4.6m"
    },
    action: {
      "action1": "follow",
      "action2": "unfollow",
      "action3": "share"
    },
    engagement: {
      rate: "19.02%",
      status: "Good"
    },
    average: {
      likes: "13.4k/92",
      status: "Very Good"
    }
  },
  {
    id: 20,
    account: {
      name: "Ava Robinson",
      dp: "https://dpwalay.com/wp-content/uploads/2023/07/single-dp-8.jpg",
      instagram: "ava.r",
      category: "Art"
    },
    followers: {
      total: "4.8m",
      current: "4.2m"
    },
    action: {
      "action1": "like",
      "action2": "comment",
      "action3": "save"
    },
    engagement: {
      rate: "18.68%",
      status: "Good"
    },
    average: {
      likes: "12.9k/89",
      status: "Very Good"
    }
  }

]


const data = [
  {
    id: 1,
    accounts: 'Beetlejuice',
    categories: 'Blogger',
    followers: '3.8m',
    engagement: '1.48%',
    average: '106.65k/99',
    likes: '106.80k',
    comments: '15k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
  {
    id: 2,
    accounts: 'TechGuru123',
    categories: 'Tech Influencer',
    followers: '2.5m',
    engagement: '2.15%',
    average: '75.2k/120',
    likes: '72.3k',
    comments: '10k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
  {
    id: 3,
    accounts: 'FashionistaQueen',
    categories: 'Fashion Blogger',
    followers: '5.2m',
    engagement: '1.75%',
    average: '210.8k/80',
    likes: '208.5k',
    comments: '18k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
  {
    id: 4,
    accounts: 'FoodieDelight',
    categories: 'Food Influencer',
    followers: '1.7m',
    engagement: '0.95%',
    average: '45.3k/110',
    likes: '44.8k',
    comments: '8k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
  {
    id: 5,
    accounts: 'FitnessFanatic',
    categories: 'Fitness Guru',
    followers: '4.1m',
    engagement: '2.34%',
    average: '150.2k/90',
    likes: '148.7k',
    comments: '22k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
  {
    id: 6,
    accounts: 'TravelExplorer',
    categories: 'Travel Blogger',
    followers: '3.5m',
    engagement: '1.92%',
    average: '98.7k/105',
    likes: '97.3k',
    comments: '14k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
  {
    id: 7,
    accounts: 'GamingMastermind',
    categories: 'Gaming Influencer',
    followers: '2.8m',
    engagement: '1.68%',
    average: '80.4k/115',
    likes: '79.2k',
    comments: '12k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
  {
    id: 8,
    accounts: 'DIYCraftsPro',
    categories: 'DIY Enthusiast',
    followers: '1.9m',
    engagement: '1.25%',
    average: '64.6k/100',
    likes: '63.8k',
    comments: '9k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
  {
    id: 9,
    accounts: 'PetLoverParadise',
    categories: 'Pet Influencer',
    followers: '2.3m',
    engagement: '1.55%',
    average: '57.9k/95',
    likes: '56.7k',
    comments: '11k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
  {
    id: 10,
    accounts: 'BusinessInsider',
    categories: 'Business News',
    followers: '6.7m',
    engagement: '1.98%',
    average: '185.3k/85',
    likes: '184.6k',
    comments: '16k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
  {
    id: 11,
    accounts: 'TravelEnthusiast123',
    categories: 'Tech Influencer',
    followers: '2.9m',
    engagement: '1.75%',
    average: '120.4k/95',
    likes: '119.2k',
    comments: '13k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
  {
    id: 12,
    accounts: 'HealthNutritionist',
    categories: 'Tech Influencer',
    followers: '1.5m',
    engagement: '1.98%',
    average: '65.2k/110',
    likes: '64.7k',
    comments: '10k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
  {
    id: 13,
    accounts: 'TechGeek456',
    categories: 'Tech Influencer',
    followers: '3.2m',
    engagement: '2.22%',
    average: '85.7k/105',
    likes: '84.3k',
    comments: '15k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
  {
    id: 14,
    accounts: 'BookWormReader',
    categories: 'Tech Influencer',
    followers: '1.8m',
    engagement: '1.45%',
    average: '48.9k/120',
    likes: '47.2k',
    comments: '8k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
  {
    id: 15,
    accounts: 'HomeDecorGuru',
    categories: 'Tech Influencer',
    followers: '4.5m',
    engagement: '1.68%',
    average: '150.6k/100',
    likes: '148.9k',
    comments: '12k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
  {
    id: 16,
    accounts: 'FashionForward123',
    categories: 'Tech Influencer',
    followers: '2.7m',
    engagement: '2.05%',
    average: '78.3k/115',
    likes: '76.5k',
    comments: '14k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
  {
    id: 17,
    accounts: 'PetAdventures',
    categories: 'Tech Influencer',
    followers: '2.1m',
    engagement: '1.55%',
    average: '57.2k/95',
    likes: '56.1k',
    comments: '10k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
  {
    id: 18,
    accounts: 'FoodieJourney',
    categories: 'Tech Influencer',
    followers: '3.9m',
    engagement: '2.12%',
    average: '105.8k/98',
    likes: '104.3k',
    comments: '13k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
  {
    id: 19,
    accounts: 'FitnessFreak456',
    categories: 'Tech Influencer',
    followers: '5.3m',
    engagement: '1.85%',
    average: '130.2k/88',
    likes: '129.5k',
    comments: '16k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
  {
    id: 20,
    accounts: 'DIYCraftyInspire',
    categories: 'Tech Influencer',
    followers: '2.4m',
    engagement: '1.34%',
    average: '72.1k/105',
    likes: '70.5k',
    comments: '11k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
  {
    id: 21,
    accounts: 'NatureExplorer',
    categories: 'Tech Influencer',
    followers: '3.6m',
    engagement: '2.08%',
    average: '95.3k/105',
    likes: '93.8k',
    comments: '14k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
  {
    id: 22,
    accounts: 'TechSavvyPro',
    categories: 'Tech Influencer',
    followers: '4.8m',
    engagement: '1.92%',
    average: '120.6k/95',
    likes: '119.4k',
    comments: '12k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
  {
    id: 23,
    accounts: 'AdventureSeeker567',
    categories: 'Tech Influencer',
    followers: '2.2m',
    engagement: '1.68%',
    average: '68.9k/110',
    likes: '67.3k',
    comments: '10k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
  {
    id: 24,
    accounts: 'FashionistaTrend',
    categories: 'Tech Influencer',
    followers: '3.4m',
    engagement: '2.15%',
    average: '82.1k/115',
    likes: '80.5k',
    comments: '13k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
  {
    id: 25,
    accounts: 'WellnessWarrior',
    categories: 'Tech Influencer',
    followers: '1.9m',
    engagement: '1.45%',
    average: '54.6k/120',
    likes: '53.2k',
    comments: '9k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
  {
    id: 26,
    accounts: 'FoodLoverDelight',
    categories: 'Tech Influencer',
    followers: '2.6m',
    engagement: '2.34%',
    average: '112.8k/98',
    likes: '111.2k',
    comments: '15k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
  {
    id: 27,
    accounts: 'CreativeArtist123',
    categories: 'Tech Influencer',
    followers: '1.3m',
    engagement: '1.75%',
    average: '40.5k/130',
    likes: '39.2k',
    comments: '7k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
  {
    id: 28,
    accounts: 'GamingExpert456',
    categories: 'Tech Influencer',
    followers: '4.2m',
    engagement: '2.02%',
    average: '88.3k/105',
    likes: '86.7k',
    comments: '13k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
  {
    id: 29,
    accounts: 'PetWhisperer',
    categories: 'Tech Influencer',
    followers: '2.8m',
    engagement: '1.98%',
    average: '70.7k/95',
    likes: '69.2k',
    comments: '11k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
  {
    id: 30,
    accounts: 'BusinessMogul123',
    categories: 'Tech Influencer',
    followers: '6.1m',
    engagement: '2.45%',
    average: '175.2k/85',
    likes: '175.5k',
    comments: '18k',
    actions: 'actions',
    photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU',
  },
];


export { influencers, data }