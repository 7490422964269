
const Influencer = [
    {
        "id": 1,
        "account": {
            "name": "InfluencerOne",
            "img": ""
        },
        "followers": "5.8m",
        "engagement": "1.84%",
        "likes": "106.6K",
        "comments": "15K"
    },
    {
        "id": 2,
        "account": {
            "name": "InfluencerTwo",
            "img": "https://example.com/image2.jpg"
        },
        "followers": "5.8m",
        "engagement": "1.84%",
        "likes": "106.6K",
        "comments": "15K"
    },
    {
        "id": 3,
        "account": {
            "name": "InfluencerThree",
            "img": "https://example.com/image3.jpg"
        },
        "followers": "5.8m",
        "engagement": "1.84%",
        "likes": "106.6K",
        "comments": "15K"
    },
    {
        "id": 4,
        "account": {
            "name": "InfluencerFour",
            "img": "https://example.com/image4.jpg"
        },
        "followers": "5.8m",
        "engagement": "1.84%",
        "likes": "106.6K",
        "comments": "15K"
    },
    {
        "id": 5,
        "account": {
            "name": "InfluencerFive",
            "img": "https://example.com/image5.jpg"
        },
        "followers": "5.8m",
        "engagement": "1.84%",
        "likes": "106.6K",
        "comments": "15K"
    },
    {
        "id": 6,
        "account": {
            "name": "InfluencerSix",
            "img": "https://example.com/image6.jpg"
        },
        "followers": "5.8m",
        "engagement": "1.84%",
        "likes": "106.6K",
        "comments": "15K"
    },
    {
        "id": 7,
        "account": {
            "name": "InfluencerSeven",
            "img": "https://example.com/image7.jpg"
        },
        "followers": "5.8m",
        "engagement": "1.84%",
        "likes": "106.6K",
        "comments": "15K"
    },
    {
        "id": 8,
        "account": {
            "name": "InfluencerEight",
            "img": "https://example.com/image8.jpg"
        },
        "followers": "5.8m",
        "engagement": "1.84%",
        "likes": "106.6K",
        "comments": "15K"
    },
    {
        "id": 9,
        "account": {
            "name": "InfluencerNine",
            "img": "https://example.com/image9.jpg"
        },
        "followers": "5.8m",
        "engagement": "1.84%",
        "likes": "106.6K",
        "comments": "15K"
    },
    {
        "id": 10,
        "account": {
            "name": "InfluencerTen",
            "img": "https://example.com/image10.jpg"
        },
        "followers": "5.8m",
        "engagement": "1.84%",
        "likes": "106.6K",
        "comments": "15K"
    },
    {
        "id": 11,
        "account": {
            "name": "InfluencerEleven",
            "img": "https://example.com/image11.jpg"
        },
        "followers": "5.8m",
        "engagement": "1.84%",
        "likes": "106.6K",
        "comments": "15K"
    },
    {
        "id": 12,
        "account": {
            "name": "InfluencerTwelve",
            "img": "https://example.com/image12.jpg"
        },
        "followers": "5.8m",
        "engagement": "1.84%",
        "likes": "106.6K",
        "comments": "15K"
    },
    {
        "id": 13,
        "account": {
            "name": "InfluencerThirteen",
            "img": "https://example.com/image13.jpg"
        },
        "followers": "5.8m",
        "engagement": "1.84%",
        "likes": "106.6K",
        "comments": "15K"
    },
    {
        "id": 14,
        "account": {
            "name": "InfluencerFourteen",
            "img": "https://example.com/image14.jpg"
        },
        "followers": "5.8m",
        "engagement": "1.84%",
        "likes": "106.6K",
        "comments": "15K"
    },
    {
        "id": 15,
        "account": {
            "name": "InfluencerFifteen",
            "img": "https://example.com/image15.jpg"
        },
        "followers": "5.8m",
        "engagement": "1.84%",
        "likes": "106.6K",
        "comments": "15K"
    },
    {
        "id": 16,
        "account": {
            "name": "InfluencerSixteen",
            "img": "https://example.com/image16.jpg"
        },
        "followers": "5.8m",
        "engagement": "1.84%",
        "likes": "106.6K",
        "comments": "15K"
    },
    {
        "id": 17,
        "account": {
            "name": "InfluencerSeventeen",
            "img": "https://example.com/image17.jpg"
        },
        "followers": "5.8m",
        "engagement": "1.84%",
        "likes": "106.6K",
        "comments": "15K"
    },
    {
        "id": 18,
        "account": {
            "name": "InfluencerEighteen",
            "img": "https://example.com/image18.jpg"
        },
        "followers": "5.8m",
        "engagement": "1.84%",
        "likes": "106.6K",
        "comments": "15K"
    },
    {
        "id": 19,
        "account": {
            "name": "InfluencerNineteen",
            "img": "https://example.com/image19.jpg"
        },
        "followers": "5.8m",
        "engagement": "1.84%",
        "likes": "106.6K",
        "comments": "15K"
    },
    {
        "id": 20,
        "account": {
            "name": "InfluencerTwenty",
            "img": "https://example.com/image20.jpg"
        },
        "followers": "5.8m",
        "engagement": "1.84%",
        "likes": "106.6K",
        "comments": "15K"
    },
    {
        "id": 21,
        "account": {
            "name": "InfluencerTwentyOne",
            "img": "https://example.com/image21.jpg"
        },
        "followers": "5.8m",
        "engagement": "1.84%",
        "likes": "106.6K",
        "comments": "15K"
    },
    {
        "id": 22,
        "account": {
            "name": "InfluencerTwentyTwo",
            "img": "https://example.com/image22.jpg"
        },
        "followers": "5.8m",
        "engagement": "1.84%",
        "likes": "106.6K",
        "comments": "15K"
    },
    {
        "id": 23,
        "account": {
            "name": "InfluencerTwentyThree",
            "img": "https://example.com/image23.jpg"
        },
        "followers": "5.8m",
        "engagement": "1.84%",
        "likes": "106.6K",
        "comments": "15K"
    },
    {
        "id": 24,
        "account": {
            "name": "InfluencerTwentyFour",
            "img": "https://example.com/image24.jpg"
        },
        "followers": "5.8m",
        "engagement": "1.84%",
        "likes": "106.6K",
        "comments": "15K"
    },
    {
        "id": 25,
        "account": {
            "name": "InfluencerTwentyFive",
            "img": "https://example.com/image25.jpg"
        },
        "followers": "5.8m",
        "engagement": "1.84%",
        "likes": "106.6K",
        "comments": "15K"
    },
    {
        "id": 26,
        "account": {
            "name": "InfluencerTwentySix",
            "img": "https://example.com/image26.jpg"
        },
        "followers": "5.8m",
        "engagement": "1.84%",
        "likes": "106.6K",
        "comments": "15K"
    },
    {
        "id": 27,
        "account": {
            "name": "InfluencerTwentySeven",
            "img": "https://example.com/image27.jpg"
        },
        "followers": "5.8m",
        "engagement": "1.84%",
        "likes": "106.6K",
        "comments": "15K"
    },
    {
        "id": 28,
        "account": {
            "name": "InfluencerTwentyEight",
            "img": "https://example.com/image28.jpg"
        },
        "followers": "5.8m",
        "engagement": "1.84%",
        "likes": "106.6K",
        "comments": "15K"
    }


]

const assignedCampaign = [
    {
        id : 1,
        img : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFRVFtnan95aKwKyCr7XwFD6akfEnceNdz_Q&usqp=CAU',
        name : 'Kurkure',
        target : '1.23'
    },
    {
        id : 2,
        img : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQykRAF0hCjdergMlao1Dh49sqMoj6D0H4h8w&usqp=CAU',
        name : 'Kurkure',
        target : '1.23'
    },
    {
        id : 3,
        img : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFRVFtnan95aKwKyCr7XwFD6akfEnceNdz_Q&usqp=CAU',
        name : 'Kurkure',
        target : '1.23'
    },
    {
        id : 4,
        img : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQykRAF0hCjdergMlao1Dh49sqMoj6D0H4h8w&usqp=CAU',
        name : 'Kurkure',
        target : '1.23'
    },
    {
        id : 5,
        img : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQykRAF0hCjdergMlao1Dh49sqMoj6D0H4h8w&usqp=CAU',
        name : 'Kurkure',
        target : '1.23'
    },

]

const collections = [
    {
        id: 1,
        name: 'xyz',
        noOfInfluencers: 15,
        brand: 'brand',
        createdOn: '12/02/2020',
        status: 'under Review',
    },
    // Additional records:
    {
        id: 2,
        name: 'abc',
        noOfInfluencers: 10,
        brand: 'brand2',
        createdOn: '15/03/2021',
        status: 'approved',
    },
    {
        id: 3,
        name: 'pqr',
        noOfInfluencers: 20,
        brand: 'brand3',
        createdOn: '22/07/2021',
        status: 'rejected',
    },
    {
        id: 4,
        name: 'lmn',
        noOfInfluencers: 8,
        brand: 'brand4',
        createdOn: '01/12/2019',
        status: 'Inactive',
    },
    {
        id: 5,
        name: 'def',
        noOfInfluencers: 12,
        brand: 'brand5',
        createdOn: '05/05/2022',
        status: 'approved',
    },
    {
        id: 6,
        name: 'ijk',
        noOfInfluencers: 18,
        brand: 'brand6',
        createdOn: '30/09/2020',
        status: 'under Review',
    },
    {
        id: 7,
        name: 'uvw',
        noOfInfluencers: 25,
        brand: 'brand7',
        createdOn: '14/06/2022',
        status: 'rejected',
    },
    {
        id: 8,
        name: 'rst',
        noOfInfluencers: 14,
        brand: 'brand8',
        createdOn: '19/04/2021',
        status: 'under Review',
    },
    {
        id: 9,
        name: 'ghi',
        noOfInfluencers: 22,
        brand: 'brand9',
        createdOn: '07/11/2019',
        status: 'approved',
    },
    {
        id: 10,
        name: 'opq',
        noOfInfluencers: 16,
        brand: 'brand10',
        createdOn: '02/08/2022',
        status: 'rejected',
    },
    {
        id: 11,
        name: 'lmnop',
        noOfInfluencers: 30,
        brand: 'brand11',
        createdOn: '08/03/2022',
        status: 'under Review',
    },
    {
        id: 12,
        name: 'qrstuv',
        noOfInfluencers: 25,
        brand: 'brand12',
        createdOn: '17/09/2021',
        status: 'approved',
    },
    {
        id: 13,
        name: 'wxyz',
        noOfInfluencers: 18,
        brand: 'brand13',
        createdOn: '26/12/2020',
        status: 'rejected',
    },
    {
        id: 14,
        name: 'abcdef',
        noOfInfluencers: 12,
        brand: 'brand14',
        createdOn: '04/06/2019',
        status: 'under Review',
    },
    {
        id: 15,
        name: 'ghijkl',
        noOfInfluencers: 22,
        brand: 'brand15',
        createdOn: '21/02/2021',
        status: 'approved',
    },
    {
        id: 16,
        name: 'mnopqr',
        noOfInfluencers: 15,
        brand: 'brand16',
        createdOn: '13/07/2022',
        status: 'under Review',
    },
    {
        id: 17,
        name: 'stuvwx',
        noOfInfluencers: 28,
        brand: 'brand17',
        createdOn: '09/11/2019',
        status: 'rejected',
    },
    {
        id: 18,
        name: 'yzabcd',
        noOfInfluencers: 20,
        brand: 'brand18',
        createdOn: '16/05/2021',
        status: 'under Review',
    },
    {
        id: 19,
        name: 'efghij',
        noOfInfluencers: 16,
        brand: 'brand19',
        createdOn: '03/10/2020',
        status: 'approved',
    },
    {
        id: 20,
        name: 'ijklmn',
        noOfInfluencers: 24,
        brand: 'brand20',
        createdOn: '28/03/2022',
        status: 'rejected',
    }, {
        id: 21,
        name: 'mnopqrs',
        noOfInfluencers: 35,
        brand: 'brand21',
        createdOn: '11/04/2022',
        status: 'under Review',
    },
    {
        id: 22,
        name: 'tuvwxyz',
        noOfInfluencers: 28,
        brand: 'brand22',
        createdOn: '20/10/2021',
        status: 'approved',
    },
    {
        id: 23,
        name: 'abcd123',
        noOfInfluencers: 25,
        brand: 'brand23',
        createdOn: '27/01/2020',
        status: 'rejected',
    },
    {
        id: 24,
        name: '456efgh',
        noOfInfluencers: 18,
        brand: 'brand24',
        createdOn: '06/07/2019',
        status: 'under Review',
    },
    {
        id: 25,
        name: 'ijkl789',
        noOfInfluencers: 20,
        brand: 'brand25',
        createdOn: '18/03/2021',
        status: 'approved',
    },
    {
        id: 26,
        name: 'mnop123',
        noOfInfluencers: 14,
        brand: 'brand26',
        createdOn: '09/08/2022',
        status: 'under Review',
    },
    {
        id: 27,
        name: 'qrst456',
        noOfInfluencers: 30,
        brand: 'brand27',
        createdOn: '12/12/2019',
        status: 'rejected',
    },
    {
        id: 28,
        name: 'uvwx789',
        noOfInfluencers: 22,
        brand: 'brand28',
        createdOn: '19/06/2021',
        status: 'under Review',
    },
    {
        id: 29,
        name: 'yzab123',
        noOfInfluencers: 16,
        brand: 'brand29',
        createdOn: '08/11/2020',
        status: 'approved',
    },
    {
        id: 30,
        name: 'cdef456',
        noOfInfluencers: 26,
        brand: 'brand30',
        createdOn: '24/04/2022',
        status: 'rejected',
    },
]



export { collections, Influencer, assignedCampaign }