import React from 'react'
import { Box, styled, Typography } from '@mui/material'

import Logo from '../../../images/logo_CreatorHunt.png'


const Top = styled(Box)`

    display : flex;
    flex-direction : column;
    align-items : center;

`
const MyLogo = styled('img')({
    width: '130px',
    height: 'auto',
    marginTop: '20px'

});

const Heading = styled(Typography)`
text-align : center;
    // background : #3DBE72;
    width : 100%;
    padding : 2px 10px;
    margin : 10px 0px;
    color : #FFFFFF;
    font-weight : bold;
    font-size : 20px;
    border-radius : 5px;

`

const Header = () => {
    return (
        <Top>
            <MyLogo src={Logo} alt="" />
            <Heading>Dashboard</Heading>
        </Top>
    )
}

export default Header