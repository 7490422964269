import {
  Box,
  Grid,
  styled,
  TextField,
  IconButton,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Avatar,
  Dialog,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
  Tabs,
  Tab,
} from "@mui/material";
import DataTable from "react-data-table-component";
// import IconButton from '@mui/material/IconButton';
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import {
  AddCircleOutlineRounded,
  GridViewRounded,
  ViewListRounded,
} from "@mui/icons-material";
// import { rowData } from './campaigndata'
import { campaign } from "../subcomponents/campaign/campaignCardData.js";
import DialogBox from "../subcomponents/campaign/DialogBox";
import CampaignCard from "../subcomponents/campaign/CampaignCard";
// import Table from '@mui/material/Table';

import SBicon from "../../images/sb.png";
import LISTicon from "../../images/lst.png";
import { useState } from "react";
import { rowData } from "./campaigndata";
import { width } from "@mui/system";
import CreateCampaignDialog from "./CreateCampaignDialog.jsx";

const findBackgroundColor = (status) => {
  switch (status) {
    case "Rejected":
      return "red";
    case "Under Review":
      return "#FFA84C";
    case "Approved":
      return "green";
    case "Inactive":
      return "#FF2323";
    case "Pending":
      return "#cddc39";
  }
};

const customStyles = {
  head: {
    style: {
      fontSize: "15px", // Set the desired font size for the header
    },
  },
};

const CustomBrand = ({ row }) => (
  <Box>
    <Chip
      avatar={
        <Avatar
          alt="Natacha"
          src={"https://cdn.finshots.app/images/2023/03/Fm6iDt1XkAAkR0O.jpeg"}
        />
      }
      label={row.brand}
      size="medium"
      // variant="outlined"
    />
    {/* <span>{row.brand}</span> */}
  </Box>
);

const CustomPlatform = ({ row }) => (
  <Box>
    <Chip
      avatar={<Avatar alt="Natacha" src={row.platform.logo} />}
      label={row.platform.name}
      size="medium"
      variant="outlined"
    />
    {/* <span>{row.brand}</span> */}
  </Box>
);

const CustomStatus = ({ row }) => (
  <span
    style={{
      background: findBackgroundColor(row.status),
      padding: 5,
      color: "#FFFFFF",
      borderRadius: 8,
    }}
  >
    {row.status}
  </span>
);

const columns = [
  {
    name: "Brand",
    selector: (row) => row.brand,
    sortable: true,
    // width: '250px',
    cell: (row) => <CustomBrand row={row} />,
  },
  {
    name: "Campaign",
    selector: (row) => row.campaign,
    sortable: true,
    reorder: true,
    // width: '190px',
    // cell: row => <Customcategory row={row} />
  },
  {
    name: "Platform",
    selector: (row) => row.platform.name,
    sortable: true,
    reorder: true,
    cell: (row) => <CustomPlatform row={row} />,
    // width: '120px',
  },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: true,
    reorder: true,
    cell: (row) => <CustomStatus row={row} />,
    // width: '120px',
  },
  {
    name: "Created on",
    selector: (row) => row.date,
    sortable: true,
    reorder: true,

    // cell: row => <CustomAverage row={row} />
  },
].map((column) => ({
  ...column,
  style: {
    fontSize: "13px",
  },
}));

const Container = styled(Box)(({ theme }) => ({
  margin: "2rem 1rem",
  // background : 'blue',
  // width: '100%',
  // height: '100%'
}));

const SearchBar = styled(TextField)(({ theme }) => ({
  //    border : 'none'
  width: "100%",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none", // Remove the outline
  },
  border: "1px solid black",
  borderRadius: 20,
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: "bold",
}));

const AddButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  padding: "5px 15px",
  marginTop: "1rem",
  borderRadius: 20,
  background: "#3DBE72",
  boxShadow: "none",
  "&:hover": {
    background: "#3DBE72",
    boxShadow: "none",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: "black",
  textTransform: "none",
  fontWeight: "bold",
}));

const UnderReviewBtn = styled(Button)(({ theme }) => ({
  background: "#FFA84C",
  borderRadius: 20,
  textTransform: "none",
  boxShadow: "none",
  "&:hover": {
    background: "#FFA84C",
    boxShadow: "none",
  },
}));

// ***************
// Dialog custom styles

const Campaigns = () => {
  const [variants, setVariants] = useState("text");
  const [createCampaignDialog, setcreateCampaignDialog] = useState(false);
  const [tableData, setTableData] = useState(rowData);
  const [cardData, setCardData] = useState(campaign);
  const [view, setViews] = useState("true");

  const [view1, setView1] = useState("list");
  const [filterBtn, setFilterBtn] = useState("all");

  const handleClick = () => {
    setVariants();
  };

  const handleToggleButtonGroup = (event, newView) => {
    setView1(newView);
  };

  const handleFilterBtnChange = (event, newFilter) => {
    setFilterBtn(newFilter);
  };

  return (
    <Container>
      {/* <DialogBox/> */}
      <CreateCampaignDialog
        open={createCampaignDialog}
        set={setcreateCampaignDialog}
      />

      <Grid container spacing={1}>
        <Grid item sm={12} lg={8}>
          <Title>Campaigns</Title>
        </Grid>
        <Grid item sm={12} lg={4} align="right">
          <SearchBar
            size="small"
            placeholder="Search by Brand name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Grid item align="right" sm={12} md={12} lg={12}>
        <AddButton
          onClick={() => setcreateCampaignDialog(true)}
          variant="contained"
          startIcon={<AddCircleOutlineRounded />}
        >
          Create Campaign
        </AddButton>
      </Grid>

      <Box
        py={3}
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid item md={12} xs={12} px={3}>
          <ToggleButtonGroup
            value={view1}
            exclusive
            onChange={handleToggleButtonGroup}
            aria-label="text alignment"
            size="small"
          >
            <ToggleButton
              value="list"
              style={{
                color: view1 === "list" ? "#3dbf73ff" : "inherit", // Set the color to red when the button is active
                border: view1 === "list" ? "2px solid #3dbf73ff" : "inherit",
              }}
            >
              <ViewListRounded />
            </ToggleButton>
            <ToggleButton
              value="card"
              style={{
                color: view1 === "card" ? "#3dbf73ff" : "inherit", // Set the color to red when the button is active
                border: view1 === "card" ? "2px solid #3dbf73ff" : "inherit",
              }}
            >
              <GridViewRounded />
            </ToggleButton>
          </ToggleButtonGroup>

          {/* <Tooltip title="Cards view" arrow placement='top'>
            <IconButton size='small'  >
              <img src={SBicon} alt="" width={30} onClick={() => setViews(true)} />
            
            </IconButton>
          </Tooltip>

          <Tooltip title="List view" arrow placement='top' onClick={() => setViews(false)}>
            <IconButton size='small' >
              <img src={LISTicon} alt="" width={30} />
            </IconButton>
          </Tooltip> */}
        </Grid>

        <Grid item lg={12} style={{ marginBottom: "2rem" }}>
          <Tabs
            value={filterBtn}
            exclusive
            onChange={handleFilterBtnChange}
            aria-label="icon label tabs example"
            sx={{
              textTransform: "none",
              padding: 0,
              margin: 0,
            }}
          >
            <Tab
              label="All"
              sx={{
                textTransform: "none",
                padding: 0,
                margin: 0,
              }}
            />
            <Tab
              label="Active"
              sx={{
                textTransform: "none",
                padding: 0,
                margin: 0,
              }}
            />
            
            <Tab
              label="Ended"
              sx={{
                textTransform: "none",
                padding: 0,
                margin: 0,
              }}
            />
            <Tab
              label="Archieved"
              sx={{
                textTransform: "none",
                padding: 0,
                margin: 0,
              }}
            />
          </Tabs>
        </Grid>
      </Box>

      {/* ListViews  */}
      <Grid item md={12} xs={12}>
        <Grid container>
          {view1 === "card" ? (
            <Grid item md={12} xs={12} mt={2}>
              <Grid container p={2} spacing={{ md: 5, xs: 5 }}>
                {cardData?.map((item) => (
                  <Grid item md={3} xs={11} key={item.id}>
                    <CampaignCard data={item} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ) : (
            <Grid item md={12} xs={12}>
              <DataTable
                columns={columns}
                data={tableData}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="100%"
                customStyles={customStyles}
                // onRowClicked={handleRowClick}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      {/* Card View of ccampaign  */}
      {/* <Grid container alignItems="center" justify='center' spacing={2} style={{ marginTop: '1rem' }}>

          {
            campaign.map((data) => {
              return (
                <Grid item md={3} key={data.id}>
                  <CampaignCard data={data} />
                </Grid>
              )
            })
          }


        </Grid> */}
    </Container>
  );
};

export default Campaigns;
