import React, { useEffect, useState } from "react";
import {
  FormControl,
  Divider,
  Select,
  MenuItem,
  Slider,
  TextField,
  Box,
  styled,
  IconButton,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";

const SliderInputFieldGroup = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "15px",
}));

const TabsGroup = ({
  text,
  tabs,
  setTabsGroupFilterState,
  setFollowersGrowthState,
  setAvgReelsViewsState,
  setViewsTimeframeState,
  clear,
  tooltipInfo,
}) => {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [sliderValue, setSliderValue] = useState(50);
  const [inputValue, setInputValue] = useState(50);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setTabsGroupFilterState({ selectedTab: newValue, value: inputValue });
  };

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
    setInputValue(newValue);
    setTabsGroupFilterState({ selectedTab: activeTab, value: inputValue });
    if (setFollowersGrowthState)
      setFollowersGrowthState({
        selectedValue: activeTab,
        value: inputValue,
      });
    else if (setAvgReelsViewsState)
      setAvgReelsViewsState({
        selectedValue: activeTab,
        value: inputValue,
      });
    else
      setViewsTimeframeState({
        selectedValue: activeTab,
        value: inputValue,
      });
  };

  const handleInputChange = (event) => {
    const newInputValue = parseInt(event.target.value, 10);
    if (!isNaN(newInputValue)) {
      setSliderValue(newInputValue);
      setInputValue(newInputValue);
      setTabsGroupFilterState({ selectedTab: activeTab, value: inputValue });
      if (setFollowersGrowthState)
        setFollowersGrowthState({
          selectedValue: activeTab,
          value: inputValue,
        });
      else if (setAvgReelsViewsState)
        setAvgReelsViewsState({
          selectedValue: activeTab,
          value: inputValue,
        });
      else
        setViewsTimeframeState({
          selectedValue: activeTab,
          value: inputValue,
        });
    }
  };

  useEffect(() => {
    if (clear) {
      setActiveTab(tabs[0]);
      setSliderValue(50);
      setInputValue(50);
    }
  }, [clear]);

  // if (setFollowersGrowthState)
  //   setFollowersGrowthState({
  //     selectedValue: activeTab,
  //     value: inputValue,
  //   });
  // else if (setAvgReelsViewsState)
  //   setAvgReelsViewsState({
  //     selectedValue: activeTab,
  //     value: inputValue,
  //   });
  // else
  //   setViewsTimeframeState({
  //     selectedValue: activeTab,
  //     value: inputValue,
  //   });

  return (
    <Accordion sx={{ width: { md: "80%", xs: "90%" }, boxShadow: "none" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ textAlign: "start" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: 1,
          }}
        >
          <IconButton style={{ color: "#3DBE72" }}>
            <Tooltip title={tooltipInfo}>
              <HelpOutlineRoundedIcon
                style={{ fontSize: 15, padding: 0, margin: 0 }}
              />
            </Tooltip>
          </IconButton>
          <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <Box sx={{ width: "100%", padding: "0 10px" }}>
          <Autocomplete
            value={activeTab}
            onChange={handleTabChange}
            options={tabs}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Time frame"
                size="small"
                sx={{
                  "&.MuiOutlinedInput-notchedOutline": {
                    borderColor: "#3dbe72",
                  },
                  "&:hover.MuiOutlinedInput-notchedOutline": {
                    borderColor: "#3dbe72",
                  },
                }}
              />
            )}
          />
          <Slider
            value={sliderValue}
            onChange={handleSliderChange}
            valueLabelDisplay="auto"
            style={{ color: "#3dbe72", padding: 0, width: "100%" }}
            size="small"
          />
          <SliderInputFieldGroup>
            <TextField
              variant="outlined"
              type="number"
              value={inputValue}
              onChange={handleInputChange}
              label="Percentage"
              size="small"
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#3dbe72",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#3dbe72",
                },
              }}
            />
          </SliderInputFieldGroup>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default TabsGroup;
