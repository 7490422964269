import { useEffect, useState } from "react";
import React from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Slider,
  TextField,
  Box,
  styled,
  IconButton,
  Tooltip,
} from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";

function valuetext(value) {
  return `${value}°C`;
}

const MinMaxFieldGroup = styled(Box)(({ theme }) => ({
  display: "flex",
  marginTop: "1rem",
  alignItems: "center",
  marginBottom: "10px",
}));

const AudienceAgeFilter = ({
  text,
  setAudienceAgeFilterState,
  clear,
  tooltipInfo,
}) => {
  const [ageValue, setAgeValue] = useState([18, 40]);
  const [minAge, setMinAge] = useState(10);
  const [maxAge, setMaxAge] = useState(90);

  useEffect(() => {
    if (clear) {
      setAgeValue([18, 40]);
      setMinAge(18);
      setMaxAge(40);
    }
  }, [clear]);

  const handleAgeChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    // if (newValue[1] - newValue[0] < minDistance) {
    //   if (activeThumb === 0) {
    //     const clamped = Math.min(newValue[0], 90 - minDistance);
    //     setAgeValue([clamped, clamped + minDistance]);
    //     setMinAge(clamped);
    //     setMaxAge(clamped + minDistance);
    //   } else {
    //     const clamped = Math.max(newValue[1], minDistance);
    //     setAgeValue([clamped - minDistance, clamped]);
    //     setMinAge(clamped - minDistance);
    //     setMaxAge(clamped);
    //   }
    // }

    setAgeValue(newValue);
    setMinAge(newValue[0]);
    setMaxAge(newValue[1]);

    setAudienceAgeFilterState({
      percentage: percentageValue,
      min: minAge,
      max: maxAge,
    });
  };

  const handleMinAgeChange = (event) => {
    const newMinAge = parseInt(event.target.value, 10);
    if (isNaN(newMinAge)) {
      setMinAge(10); // set min age to 10 when input is emptied
      // if (maxAge < 10) {
      //   setMaxAge(10 + minDistance); // ensure maxAge does not become negative
      // }
    } else if (newMinAge > maxAge) {
      setMaxAge(newMinAge);
    }
    setMinAge(newMinAge);
    setAgeValue([newMinAge, maxAge]);
  };

  const handleMaxAgeChange = (event) => {
    const newMaxAge = parseInt(event.target.value, 10);
    if (isNaN(newMaxAge)) {
      setMaxAge(90); // set max age to 90 when input is emptied
      // if (minAge > 90) {
      //   setMinAge(minDistance); // ensure minAge does not become negative
      // }
    } else if (newMaxAge < minAge) {
      setMinAge(newMaxAge);
    }
    setMaxAge(newMaxAge);
    setAgeValue([minAge, newMaxAge]);
  };

  const [percentageValue, setPercentageValue] = React.useState(50);

  const handlePercentageChange = (event, newValue) => {
    setPercentageValue(newValue);
    setAudienceAgeFilterState({
      percentage: percentageValue,
      min: minAge,
      max: maxAge,
    });
  };

  return (
    <Accordion sx={{ width: { md: "80%", xs: "90%" }, boxShadow: "none" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: 1,
          }}
        >
          <IconButton style={{ color: "#3DBE72" }}>
            <Tooltip title={tooltipInfo}>
              <HelpOutlineRoundedIcon
                style={{ fontSize: 12, padding: 0, margin: 0 }}
              />
            </Tooltip>
          </IconButton>
          <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Typography sx={{ marginBottom: "10px" }}>
          Age Range ({minAge} - {maxAge})
        </Typography>
        <Slider
          getAriaLabel={() => "Age Range"}
          value={ageValue}
          onChange={handleAgeChange}
          valueLabelDisplay="auto"
          getAriaValueText={valuetext}
          disableSwap
          style={{ color: "#3dbe72", padding: 0, width: "100%" }}
          size="small"
          min={10}
          max={90}
        />
        <MinMaxFieldGroup>
          <TextField
            variant="outlined"
            type="number"
            value={minAge}
            onChange={handleMinAgeChange}
            style={{ marginRight: "10px" }}
            size="small"
          />
          <SwapHorizIcon style={{ marginRight: 6, color: "#3dbe72" }} />
          <TextField
            variant="outlined"
            type="number"
            value={maxAge}
            onChange={handleMaxAgeChange}
            size="small"
          />
        </MinMaxFieldGroup>
        <Box
          sx={{
            padding: "15px 10px",
            bgcolor: "#f0f3f6",
            borderRadius: "10px",
          }}
        >
          <Typography sx={{ margin: "5px 0", textAlign: "start" }}>
            More than{" "}
            <span style={{ marginRight: "5px", fontWeight: "bold" }}>
              {percentageValue}%
            </span>
            of Audience
          </Typography>
          <Slider
            getAriaLabel={() => "Percentage of Audience"}
            value={percentageValue}
            onChange={handlePercentageChange}
            valueLabelDisplay="auto"
            getAriaValueText={(value) => `More than ${value}% of audience`}
            style={{
              color: "#3dbe72",
              padding: "0",
              width: "100%",
            }}
            size="small"
            min={0}
            max={100}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default AudienceAgeFilter;
