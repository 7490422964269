import axios from "axios";

const URL = process.env.REACT_APP_BASE_URL

export const getBrandByID = async (id_) => {
    try {
        const res = await axios.post(`${URL}/getbrandbyid`, id_)
        console.log("response by brand id : ", res);
        return res;

    }
    catch (e) {
        console.log("error while fetching brand details by id : ", e.message);
    }
}