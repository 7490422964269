import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
// components
import Login from "./assets/components/Login";
import Signup from "./assets/components/Signup";
// import Dashboard from "./assets/components/Dashboard";
import MiniDrawer from "./assets/components/MiniDrawer";
import Home from "./assets/components/Home";
import FindInfluencer from "./assets/components/FindInfluencer";
import Profile from "./assets/components/subcomponents/findInfluencer/Profile";
import SignupForAddSocialMedia from "./assets/components/SignupForAddSocialMedia";
import InstagramConfirm from "./assets/components/InstagramConfirm";
import ManageBrands from "./assets/components/manage brands/ManageBrands";
import Reports from "./assets/components/reports comp/Reports";
import ManageInfluencers from "./assets/components/manage influencers/ManageInfluencers";
import ManageEmployees from "./assets/components/manage employees/ManageEmployees";
import Collections from "./assets/components/collection/Collections";
import CollectionProfile from "./assets/components/collection/CollectionProfile";
import Campaigns from "./assets/components/campaigns/Campaigns";
import InfluencerTable from "./assets/components/collection/InfluencerTable";
import CollectionProfileAuthFree from "./assets/components/collection/CollectionProfileAuthfree";
import Analytics from "./assets/components/collection/Analytics";
import CampaignProfile from "./assets/components/campaigns/CampaignProfile";
import Setting from "./assets/components/setting component/Setting";
import UserProfile from "./assets/components/profile/UserProfile";
import PublicInfluencerProfile from "./assets/components/public content/PublicInfluencerProfile";

import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

const RouteType = ({ children }) => {
  const isAuthorized = useSelector((state) => state.authorization);
  const navigate = useNavigate();

  const isMiniDrawerOpen = useSelector((state) => state.sharedState);


  useEffect(() => {
    if (!isAuthorized) {
      navigate('/login');
    }
  }, [isAuthorized, navigate]);

  return (
    <>
      {React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { });
        }
        return child;
      })}
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/public-content/influencer-profile/:id",
    element: <PublicInfluencerProfile />,
  },
  {
    path: "/dashboard",
    element: (
      <RouteType>
        <MiniDrawer />
      </RouteType>
    ),
    children: [
      {
        path: "/dashboard/home",
        element: (
          <RouteType>
            <Home />
          </RouteType>
        ),
      },
      {
        path: "",
        element: (
          <RouteType>
            <Home />
          </RouteType>
        ),
      },
      {
        path: "/dashboard/find-influencers",
        element: (
          <RouteType>
            <FindInfluencer />
          </RouteType>
        ),
      },
      {
        path: "/dashboard/find-influencers/profile/:id",
        element: (
          <RouteType>
            <Profile />
          </RouteType>
        ),
      },
      {
        path: "/dashboard/collection",
        element: (
          <RouteType>
            <Collections />
          </RouteType>
        ),
      },
      {
        path: "/dashboard/collection/:id",
        element: (
          <RouteType>
            <CollectionProfile />
          </RouteType>
        ),
        children: [
          {
            path: "list",
            element: <InfluencerTable />,
          },
          {
            path: "",
            element: <InfluencerTable />,
          },
          {
            path: "analytics",
            element: <Analytics />,
          },
        ],
      },
      {
        path: "/dashboard/campaign",
        element: (
          <RouteType>
            <Campaigns />
          </RouteType>
        ),
      },
      {
        path: "/dashboard/campaign/profile",
        element: (
          <RouteType>
            <CampaignProfile />
          </RouteType>
        ),
      },
      {
        path: "/dashboard/manage-brands",
        element: (
          <RouteType>
            <ManageBrands />
          </RouteType>
        ),
      },
      {
        path: "/dashboard/reports",
        element: (
          <RouteType>
            <Reports />
          </RouteType>
        ),
      },
      {
        path: "/dashboard/manage-influencers",
        element: (
          <RouteType>
            <ManageInfluencers />
          </RouteType>
        ),
      },
      {
        path: "/dashboard/manage-employees",
        element: (
          <RouteType>
            <ManageEmployees />
          </RouteType>
        ),
      },
      {
        path: "/dashboard/setting",
        element: (
          <RouteType>
            <Setting />
          </RouteType>
        ),
      },
      {
        path: "/dashboard/profile",
        element: (
          <RouteType>
            <UserProfile />
          </RouteType>
        ),
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/add-social-accounts",
    element: <SignupForAddSocialMedia />,
  },
  {
    path: "/confirm-social-media",
    element: <InstagramConfirm />,
  },
  {
    path: "/public/collection/:id",
    element: <CollectionProfileAuthFree />,
  },
]);

const App = () => {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};

export default App;