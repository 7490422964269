
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    influencers: {},
}

export const influencersSlice = createSlice({
    name: 'influencers',
    initialState,
    reducers: {
        setInfluencers: (state, action) => {
            state.influencers = action.payload;
        }
    }
})



export const { setInfluencers } = influencersSlice.actions;


export default influencersSlice.reducer;