import React, { useEffect, useState } from "react";
import {
  FormControl,
  Divider,
  Select,
  MenuItem,
  Slider,
  TextField,
  Box,
  styled,
  IconButton,
  Tooltip,
} from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";

const ButtonGroupFilter = ({
  text,
  tabs,
  setButtonGroupFilterState,
  clear,
  tooltipInfo,
}) => {
  const [activeTab, setActiveTab] = useState(tabs[1]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setButtonGroupFilterState(tab);
  };

  useEffect(() => {
    if (clear) {
      setActiveTab(tabs[1]);
    }
  }, [clear]);

  return (
    <Accordion sx={{ width: { md: "80%", xs: "90%" }, boxShadow: "none" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ textAlign: "start" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: 1,
          }}
        >
          <IconButton style={{ color: "#3DBE72" }}>
            <Tooltip title={tooltipInfo}>
              <HelpOutlineRoundedIcon
                style={{ fontSize: 12, padding: 0, margin: 0 }}
              />
            </Tooltip>
          </IconButton>
          <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <Box sx={{ width: "100%", padding: "0 10px" }}>
          <ButtonGroup
            sx={{ width: "100%" }}
            color="inherit"
            variant="outlined"
            aria-label="Basic button group"
          >
            {tabs.map((tab) => (
              <Button
                sx={{
                  padding: "5px",
                  fontSize: "13px",
                  backgroundColor: activeTab === tab ? "#3dbe72" : "",
                  color: activeTab === tab ? "white" : "",
                  "&:hover": {
                    color: "#3dbe72",
                  },
                  border:
                    activeTab === tab
                      ? "1px solid #3dbe72"
                      : "1px solid #3dbe72",
                }}
                onClick={() => handleTabClick(tab)}
              >
                {tab}
              </Button>
            ))}
          </ButtonGroup>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default ButtonGroupFilter;
