import { Grid, Typography, TextField, InputAdornment, IconButton, styled, Button, Box, Dialog, CircularProgress, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Search, Add, AddTaskOutlined, WarningAmberOutlined, Visibility, VisibilityOff } from '@mui/icons-material'
import DataTable from 'react-data-table-component';
// import { brands } from './rowsData';
import CloseIcon from '@mui/icons-material/Close';
import facebookIcon from '../../images/facebook.png'
import instaIcon from '../../images/insta-sm.png'
import youtubeIcon from '../../images/youtube-sm.png'

// api 
import { registerBrand } from '../../../service/api';
import { getAllBrands } from '../../../service/getAllApi';



const MyInput = styled('input')(`
    background : #dce7f7ff;
    outlined : none;
    border : none;
    width : 100%;
    padding : 15px 10px;
    border-radius : 8px;
    font-size : 15px;
    margin-top : 5px;
`)

const Mylabel = styled('label')(`
    color : #828282ff;
`)

const LoadingSpinner = styled(CircularProgress)(({ theme }) => ({
    color: '#FFFFFF',


}))


const AddNewBrand = ({ close }) => {
    // const [data, setData] = useState(brands);
    const [loading, setLoading] = useState(false);

    const [isSucessful, setIsSuccessful] = useState(false);
    const [error, setError] = useState(
        {
            message: '',
            status: false
        }
    )
    const [brand, setBrand] = useState({
        name: '',
        email: '',
        password: ''
    })

    const handleChange = (e) => {
        setBrand({ ...brand, [e.target.name]: e.target.value })
        console.log(brand);
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError({ message: "", status: false })
        try {
            const response = await registerBrand(brand)
            if (response.data.status === 201) {
                setIsSuccessful(true);
                setError({ message: '', status: false })
            }
        }
        catch (e) {
            setError({ message: "Brand already exists.", status: true })
            console.error("Error while submiting register brand data : " + e)


            setIsSuccessful(false)
        }
        finally {
            setLoading(false);
        }
    }

    const handleClose = () => {
        setError({ message: "", status: false })
        setIsSuccessful(false);
        close(false)
    }

    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };


    return (

        <>
            <Grid container p={3}>
                <Grid item md={11} xs={11} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '1.2rem', color: '#4caf50' }}>Add Brands</Typography>
                    {
                        error.status &&
                        <Box>
                            <p style={{ color: '#e81e61ff', fontSize: 13 }} >{error.message}</p>
                        </Box>
                    }
                </Grid>
                <Grid item md={1} xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title='Close' arrow placement='left'>
                        <IconButton size='small' onClick={() => handleClose()}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item md={12} xs={12}>
                    <form onSubmit={handleSubmit}>
                        <Box mt={2}>
                            <Mylabel htmlFor="name">Name</Mylabel>
                            <Box>
                                <MyInput type="text" name="name" id="name" placeholder='Enter Brand name...' onChange={(e) => handleChange(e)} required />
                            </Box>
                        </Box>
                        <Box mt={2}>
                            <Mylabel htmlFor="email">Email</Mylabel>
                            <Box>
                                <MyInput type="email" name="email" id="email" placeholder="Enter brand's email address..." onChange={(e) => handleChange(e)} required />
                            </Box>
                        </Box>
                        <Box mt={2}>
                            <Mylabel htmlFor="password">Password</Mylabel>
                            <Box position="relative">
                                <MyInput type={showPassword ? 'text' : 'password'} name="password" id="password" placeholder='Enter Strong Password...' onChange={(e) => handleChange(e)} required />
                                <IconButton
                                    onClick={togglePasswordVisibility}
                                    style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 2 }}>
                            <Button type='submit' size='small' variant='contained'
                                sx={{
                                    bgcolor: error.status ? '#e81e61ff' : '#4cb04fff',
                                    boxShadow: 'none',
                                    fontWeight: 'bold',
                                    // padding: '10px 30px',
                                    '&:hover': {
                                        bgcolor: error.status ? '#e81e61ff' : '#4cb04fff',
                                    }
                                }}
                                endIcon={
                                    loading ? (
                                        <LoadingSpinner size={25} />
                                    ) : (
                                        isSucessful ? (
                                            <AddTaskOutlined />
                                        ) : (
                                            error.status && <WarningAmberOutlined />
                                        )
                                    )
                                }

                                disabled={loading}
                            >
                                {
                                    isSucessful ? 'Registered' : 'Register'
                                }

                            </Button>
                        </Box>
                    </form>


                </Grid>
            </Grid>

        </>
    )
}

export default AddNewBrand