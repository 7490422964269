import React from 'react'

import { NavLink, Link } from "react-router-dom";
import { Box, Typography, TextField, Button, styled, Select } from '@mui/material'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

//to table creation
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

//custom components
import SelectComp from '../subcomponents/manageBrands/SelectComp';




import FilterIcon from '../../images/filter.png'

const Container = styled(Box)(({ theme }) => ({
    padding: 10,
    width: '100%'
}))

const TopBox = styled(Box)(({ theme }) => ({
    padding: '2rem'
}))

const Title = styled(Typography)(({ theme }) => ({
    fontSize: '2rem',
    fontWeight: 'bold'

}))

const AddButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    padding: '5px 15px',
    borderRadius: 20,
    background: 'black',
    '&:hover': {
        background: 'black',
    }

}))

const SearchBar = styled(TextField)(({ theme }) => ({
    //    border : 'none'

    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none', // Remove the outline
    },
    border: '1px solid black',
    borderRadius: 20,




}))

const SearchAndAddBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '2rem'
}))

const FilterBox = styled(Box)(({ theme }) => ({
    marginTop: '2rem',
    // display: 'flex',
    // justifyContent: 'space-between',
    width: '50%'
}))

const SortByGroup = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center'
}))

const ResetBtn = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    fontSize: '1rem',
    color: 'black',

}))




const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // backgroundColor: theme.palette.common.black,
        color: theme.palette.common.black,
        //   fontWeight : 'bold',
        fontWeight: 'bold',
        fontSize: '12px'

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,

    },
}));

function createData(id, brand, collection, totalCampaign, activeCampaign, action) {
    return { id, brand, collection, totalCampaign, activeCampaign, action };
}

const rows = [
    createData(1, 'MasterCard', 177, 18, 24),
    createData(2, 'Nintendo', 169, 18, 37),
    createData(3, 'EBay', 357, 16.0, 8),
    createData(4, "McDonald's", 738, 45, 67),
    createData(5, 'IBM', 185, 40, 49),
    createData(6, 'Sony', 600, 14, 49),
    createData(7, 'Mitsubishi', 130, 34, 49),
];

const Navitems = [
    {
        text: "All",
        path: "#all"
    },
    {
        text: "Live",
        path: "#live"
    },
    {
        text: "Offline",
        path: "#offline"
    },
    {
        text: "Archieved",
        path: "#archieved"
    },
    
]



const Reports = () => {
    const activeLinkStyle = {
        fontWeight: 'bold',
        color: 'red',
        // Add any other styles you want to apply when the NavLink is active
      };
    return (
        <>
            <Container>
                <TopBox>
                    <Title>Reports</Title>
                    <SearchAndAddBox >
                        <SearchBar size='small' placeholder='Search by Brand name' InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}

                        />
                        <AddButton variant='contained' startIcon={<AddRoundedIcon />}>New Reports</AddButton>
                    </SearchAndAddBox>

                    <FilterBox>
                        {/* <ResetBtn sx={{ fontWeight: 'bold' }} startIcon={<img src={FilterIcon} />}>Advance Filters</ResetBtn> */}
                        <Box style={{margin : '10px 0px'}}>
                           <Button variant='contained' style={{background : '#3DBE72', padding : 0, textTransform : 'none', boxShadow : 'none'}}>All</Button>
                           <Button variant='text' style={{color :'black',  padding : 0, textTransform : 'none', boxShadow : 'none'}}>Live</Button>
                           <Button variant='text' style={{color :'black', padding : 0, textTransform : 'none', boxShadow : 'none'}}>Offline</Button>
                           <Button variant='text' style={{color :'black', padding : 0, textTransform : 'none', boxShadow : 'none'}}>Archieved</Button>
                   
                        </Box>

                        <SortByGroup>
                            <Typography
                                sx={{ fontWeight: 'bold' }}>Sort by : </Typography>
                            <ResetBtn>Launch date</ResetBtn>
                            <ResetBtn>Newest</ResetBtn>
                            <ResetBtn>Oldest</ResetBtn>
                            <ResetBtn>%Complete</ResetBtn>
                        </SortByGroup>
                    </FilterBox>
                </TopBox>

                <TableContainer component={Paper} sx={{
                    boxShadow: '0px 0px 15px 0px #9BD66A40', borderRadius: 8
                }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell>Brand Name</StyledTableCell>
                                <StyledTableCell align="center">Total Collection</StyledTableCell>
                                <StyledTableCell align="center">Total Campaign</StyledTableCell>
                                <StyledTableCell align="center">Active Campaign</StyledTableCell>
                                <StyledTableCell align="center">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows?.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row"  align="right">
                                        {row.id}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.brand}
                                    </TableCell>
                                    <TableCell align="center">{row.collection}</TableCell>
                                    <TableCell align="center">{row.totalCampaign}</TableCell>
                                    <TableCell align="center">{row.activeCampaign}</TableCell>
                                    <TableCell align="center"><SelectComp /></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>


            </Container>
        </>
    )
}

export default Reports